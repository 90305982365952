import React from 'react'

function PatientEducate() {
    return (
        <div>
            <div className='font-semibold text-lg '>Patient Education</div>
            <div className='pt-3 text[16px] pl-6'>No Data Available</div>
        </div>
    )
}

export default PatientEducate