import { localstore } from "../localstore/localstore";


export const createInteractionData = async (pageId, title, activity) => {
    const visitId = localstore.getVisitorId()

    // Get device and browser information
    const browser = navigator.userAgent; // User agent string
    const device = /Mobi|Android/i.test(navigator.userAgent) ? "Mobile" : "Desktop"; // Si
    // const currentUrl = window.location.href; // Full URL of the page

    return {
        visitorId: visitId,
        participantId: "",
        interactionType: "page",
        pageTitle: title,
        // location: location, // Use the fetched location
        // city: "hyd",
        browser: browser,
        device: device,
        pageId: pageId, // Use the retrieved pageId
        Activity: activity
    };
};

const createActivityInteractionData = (visitId, location, pageId, activity) => {
    const browser = navigator.userAgent; // User agent string
    const device = /Mobi|Android/i.test(navigator.userAgent) ? "Mobile" : "Desktop"; // Si
    return {
        visitorId: visitId,
        participantId: "",
        interactionType: "activity",
        location: location,
        browser: browser,
        device: device,
        pageId: pageId,
        Activity: activity,
    }
};
export const getLoc = async () => {
    try {
        const position = await getCurrentPosition();
        const loc = position.coords ? `${position.coords.latitude},${position.coords.longitude}` : null;
        return loc;
    } catch (error) {
        console.error('Error getting location:', error);
        return null;
    }
};

export const getMetaData = async (state) => {
    return {
        ...state,
        dateTime: new Date().toISOString(),
        location: await getLoc(),
        author: localstore.getUserId(),
        versionId: 0,
        securityCodes: null,
    }
}
function getCurrentPosition() {
    return new Promise((resolve, reject) => {
        const Location = window.navigator && window.navigator.geolocation;
        if (Location) {
            Location.getCurrentPosition(
                (position) => resolve(position),
                (error) => reject(error) // use reject here to handle errors properly
            );
        } else {
            reject(new Error('Geolocation is not supported by this browser.'));
        }
    });
}



