import React, { useEffect, useRef, useState } from 'react'
import CommonDialog from '../atoms/commonModal'
import { Box, CircularProgress, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import { CustomTextField } from '../atoms/TextField'
import { OutlinedButton } from '../atoms/commonbutton'
import * as yup from 'yup';
import URL from '../../services/api_url';
import axios from 'axios';
import { Formik } from 'formik';
import ErrorStatusMsg from '../atoms/ErrorStatusMsg'

function ForgotUserName({ open, setForgotuserOpen, selectedbranch }) {

    const [email, setEmail] = useState(null)
    const [sendOtp, setSendOtp] = useState(false)
    const [showusername, setShowUsername] = useState(false)
    const [alertmessage, setAlertmessage] = useState('')
    const [alertstatus, setAlertstatus] = useState('')
    const [loading, setLoading] = useState(false)
    const formikRef = useRef(null);  // Create a ref to store formik's resetForm function

    const signUpValidationSchema = yup.object().shape({
        emailId: yup.string().required('Email is required')
            .test('valid-domain', 'Invalid Email', (value) => {
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regular expression for email validation
                return emailPattern.test(value);
            }),
    })
    // useEffect(() => {
    //     const script = document.createElement("script");
    //     script.src =
    //         "https://www.google.com/recaptcha/api.js?render=6LfZoZEnAAAAAI-P-K64YET4zUQtJiZwVGCpY0m-";
    //     document.body.appendChild(script);
    // }, []);
    const handleChangeEmail = (e, setFieldTouched, setFieldValue, setFieldError) => {
        const { value } = e.target;
        setFieldValue('emailId', value);
        setFieldTouched('emailId', true, false); // S
        setFieldError('emailId', '');
        setAlertmessage('')
    }


    const handleSendEmail = async (username, email) => {
        // if (phoneNumber.length === 10 && verifyOtp.length === 6) {

        //     // Perform OTP verification logic here...
        //     setOtpSubmitted(true); // Set OTP submission status to true
        // } else {
        //     console.log("Please enter a valid phone number and OTP.");
        // }
        // setVerifyOpen(true)
        // setRequestOpen(false)
        const data = {
            "messagetype": "OTP",
            "apptype": "paramitha",
            "phoneNumber": '',
            "email": email,
            "realm": 'parimitha',
            "subject": "paramitha",
            "username": username
        }
        // timeinterval();
        setLoading(true)
        window.grecaptcha.ready(_ => {
            window.grecaptcha
                .execute("6LfZoZEnAAAAAI-P-K64YET4zUQtJiZwVGCpY0m-", { action: "submit" })
                .then(token => {
                    // console.log(token, "pythontokennnn")
                    axios.post(`${URL.otp}forgotusername/paramitha`, data, {
                        headers: {
                            "Authorization": `Bearer ${token}`,
                        }
                    }).then(res => {
                        if (res.status === 200 || res.status === 201) {
                            // setAlertmessage("please check your email")
                            // setAlertstatus('success')
                            alert('please check your Email')
                            handleDonePage()
                        }

                    }).catch((error) => {

                        setAlertmessage(error.message)
                        setAlertstatus('error')
                    }).finally(() => {
                        setLoading(false)
                    })
                })
        })

    }
    const handleVerifyOtp = () => {
        setShowUsername(true)
    }

    const handleDonePage = () => {
        setAlertmessage('')
        setForgotuserOpen(false)
        if (formikRef.current) {
            formikRef.current.resetForm();  // Reset the form when the dialog is closed
        }
    }
    // console.log(alertmessage, "akertyu")
    return (
        <div>
            <CommonDialog open={open} maxWidth="sm" onClose={() => setForgotuserOpen(false)} sx={{ '.MuiDialog-paper': { top: { xs: 0, sm: 0, md: -90 } } }}>
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', p: '5px 20px', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 600, textAlign: 'center' }}>Forgot Your User Name</Typography>
                    <IconButton onClick={handleDonePage}><Close sx={{ color: 'red' }} /></IconButton>
                </DialogTitle>
                <Divider />

                <Formik
                    validationSchema={signUpValidationSchema}
                    enableReinitialize={true}
                    initialValues={{ emailId: "" }}
                    onSubmit={async (values) => {
                        const data = {
                            "email": values.emailId,
                            "realm": 'parimitha'
                        }
                        try {
                            const res = await axios.post(`${URL.check}/findPatientsByUserNameviaEmail`, data, { headers: { realm: 'parimitha' } })
                            handleSendEmail(res.data, values.emailId)
                        } catch (error) {
                            // console.log(error.response.data, "erroremail")
                            setAlertmessage(error.response.data)
                            setAlertstatus('error')
                        }
                    }}
                    innerRef={formikRef}  // Store the formik instance in the ref

                >
                    {
                        ({
                            handleSubmit,
                            isValid,
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            touched,
                            isSubmitting,
                            setFieldValue,
                            setFieldError,
                            setFieldTouched
                        }) => (
                            <>
                                <DialogContent>

                                    <Grid container>
                                        <Grid item xs={12} md={12}>
                                            <Box >
                                                <Box sx={{ mb: 1 }}>
                                                    <label style={{ fontWeight: 500 }}>Email ID</label>
                                                </Box>
                                                <CustomTextField
                                                    placeholder="Enter your Email"
                                                    type={'email'}
                                                    borderColor="#59D8FA"
                                                    borderRadius="7px"
                                                    value={values.emailId}
                                                    onChange={
                                                        (e) => handleChangeEmail(e, setFieldTouched, setFieldValue, setFieldError)
                                                    }
                                                    handleBlur={handleBlur('emailId')}
                                                    error={touched.emailId && Boolean(errors.emailId)}
                                                />
                                                {errors.emailId && touched.emailId && (
                                                    <Typography sx={{ color: '#D32F2F', fontSize: '14px' }}>
                                                        {touched.emailId && errors.emailId}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </DialogContent >
                                <Divider />

                                <div className='text-center'> <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} /></div>
                                <DialogActions sx={{ m: "5px 20px", }}>
                                    <div>
                                        <OutlinedButton text={"Send User Name "} height="33px" fontSize="14px" borderColor="#1B5775" color="#1B5775" borderRadius="7px" onClick={handleSubmit} endIcon={loading ? <CircularProgress size={20} color='inherit' /> : null} />
                                    </div>
                                </DialogActions>
                            </>
                        )}

                </Formik>
            </CommonDialog >
        </div >
    )
}

export default ForgotUserName