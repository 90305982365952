import React from 'react'

function PostArticles() {
    return (
        <div>
            <div className='font-semibold text-lg '>Posts & Articles</div>
            <div className='pt-3 text[16px] pl-6'>No Data Available</div>
        </div>)
}

export default PostArticles