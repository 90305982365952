import React, { useEffect, useState, useCallback } from 'react';
import { Paper, Typography, Grid, Divider, Box, Avatar, CircularProgress } from '@mui/material';
import SwitchprofileIcon from '../../assets/healthportal-icons/switchprofile.svg';
import { Logout } from '@mui/icons-material';
import { localstore } from '../localstore/localstore';
import BranchUrl from '../../services/api_branch_url';
import axios from 'axios';
import { getAllProfiles, getPatientdatafromibm, getPatientDatafromId, getPatientIdDetails } from '../../redux/actions/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import StringAvatar from '../atoms/stringAvatar';
import { calculateAge } from '../atoms/calculateAge';

const AllProfiles = ({ person, handleSwitchProfile }) => {
    const [patient, setPatient] = useState('');
    const patientIbmId = person?.target?.reference;
    const urls = BranchUrl();
    const usertoken = localstore.getToken();

    useEffect(() => {
        if (patientIbmId && usertoken) {
            axios.get(`${urls.person_tenant_orgId}${patientIbmId}`, {
                headers: {
                    'X-FHIR-TENANT-ID': 'parimitha',
                    'X-FHIR-DSID': `fhirparimitha`,
                    'Realm': 'parimitha',
                    'Authorization': `Bearer ${usertoken}`
                }
            }).then(res => {
                setPatient(res.data);
            }).catch(err => {
                console.error(err);
            });
        }
    }, [patientIbmId, usertoken, urls.person_tenant_orgId]);




    return (
        <div className='cursor-pointer' onClick={() => handleSwitchProfile(patient?.id, patient)}>
            <Grid container m={"10px 0px"}>
                <Grid item md={10.8} className="flex items-center" gap={"12px"}>
                    {/* <Avatar {...stringAvatar(`${personData?.resource?.name?.[0]?.given} ${personData?.name?.[0]?.family}`)} /> */}
                    <Avatar variant="rounded" {...StringAvatar(`${`${patient?.name?.[0]?.text?.toUpperCase()} ${patient?.name?.[0]?.family?.toUpperCase() ?? ''}`.slice(0, 12)}${`${patient?.name?.[0]?.text?.toUpperCase()} ${patient?.name?.[0]?.family?.toUpperCase() ?? ''}`.length > 12 ? '...' : ''}`)} />

                    <div>
                        <Typography fontSize={{ xs: '12px', sm: '14px', md: 14, lg: 16 }} fontWeight={500} textTransform={'capitalize'}>
                            {patient?.name?.[0]?.given} {patient?.name?.[0]?.family}
                        </Typography>

                        <Typography fontSize={{ xs: '11px', sm: 13, md: 13, lg: 13 }}>
                            {patient?.birthDate ? `${calculateAge(patient?.birthDate)} Y` : ''}/{patient?.gender && patient?.gender.charAt(0).toUpperCase()}
                        </Typography>
                    </div>
                </Grid>
                <Grid item md={1.2}>
                    <Logout />
                </Grid>
            </Grid>
            <Divider />
        </div>
    );
};

function Switchprofile({ loading, personDetails, setLoading }) {
    const ibmId = localstore.getIbmId();
    const fixedIbmId = localstore.getFixedIbmId();
    const branchName = useSelector((state) => state.branch.branch)
    const usertoken = localstore.getToken();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const urls = BranchUrl();
    const handleSwitchProfile = useCallback(async (id, val) => {
        // console.log(id, "iddd")
        setLoading(true);
        if (fixedIbmId === id) {
            dispatch(getPatientdatafromibm(urls, usertoken, id));
            setLoading(false);
            localStorage.setItem('pId', fixedIbmId)

            // } else if ((fixedIbmId === id)) {
        } else {
            dispatch(getPatientIdDetails(urls, usertoken, id))
            setLoading(false);
            localStorage.setItem('pId', id);
            navigate(branchName ? `/${branchName}/patient` : `/patient`, { state: { id } });
        }
    }, [fixedIbmId, usertoken, setLoading]);

    // useEffect(() => {
    //     if (ibmId) {
    //         dispatch(getAllProfiles(urls, ibmId, usertoken);
    //     }
    // }, [dispatch, ibmId, usertoken]);


    return (
        <Box sx={{ borderRadius: '5px', p: { xs: '0px 10px', sm: '0px 10px', md: '0px 10px', lg: "0px 10px" } }}>
            <Typography fontSize={21} fontWeight={500} sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <img src={SwitchprofileIcon} alt="switch" />
                Select Profile
            </Typography>
            <Box sx={{ mt: 2 }}>
                <Paper elevation={3} sx={{
                    p: '5px 20px', mt: 2,
                    overflowY: 'scroll',
                    maxHeight: { xs: '100vh', sm: '100vh', md: '67vh' },
                    '&::-webkit-scrollbar': { display: 'none' },
                }}>
                    {loading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            {/* {fixedPatient?.id &&
                                <>
                                    <Grid container mt={"15px"} sx={{ cursor: 'pointer' }} onClick={() => handleSwitchProfile(fixedPatient.id, fixedPatient)}>
                                        <Grid item md={10.8} className="flex items-center" gap={"12px"}>
                                            <Avatar variant="rounded" {...StringAvatar(`${`${fixedPatient?.name?.[0]?.given?.[0].toUpperCase()} ${fixedPatient?.name?.[0]?.family.toUpperCase() ?? ''}`.slice(0, 12)}${`${fixedPatient?.name?.[0]?.given?.[0].toUpperCase()} ${fixedPatient?.name?.[0]?.family.toUpperCase() ?? ''}`.length > 12 ? '...' : ''}`)} />
                                            <div>
                                                <Typography fontSize={{ xs: '12px', sm: 14, md: 14, lg: 16 }} fontWeight={500} textTransform={'capitalize'}>
                                                    {`${fixedPatient?.name?.[0]?.given?.[0]} ${fixedPatient?.name?.[0]?.family}`.slice(0, 12)}
                                                    {`${fixedPatient?.name?.[0]?.given?.[0]} ${fixedPatient?.name?.[0]?.family}`.length > 12 ? '...' : ''}
                                                    <Typography component={'span'} fontSize={{ xs: 10, sm: 12, md: 12, lg: 14 }}>(My self)</Typography>
                                                </Typography>
                                                <Typography fontSize={{ xs: '11px', sm: 13, md: 13, lg: 13 }}>
                                                    {fixedPatient?.birthDate ? `${calculateAge(fixedPatient?.birthDate)} Y` : ''}/{fixedPatient?.gender && fixedPatient?.gender.charAt(0).toUpperCase()}
                                                </Typography> */}
                            {/* <Typography fontSize={{ xs: '11px', sm: 13, md: 13, lg: 13 }}>Not Verified</Typography> */}
                            {/* </div>
                                        </Grid>
                                        <Grid item md={1.2}>
                                            <Logout />
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ mt: 1 }} />
                                </>
                            } */}
                            {personDetails?.link && personDetails.link.length > 0 ? (
                                personDetails.link.map((person, index) => (
                                    <AllProfiles
                                        key={index}
                                        person={person}
                                        handleSwitchProfile={handleSwitchProfile} />
                                ))
                            ) : (
                                <div className='text-center mt-1'>No patients found. Add new patients to get started.</div>
                            )}
                        </>
                    )}
                </Paper>
            </Box>
        </Box>
    );
}

export default Switchprofile;
