import React from "react";
import { Box, Button, Typography } from "@mui/material";
import styles from "../../header/styles.module.css";
import AutoPlayVideo from "./AutoPlayVideo";
import videosrc from "../../../assets/images/mother and boy.mp4"


const Thirdslide = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "80vh",
        // position: "relative",
      }}
      className={styles.carouselHead}
    >
      <AutoPlayVideo src={videosrc} />
      {/* <Image
        src={"/background.jpg"}
        alt="background"
        width={1200}
        height={600}
      /> */}
      <div className={styles.thirdslidecontainer}>
        <h1
          className={[styles.slidethirdheading].join(" ")}
          style={{ color: "black" }}
        >
          {/* Children are Special So, Our Specialists For Children */}
          Child Birth is Happiness,We Only Make It Natural & Safe
        </h1>

        <Box className={styles.childinformation}>
          <Typography className={styles.item}>TRIMESTER CHECKUP</Typography>
          <Typography className={styles.item}>LABOR PREPARATION</Typography>
          <Typography className={styles.item}>CHILD BIRTH</Typography>
          <Typography className={styles.item}>POSTNATAL CARE</Typography>
        </Box>
        <div className={styles.appintmentbtn}>
          <Button
            variant="contained"
            sx={{
              color: "#fff",
              borderRadius: "10px",
              // alignSelf: "left",
              width: '150px',
              textTransform: "capitalize",
              backgroundColor: '#000000 !important',
              height: 35,
              mt: 2
            }}
          >
            <Typography sx={{ fontSize: '18px' }}>  Book Now</Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Thirdslide;
