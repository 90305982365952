import React from "react";
import { Button } from "@mui/material";
import styles from "../../header/styles.module.css";
import videosrc from "../../../assets/images/Boy laughing at doctor checking heartbeat of toy.jpeg"


const Fourthslide = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "80vh",
        // position: "relative",
      }}
      className={styles.carouselHead4}
    >
      <img
        src={videosrc}
        alt="background"
        width={1200}
        height={600}
        className={styles.fifthSlideImage}
      />
      <div className={styles.fourthslidecontainer}>
        <h1 className={[styles.slidefourthheading].join(" ")}>
          Children are Special So, Our Specialists For Children
        </h1>
        <p className={styles.slidefourthheading1} style={{ fontSize: "25px", textAlign: "left", marginTop: '10px' }}>
          Consult the Best Peadiatrician
        </p>
        <div className={styles.schedulebtn}>
          <Button
            variant="contained"
            sx={{
              background: "#002F9A !important",
              // color: "#000",
              borderRadius: 16,
              alignSelf: "left",
              textTransform: "capitalize", mt: '15px'
            }}
          >
            Book Appointment
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Fourthslide;
