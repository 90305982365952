import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

function CommonDialog(props) {
    const { open, onClose, title, children } = props;
    const theme = useTheme();

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


    return (
        <React.Fragment>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                fullScreen={fullScreen}

                fullWidth
                maxWidth={props.maxWidth}
                onClose={onClose}
                aria-describedby="alert-dialog-slide-description"
                sx={props.sx}
            >
                {children}
            </Dialog>
        </React.Fragment>
    );
}

export default CommonDialog;