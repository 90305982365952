import React, { useState, useEffect } from "react";
import { Box, IconButton, Popover, Typography, Button, Drawer } from "@mui/material";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import MenuIcon from "@mui/icons-material/Menu";
import NavbarMenuItems from "./menuItems";
import { subMenuItemsData } from "../../shared/utils";
import "./styles.module.css";
import { useNavigate, Link } from 'react-router-dom';
import { Close } from "@mui/icons-material";
import { Overlay } from "../atoms/overlay";
import { localstore } from "../localstore/localstore";
import { useSelector } from "react-redux";

const DrawerSlider = ({ anchor, open, onClose, children }) => {
  return (
    <div style={{ width: "100%" }}>
      <Drawer
        anchor={anchor}
        open={open}
        onClose={onClose}
        sx={{
          ".MuiDrawer-paper": {
            top: "80px",
          },
          zIndex: 10002
        }}
      >
        <Box sx={{ width: "100vw", mt: "10px" }}>
          {children}
        </Box>
      </Drawer>
    </div>
  );
};

const SecondaryNavbar = ({ spanRef, transparent, token, sticky, handleLogOut }) => {
  const navigate = useNavigate();
  const ibmid = localstore.getIbmId();
  const [subMenuAnchorEl, setSubmenuAnchorEl] = useState(null);
  const [menuItemsData, setMenuItemsData] = useState([]);
  const [selectedSubMenuTitile, setSelectedSubmenuTitile] = useState("");
  const [anchorElNav, setAnchorElNav] = useState(null);
  const usertoken = localstore.getToken();
  const [ourServicePage, setOurServicePage] = useState(false);
  const branchName = useSelector((state) => state.branch.branch);

  const selectBranchInfo = useSelector((state) => state.SelectedBranch.selectedbranch);

  const handleServices = (title) => {
    setOurServicePage(true);
    let getOurServices = () => {
      let value;
      for (let i of Object.keys(subMenuItemsData[0])) {
        if (i === title) {
          value = subMenuItemsData[0][i];
        }
      }
      return value;
    };
    setAnchorElNav(null);
    setMenuItemsData(getOurServices);
  };

  const handleLoginClick = () => {
    navigate(branchName ? `/${branchName}/login` : '/login');
    setAnchorElNav(null);
  };

  const handleRegisterClick = () => {
    navigate(branchName ? `/${branchName}/signup` : '/signup');
    handleCloseNavMenu();
    setAnchorElNav(null);
  };

  const handleClose = () => {
    setOurServicePage(false);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const submenuPopoverOpen = Boolean(subMenuAnchorEl);
  const submenuPopoverId = submenuPopoverOpen ? "submenu" : undefined;

  const mainNavItems = [
    { id: 1, name: "Our Services", title: "ourservices" },
    { id: 2, name: "Health Information", title: "healthinformation" },
    { id: 3, name: "About Us", title: "aboutus" },
  ];

  return (
    <>
      <div
        className="flex justify-center items-center gap-10 p-3 h-1"
        ref={spanRef}
      >
        <Box
          sx={{ flexGrow: 1, display: { xs: "none", sm: "none", md: "flex" } }}
        >
          {mainNavItems.map((item) => {
            return (
              <div
                key={item?.id}
                className={`flex items-center gap-3 ${transparent ? "text-white" : "text-seablue"
                  } cursor-pointer group ${submenuPopoverOpen && item?.title === selectedSubMenuTitile
                    ? "text-seablue"
                    : ""
                  } ${!transparent && "hover:text-pink-500"}`}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: "0px 20px",
                    fontSize: { md: sticky ? 14 : 16, lg: sticky ? "16px" : "18px" },
                    color: '#E44190',
                  }}
                  className={`cursor-pointer ${item?.title === "ourservices" ? "hover:text-[#E44190]" : ""
                    }`}
                  onClick={(event) => {
                    if (item?.title === "ourservices") {
                      handleServices(item?.title);
                      setSubmenuAnchorEl(event.currentTarget);
                    } else if (item?.title === "healthinformation") {
                      navigate(branchName ? `${branchName}/healthinformation` : '/healthinformation')
                    } else if (item?.title === "aboutus") {
                      navigate(branchName ? `${branchName}/AboutUs` : '/AboutUs')
                    }
                  }}
                >
                  <Box
                    // style={{
                    //   display: 'inline-block',
                    sx={{ position: 'relative', fontSize: { md: 14, lg: sticky ? 16 : 18 } }}
                  // }}
                  >
                    {item.name}
                    <span
                      className="absolute left-0 bottom-0 w-full h-px bg-[#E44190] opacity-0 transition-opacity duration-300 group-hover:opacity-100"
                    ></span>
                  </Box>
                  {item.title === "ourservices" && (
                    <Box>
                      {ourServicePage && item.title === "ourservices" ? (
                        <FaAngleUp
                          className={`text-xl font-normal ${submenuPopoverOpen && item.title === selectedSubMenuTitile
                            ? "text-seablue border-b border-seablue"
                            : `${transparent
                              ? "group-hover:border-b border-white"
                              : "group-hover:border-b border-royalpink"
                            }`
                            }`}
                        />
                      ) : (
                        <FaAngleDown className="mt-0.5 opacity-0 transition-opacity duration-300 group-hover:opacity-100" />
                      )}
                    </Box>
                  )}
                </div>

              </div>
            );
          })}

        </Box >
      </div >




      <Box
        sx={{
          display: {
            xs: "flex",
            sm: "flex",
            md: "none",
            marginTop: "-20px",
          },
        }}
      >
        <IconButton
          size="small"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          color="inherit"
          onClick={anchorElNav ? handleCloseNavMenu : handleOpenNavMenu}
        >
          {anchorElNav ? <Close /> : <MenuIcon sx={{ color: '#E44190' }} />}
        </IconButton>

        <DrawerSlider
          anchor="right"
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: { xs: "block", sm: "none", md: "none" },
            width: "100vw !important",
            zIndex: 10001
          }}
        >
          {mainNavItems.map((item) => {
            return (
              <div
                key={item.id}
                className={`flex items-center gap-3 ${transparent ? "text-white" : "text-seablue"
                  } cursor-pointer group ${submenuPopoverOpen && item.title === selectedSubMenuTitile
                    ? "text-seablue"
                    : ""
                  } ${!transparent && "hover:text-pink-500"}`}
              >
                {item.title === "ourservices" ? (
                  <>
                    <Typography
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: "10px 20px",
                        fontSize: "18px",
                      }}
                      className={`cursor-pointer ${item.title === "ourservices"
                        ? "hover:text-pink-500"
                        : "#1D5775"
                        }`}
                      onClick={(event) => { handleServices(item.title); setSubmenuAnchorEl(event.currentTarget); }}
                    >
                      {item.name}
                      <Box> {ourServicePage && item.title === "ourservices" ? (
                        <FaAngleUp
                          className={`text-xl font-normal  ${submenuPopoverOpen &&
                            item.title === selectedSubMenuTitile
                            ? "text-seablue border-b-2 border-seablue "
                            : `${transparent
                              ? "group-hover:border-b-2 border-white"
                              : "group-hover:border-b-2 border-royalpink"
                            }`
                            }`}
                        />
                      ) : (
                        <FaAngleDown className=" mt-1 opacity-0 transition-opacity duration-300 group-hover:opacity-100" />
                      )}</Box>
                    </Typography>
                  </>
                ) : (
                  <>
                    <Link
                      to={item.title === "healthinformation" ? branchName ? `/${branchName}/healthinformation` : "/healthinformation" : branchName ? `/${branchName}/aboutus` : "/aboutus"}
                      onClick={handleCloseNavMenu}
                      style={{ textDecoration: 'none' }}
                    >
                      <Typography
                        style={{
                          padding: "10px 20px",
                          fontSize: "18px",
                        }}
                        className={`text-xl font-normal hover:text-pink-500`}
                      >
                        {item.name}
                      </Typography>
                    </Link>
                  </>
                )}
              </div>
            );
          })}
          <Box sx={{ pl: "10px" }}>
            {ibmid && usertoken ?
              <Button onClick={() => {
                navigate(branchName ? `/${branchName}/healthportal` : '/healthportal')
                handleCloseNavMenu();
              }} sx={{ color: "black" }}>My Health Portal</Button>
              :
              <div>
                {usertoken ? <Button sx={{ color: "black" }} onClick={() => {
                  handleLogOut();
                  handleCloseNavMenu();
                }}> logout</Button> : <>
                  <Button onClick={handleLoginClick} sx={{ color: "black" }}> Login</Button>
                  <span>/</span>
                  <Button onClick={handleRegisterClick} sx={{ color: "black" }}>Register</Button>
                </>
                }
              </div>
            }
          </Box>
        </DrawerSlider>
      </Box>
      {ourServicePage ? <Overlay onClick={handleClose} /> : null}
      <Popover
        id={submenuPopoverId}
        open={ourServicePage}
        anchorEl={subMenuAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        elevation={0}
        className="submenu"
        sx={{
          marginTop: sticky ? "32px" : "32px",
          zIndex: 100,
          '.MuiPopover-paper': {
            overflowY: 'scroll', height: '100vh', '&::-webkit-scrollbar': {
              display: 'none',
            }
          }
        }}
      >
        <NavbarMenuItems menuItemsData={menuItemsData} onClose={handleClose} handleLogOut={handleLogOut} handleCloseNavMenu={handleCloseNavMenu} />
      </Popover>
    </>
  );
};

export default SecondaryNavbar;
