import { Box } from '@mui/material';
import React from 'react'

function EligibilityCriteria({ deptData }) {
    const eligibities = deptData?.eligibility
    // console.log(eligibities, "departdata")
    return (
        <div>
            <div className='font-semibold text-lg '>Eligibility</div>
            <div className='mt-1 text-sm font-normal'>Eligible HealthCare Programs</div>
            <div className='flex flex-wrap gap-3 mt-1'>
                {eligibities?.length > 0 ? (
                    eligibities?.map((eligible, index) => (
                        <Box key={index} sx={{ bgcolor: '#E3DDDD', fontSize: '14px', p: '5px 10px', borderRadius: '15px' }}>
                            {eligible?.code?.coding?.[0]?.display}
                        </Box>
                    ))
                ) : (
                    <div className='pt-3 text-[16px] pl-6'>No Data Available</div>
                )}
            </div>
            <div className='mt-1'>
                <div className='text-sm font-normal'> Appointment Required: <span className='font-light'>Yes </span></div>
                <div className='text-sm font-normal mt-0.5'> Require Referral: <span className='font-light'>No</span> </div>
            </div>
            <div className='flex flex-wrap gap-3 mt-1'>
                {
                    eligibities?.map((eligible, index) => (
                        <Box key={index} sx={{ fontSize: '14px', fontWeight: 400 }}>
                            EligibilityCode: <span className='font-light'>{eligible?.comment}</span>
                            {index < eligibities?.length - 1 && ','}
                        </Box>
                    )
                    )}
            </div>
        </div>
    )
}

export default EligibilityCriteria;