import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Close, Message } from '@mui/icons-material';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import ChatMessage from './chat';
import "./chat.css";
import { createInteractionData } from '../atoms/getLocations';
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { useEffect } from 'react';
import { closeWebSocket, initializeWebSocket } from '../doctors/webSocketManager';
import { Badge } from '@mui/material';
import { fetchLoc } from '../../redux/actions/loginActions';
import { useDispatch } from 'react-redux';



export default function FloatingMenu() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [showWhatsApp, setShowWhatsApp] = useState(false);
    const [showChat, setShowChat] = useState(false);
    const [visitorId, setVisitorId] = useState("");
    const visitId = localStorage.getItem("visitorId");
    const [messages, setMessages] = useState([]);
    const [contentMessage, setContentMessage] = useState([])
    const dispatch = useDispatch()
    const activity = ""
    const pageTitle = "Paramitha Hospitals"
    const pageId = window.location.pathname;


    useEffect(() => {
        const generateVisitorId = async () => {
            const fp = await FingerprintJS.load();
            const result = await fp.get();
            setVisitorId(result.visitorId);
            localStorage.setItem("visitorId", result.visitorId);
        };
        generateVisitorId(); // Generate visitorId once on initial load
    }, []);

    useEffect(() => {
        dispatch(fetchLoc());
    }, []);
    useEffect(() => {
        if (visitorId) {
            const setupWebSocket = async () => {
                const initialInteractionData = await createInteractionData(pageId, pageTitle, activity);
                // Initialize WebSocket when the component mounts
                const handleMessage = (message) => {
                    console.log("Message received:", message);
                    // setMessages((prevMessages) => {
                    //     const newMessages = [...prevMessages, message];
                    //     // Update the message count
                    //     return newMessages;
                    // });
                    setMessages((prevMessages) => [...prevMessages, message] || []); // Append the message to the state

                };
                initializeWebSocket(initialInteractionData, handleMessage);
            };

            setupWebSocket();
            // setWebSocketInitialized(true); // Set to true after initializing

            return () => {
                // Clean up the WebSocket connection when the component unmounts
                closeWebSocket();
            };
        }
    }, [visitorId]);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleWhatsAppClick = () => {
        setShowWhatsApp(true);
        setShowChat(false);
        setMenuOpen(false); // Optionally close menu after selecting
    };

    const handleChatClick = () => {
        setShowChat(true);
        setShowWhatsApp(false);
        setMenuOpen(false); // Optionally close menu after selecting
    };

    useEffect(() => {
        const messageContent = messages.filter(msg => {
            try {
                const parsedMessage = JSON.parse(msg);
                return parsedMessage?.content; // Only count messages with content
            } catch {
                return false; // If parsing fails, don't count it
            }
        });

        // Set the contentMessage state once after filtering
        setContentMessage(messageContent);

        // Log for debugging
        // console.log(messageContent, "helcontent");
        // console.log(messages, "messagesssss");
    }, [messages]); // Run effect when messages change

    const messageCount = contentMessage?.length;
    // console.log(contentMessage, "helcontent")
    // console.log(messages, "messagesssss")
    // console.log(contentMessage, "contentMessage")
    // console.log(messageCount, "messagecount")

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 25,
                right: 30,
                zIndex: 1000,
            }}
        >
            {/* Main Add Button */}
            {menuOpen ? <IconButton
                sx={{
                    backgroundColor: '#E44190',
                    color: '#FFFFFF',
                    '&:hover': {
                        backgroundColor: '#E44190',
                    },
                    width: 56,
                    height: 56,
                    zIndex: 1001, // Ensure it stays above the menu
                }}
                onClick={toggleMenu}
            >
                {/* {menuOpen ? <Close /> : <Add />} */}
                {/* {menuOpen ? <Close /> : <Message />} */}
                <Close />



            </IconButton> :

                <div>
                    {contentMessage?.length > 0 ?
                        <Badge sx={{
                            '.MuiBadge-badge': {
                                right: '8px', top: '10px', zIndex: '1001', bgcolor: '#EB0014', color: 'white'
                            }
                        }} badgeContent={messageCount} >
                            <ChatMessage messages={contentMessage} />
                            {/* } */}
                        </Badge> : <Badge sx={{
                            '.MuiBadge-badge': {
                                right: '8px', bgcolor: '#EB0014', color: 'white', zIndex: 1001
                            }
                        }} badgeContent={messageCount} >
                            <IconButton
                                sx={{
                                    backgroundColor: '#E44190',
                                    color: '#FFFFFF',
                                    '&:hover': {
                                        backgroundColor: '#E44190',
                                    },
                                    width: 56,
                                    height: 56,
                                    zIndex: 1001, // Ensure it stays above the menu
                                }}
                                onClick={toggleMenu}
                            >
                                {/* {menuOpen ? <Close /> : <Add />} */}
                                {/* {menuOpen ? <Close /> : <Message />} */}
                                {/* {menuOpen ? <Close /> : */}

                                <Message />

                                {/* } */}
                            </IconButton>
                        </Badge>
                    }

                </div>

                // <Badge sx={{
                //     '.MuiBadge-badge': {
                //         right: '8px', bgcolor: '#EB0014', color: 'white', zIndex: 1001
                //     }
                // }} badgeContent={messageCount} >
                //     <IconButton
                //         sx={{
                //             backgroundColor: '#E44190',
                //             color: '#FFFFFF',
                //             '&:hover': {
                //                 backgroundColor: '#E44190',
                //             },
                //             width: 56,
                //             height: 56,
                //             zIndex: 1001, // Ensure it stays above the menu
                //         }}
                //         onClick={toggleMenu}
                //     >
                //         {/* {menuOpen ? <Close /> : <Add />} */}
                //         {/* {menuOpen ? <Close /> : <Message />} */}
                //         {/* {menuOpen ? <Close /> : */}

                //         <Message />

                //         {/* } */}
                //     </IconButton>
                // </Badge>
            }




            {/* Circular Menu */}
            {menuOpen && (
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 70, // Adjust based on your main button position
                        right: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 1, // Spacing between buttons
                        }}
                    >
                        {/* WhatsApp Button */}
                        {/* <IconButton
                            sx={{
                                backgroundColor: '#00E676',
                                color: 'black',

                                width: 56,
                                height: 56,
                                '&:hover': {
                                    backgroundColor: '#00E676',
                                },
                            }}
                        // onClick={handleWhatsAppClick}
                        >
                            <FloatingWhatsApp
                                phoneNumber='+917207771225'
                                accountName="Paramitha Hospitals"
                                className='whatsappButton'
                            />
                        </IconButton> */}

                        {/* Chat Button */}
                        {/* <IconButton
                            sx={{
                                backgroundColor: '#E44190',
                                // color: '#FFFFFF',
                                width: 56,
                                height: 56,
                                '&:hover': {
                                    backgroundColor: '#E44190',
                                },
                                // mt: 1
                            }}
                        // onClick={handleChatClick}
                        >
                        </IconButton> */}
                        <div>
                            <Badge sx={{
                                '.MuiBadge-badge': {
                                    right: '8px', top: '10px', zIndex: '1001', bgcolor: '#EB0014', color: 'white'
                                }
                            }} badgeContent={messageCount} >
                                <ChatMessage messages={contentMessage} />
                                {/* } */}
                            </Badge>
                        </div>

                    </Box>

                </Box>
            )
            }


        </Box >
    );
}
