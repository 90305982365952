import { FaRegCalendarAlt, FaStethoscope, FaAmbulance } from "react-icons/fa";
import { FaPersonWalkingArrowRight } from "react-icons/fa6";

export const subMenuItemsData = [
  {
    healthinformation: [
      {
        title: "Articles & Blog",
        services: [
          "Articles & Blog",
          "New Born Care & Lactation",
          "Child Health & Adolescents",
          "Scientific Publications",
        ],
      },
      {
        title: "Live Health",
        services: [
          "Events @ Paramitha",
          "Posts & Videos",
          "Interview Podcasts",
        ],
      },
      {
        title: "Interview Podcasts",
        services: ["Patient Rights", "Patients FAQ’s"],
      },
    ],
    aboutus: [
      {
        title: "Who We Are",
        services: ["Our Story", "Founders & Team", "Quality Standards"],
      },
      {
        title: "Why Paramitha Hospital",
        services: ["Our Vision", "Our Mission", "Our Values", "Testimonials"],
      },
      {
        title: "Contact Us",
        services: [
          "Partner With Us",
          "Education & Training",
          "Careers With Us",
        ],
      },
    ],
    ourservices: [
      {
        title: "Clinical Services",
        services: [
          { name: 'Our Services', url: '/healthcareservices' },
          { name: 'Our Departments', url: '/healthcaredept' },
          { name: 'Conditions & Treatments', url: '/conditions' },
          { name: 'Healthcare Programs' },
        ],
      },
      {
        title: "Catalogs",
        services: [
          { name: 'Diagnostics Tests' },
          { name: 'Medical Devices' },
          { name: 'Medication Products' },
          { name: 'Surgical Procedures' },
        ],
      },
      {
        title: "Finance & Billing",
        services: [
          { name: 'Insurance Network' },
          { name: 'Get Cost Estimate' },
          { name: 'Coverage & Eligibility' },
          { name: 'Pay Your Bill' },
        ],
      },
      {
        title: "Other Services",
        services: [
          { name: 'Privacy & Safety' },
          { name: 'Digital Health' },
          { name: 'Offers & Discounts' },
        ],
      },
    ],
  },
];

export const carouselData = [
  {
    id: 1,
    slideImage: "/background.jpg",
    title: "Welcome to Paramitha Hospitals",
    description: "Exceptional Care for Your Family's Precious Moments",
    buttonText: "Explore Our Services",
    containerStyle: {
      fontSize: "3vw",
      color: "white",
      textTransform: "uppercase",
      textAlign: "left",
      letterSpacing: 0,
      lineHeight: "60px",
    },
  },
  //  {
  //     id: 2,
  //     slideImage: "/background.jpg",
  //     title: "Nurturing Care for Your Little Ones",
  //     description: "Experienced Pediatricians Dedicated to Child Health",
  //     buttonText: "Meet Our Doctors",
  //     styles: styles.secondslidecontainer
  // }, {
  //     id: 3,
  //     slideImage: "/background.jpg",
  //     title: "Comprehensive Support for Mothers & Babies",
  //     description: "Prenatal, Delivery, and Postpartum Expertise",
  //     buttonText: "Schedule Consultation",
  //     styles: styles.thirdslidecontainer

  // }, {
  //     id: 4,
  //     slideImage: "/background.jpg",
  //     title: "Protect Your Child's Health",
  //     description: "Safe and Effective Vaccinations with Expert Guidance",
  //     buttonText: "Book Vaccination",
  //     styles: styles.fourthslidecontainer
  // }
];

export const navbarJson = [
  {
    title: "Find a Doctor",
    icon: FaRegCalendarAlt,
    description: "Schedule an Appointment",
    url: '/doctors'
  },
  {
    title: "Urgent Care",
    icon: FaPersonWalkingArrowRight,
    description: "Walk-In Immediate Available",
  },
  {
    title: "Telemedicine",
    icon: FaStethoscope,
    description: "Ambulance & ER Admission",
  },
  {
    title: "Emergency Services",
    icon: FaAmbulance,
    description: "Virtual Consultations Online",
  },
];

export const mainNavItems = [
  { id: 1, name: "Our Services", title: "ourservices" },
  { id: 2, name: "Health Information", title: "healthinformation" },
  { id: 3, name: "About Us", title: "aboutus" },
];

export const locationJson = [
  {
    name: "Kothapet",
    address: {
      street: "Main Road",
      area: "Kothapet",
      city: "Hyderabad",
      state: "telgana",
      pincode: "500035",
    },
  },
  {
    name: "Chintal",
    address: {
      street: "APHB colony",
      area: "chintal",
      city: "Hyderabad",
      state: "telgana",
      pincode: "500054",
    },
  },
  {
    name: "Chandranagar",
    address: {
      street: "Jawha Colony",
      city: "Hyderabad",
      state: "telgana",
      pincode: "500035",
    },
  },
  {
    name: "Medipally",
    address: {
      street: "Peerzadiguda",
      city: "Hyderabad",
      state: "telgana",
      pincode: "500035",
    },
  },
];
