import React from 'react'
import CommonDialog from './commonModal'
import { Button, DialogContent, Divider } from '@mui/material'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

function NoAccess({ open, handleClose }) {
    const branchName = useSelector((state) => state.branch.branch)
    const navigate = useNavigate()
    return (
        <CommonDialog open={open}  >
            <DialogContent sx={{ p: '10px 30px 10px 30px' }}>
                Your are unable to access the data, please log in or sign up for an account
                <Divider sx={{ mt: 1 }} />
                <div className='flex items-center justify-between p-1'>
                    <Button color='error' sx={{ textTransform: 'capitalize' }} onClick={handleClose}>Cancel</Button>
                    <div > <span className='text-[#1B5775] cursor-pointer hover:underline' onClick={() => navigate(
                        branchName ? `/${branchName}/signup` : "/signup"
                    )}>Signup </span><span>/</span> <span className='text-[#1B5775] cursor-pointer hover:underline' onClick={() => navigate(
                        branchName ? `/${branchName}/login` : "/login"
                    )}>Login</span></div>
                </div>
            </DialogContent>
        </CommonDialog>
    )
}

export default NoAccess