import { Box, Typography } from '@mui/material'
import React from 'react'

function ErrorStatusMsg(props) {
    return (
        <Box>
            {
                props.alertmessage &&
                < Typography
                    sx={{
                        color: props.alertstatus === "success" ? "green" : "#D32F2F",
                        mt: 1, fontSize: "14px", textAlign: props.textAlign
                    }}
                >
                    {props.alertmessage}
                </Typography>
            }
        </Box >
    )
}

export default ErrorStatusMsg