import { DoneAll } from "@mui/icons-material";
import { useEffect, useState } from "react";

export function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export function Message(props) {
    const [message, setMessage] = useState();
    const participantId = localStorage.getItem('participantId');

    useEffect(() => {
        setMessage(props.message);
    }, [props.message]);

    function formatMessageContent(content) {
        const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
        return content?.split(urlRegex).map((part, index) => {
            if (urlRegex.test(part)) {
                const url = part?.startsWith("http") ? part : `https://${part}`;
                return (
                    <a key={index} href={url} target="_blank" rel="noopener noreferrer" style={{ color: '#1a0dab' }}>
                        {part}
                    </a>
                );
            }
            return part;
        });
    }
    function formatMessageTimestamp(timestamp) {
        const date = new Date(timestamp);
        return date.toTimeString().slice(0, 5);
    }


    return (
        <div>
            <div
                className={classNames(
                    "mt-1 mb-1 flex text-ellipsis",
                    participantId == message?.sender ? "justify-end" : "justify-start"
                )}
            >
                <div
                    className={classNames(
                        "relative max-w-xl rounded-lg px-2 py-1 text-gray-700 shadow",
                        participantId == message?.sender ? "bg-[#bcb6b6] text-white" : "bg-white"
                    )}
                >
                    <div className="flex items-end">
                        <span className="block" style={{ wordBreak: 'break-word', whiteSpace: "pre-wrap" }}>
                            <p>{formatMessageContent(message?.content?.trim())}</p>
                        </span>
                        <span
                            className="ml-2"
                            style={{
                                fontSize: "0.6rem",
                                lineHeight: "1rem"
                            }}
                        >
                            {formatMessageTimestamp(message?.timestamp)}
                            {participantId == message?.sender && (
                                <DoneAll
                                    sx={{
                                        color: message?.read ? 'blue' : 'inherit',
                                        fontSize: "16px",
                                        ml: 1
                                    }}
                                />
                            )}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
