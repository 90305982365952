import { MoreVert, Public } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import Media from "./Media";
import PostActions from "./PostActions";
import Video from "./Video";
import ArticleView from "./ArticleView";
import ViewEvents from "./ViewEvents";
import medxpertsIcon from "../../../assets/images/like-icon.svg";
import ShareImg from "../../../assets/images/Share 1.svg";

import Text from "./Text";
import HealthcomActions from "./HealthcomActions";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Photo from "./Photo";
import FeedAlert from "../../atoms/feedAlert";
import { localstore } from "../../localstore/localstore";

export const PostData = ({ feedData }) => {
  const item = feedData;
  const [expanded, setExpanded] = useState(false);
  const branchName = useSelector((state) => state.branch.branch)
  const [open, setOpen] = useState(false)
  const token = localstore.getToken()
  const navigate = useNavigate()
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const timeSince = (date) => {
    var ms = new Date().getTime() - new Date(date).getTime();
    var seconds = Math.floor(ms / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);
    var months = Math.floor(days / 30);
    var years = Math.floor(months / 12);
    if (seconds < 1) {
      return "Just now";
    }
    if (seconds < 60) {
      return seconds + "s";
    }
    if (minutes < 60) {
      return minutes + "m";
    }
    if (hours < 24) {
      return hours + "h";
    }
    if (days < 30) {
      return days + "d";
    }
    if (months < 12) {
      return months + "mon";
    } else {
      return years + "y";
    }
  };

  const handleNavigatePost = (docFeed) => {
    if (docFeed.postType === "ARTICLE") {
      navigate(branchName ? `/${branchName}/viewarticle/${docFeed.id}` : `/viewarticle/${docFeed.id}`, { state: { docFeed } })
    } else {
      navigate(branchName ? `/${branchName}/post/${docFeed?.id}` : `/post/${docFeed?.id}`)
    }
  }

  const handleShare = () => {
    if (token) {
      console.log('hello')
    } else {
      setOpen(true)
    }
  }

  return (
    <div>

      <Box
        sx={{
          // p: '10px',
          bgcolor: '#FFFFFF',
          border: "1px solid #e5e5dc",
          borderRadius: "10px",
          gap: 1,
          mt: 1,
          m: { xs: '15px 0px ', sm: '15px 4px', md: "15px" },
          // height: "546px",
        }}

      >
        <Box sx={{ height: '6px' }}>

        </Box>
        <Grid
          container
          // spacing={2}
          sx={{
            p: "7px 10px",
            height: '90px'
          }}

        >
          <Grid item xs={2.5} sm={2.3} md={2.5} lg={3} xl={2.5} onClick={() => handleNavigatePost(item)}
          >
            {item?.pageData?.profilePic?.length > 0 ||
              item?.userData?.profilePic?.length > 0 ? (
              // <Avatar
              //     src={
              //         item.pageData?.profilePic
              //             ? item.pageData.profilePic
              //             : item.userData?.profilePic
              //     }
              //     sx={{
              //         borderRadius: "7px!important",
              //         // border: "1px solid lightgrey",
              //         width: { sm: 54, md: 54, lg: 62 },
              //         height: { sm: 54, md: 54, lg: 62 },
              //         p: "5px",
              //         ".MuiAvatar-img": { borderRadius: "5px" },
              //     }}
              // />
              <CardMedia
                sx={{
                  width: { xs: 44, sm: 54, md: 54, lg: 54, xl: 62 },
                  height: { xs: 44, sm: 54, md: 54, lg: 54, xl: 62 },
                  border: '3px solid #fff',
                  padding: {
                    xs: '1px', sm: '3px',
                  },
                  borderRadius: '12px',
                  background: '#C6C6C6'
                }
                }
                className="shadow-md"
                component="img"
                alt="doctor img"
                image={item.pageData?.profilePic
                  ? item.pageData.profilePic
                  : item.userData?.profilePic}
              />
            ) : (
              <Avatar
                sx={{
                  borderRadius: "7px!important",
                  // border: "1px solid lightgrey",
                  width: { sm: 55, md: 65 },
                  height: { sm: 55, md: 65 },
                }}
                src={medxpertsIcon}
              />
            )}
          </Grid>

          <Grid item xs={7.5} sm={7.7} md={8} lg={7.5} xl={8} onClick={() => handleNavigatePost(item)}
          >
            {item?.pageData?.fullName?.length > 0 ||
              item?.userData?.fullName?.length > 0 ? (
              <Typography
                sx={{
                  fontSize: { xs: 14, sm: 14, md: 14 },
                  fontWeight: 500,
                  cursor: "pointer",
                }}
              >
                {(item.pageData?.fullName || item.userData?.fullName || "")
                  .charAt(0)
                  .toUpperCase() +
                  (
                    item.pageData?.fullName ||
                    item.userData?.fullName ||
                    ""
                  ).slice(1)}
              </Typography>
            ) : (
              <Typography sx={{ fontSize: 15 }}>Not Mentioned</Typography>
            )}

            {item?.pageData?.caption?.length > 0 ||
              item?.userData?.caption?.length > 0 ? (
              <Typography
                sx={{
                  fontSize: { xs: 12, sm: 12, md: 12 },
                }}
              >
                {item.pageData?.caption || item.userData?.caption}
              </Typography>
            ) : null}

            <Typography color="black">
              <Typography
                sx={{
                  fontSize: { xs: 12, sm: 12, md: 12 },
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <Public sx={{ fontSize: "14px", color: "#336ECC" }} />
                {`${timeSince(moment.utc(item.createDate).local())}`} ago
              </Typography>
              &nbsp;
            </Typography>
          </Grid>

          <Grid item xs={2} sm={2} md={1.5} lg={1.5} xl={1.5} >
            <IconButton>
              <img src={ShareImg} alt="share" />
            </IconButton>
          </Grid>
        </Grid>
        {/* <Typography
            sx={{
              textOverflow: "ellipsis",
              wordWrap: "break-word",
              fontWeight: 500,
              fontSize: 14,
              p: "0px 10px",
            }}
          >
            {item?.description ? (
              <>
                {expanded
                  ? item.description
                  : `${item.description.slice(0, 114)}`}
                {item.description.length > 95 ? (
                  <Typography
                    component={"span"}
                    onClick={handleExpandClick}
                    sx={{ cursor: "pointer", fontSize: 14, ml: 1 }}
                  >
                    {expanded ? "...see less" : "...see more"}{" "}
                  </Typography>
                ) : (
                  ""
                )}
              </>
            ) : (
              "Hi"
            )}
          </Typography> */}
        {/* <CardContent> */}
        <Box sx={{ height: "310px" }} onClick={() => handleNavigatePost(item)}>
          {
            item.postType === "TEXT" ? (
              <Text docFeed={item} />
            ) : item.postType === "IMAGE" ? (
              // <Photo url={item?.sourceLink?.split(',').slice(0, -1)} post={item} />
              <Media docFeed={item} />
            ) : item.postType === "VIDEO" ? (
              <Video docFeed={item} />
            ) : item.postType === "ARTICLE" ? (
              <ArticleView docFeed={item} />
            ) : item.postType === "EVENT" ? (
              <ViewEvents docFeed={item} />
            ) : null}
          {/* </CardContent> */}
        </Box>

        <HealthcomActions item={item} setOpen={setOpen} handleNavigatePost={handleNavigatePost} />
      </Box>
      <FeedAlert open={open} setOpen={setOpen} />
    </div>
  );
};
