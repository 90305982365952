import { Box, Card, Grid, Typography, Avatar, IconButton, Button, } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ArticlePost from "./ArticlePost";
import moment from "moment";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import PtEduImg from '../../assets/images/patient Education.svg';
import URL from "../../services/api_url";
import { useSelector } from "react-redux";
import { MenuBookTwoTone } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";

const ViewAllArticles = () => {
    const location = useLocation()
    const articleData = location?.state?.articleData
    const [expanded, setExpanded] = useState(false);
    const navigate = useNavigate()
    const selectedbranch = useSelector((state) => state.SelectedBranch.selectedbranch);
    // const timeSince = (date) => {
    //   var ms = new Date().getTime() - new Date(date).getTime();
    //   var seconds = Math.floor(ms / 1000);
    //   var minutes = Math.floor(seconds / 60);
    //   var hours = Math.floor(minutes / 60);
    //   var days = Math.floor(hours / 24);
    //   var months = Math.floor(days / 30);
    //   var years = Math.floor(months / 12);
    //   if (seconds < 1) {
    //     return "Just now";
    //   }
    //   if (seconds < 60) {
    //     return seconds + "s";
    //   }
    //   if (minutes < 60) {
    //     return minutes + "m";
    //   }
    //   if (hours < 24) {
    //     return hours + "h";
    //   }
    //   if (days < 30) {
    //     return days + "d";
    //   }
    //   if (months < 12) {
    //     return months + " month";
    //   } else {
    //     return years + "y";
    //   }
    // };

    // const timeSince = (date) => {
    //   var ms = new Date().getTime() - new Date(date).getTime();
    //   var seconds = Math.floor(ms / 1000);
    //   var minutes = Math.floor(seconds / 60);
    //   var hours = Math.floor(minutes / 60);
    //   var days = Math.floor(hours / 24);
    //   var months = Math.floor(days / 30);
    //   var years = Math.floor(months / 12);
    //   if (seconds < 1) {
    //     return "Just now";
    //   }
    //   if (seconds < 60) {
    //     return seconds + "s";
    //   }
    //   if (minutes < 60) {
    //     return minutes + "m";
    //   }
    //   if (hours < 24) {
    //     return new Date(date).toLocaleDateString(); // Return date when difference is less than a day
    //   }
    //   if (days < 30) {
    //     return days + "d";
    //   }
    //   if (months < 12) {
    //     return months + " month";
    //   } else {
    //     return years + "y";
    //   }
    // };

    const timeSince = (date) => {
        return new Date(date).toLocaleDateString();
    };



    const handleExpandClick = () => {
        setExpanded(!expanded);
    };


    return (
        <Box
            sx={{
                mt: 2, p: { xs: "5px", sm: "5px 20px", md: "5px 80px", lg: '5px 100px' }
            }}
        >
            <div className="flex gap-2">
                <MenuBookTwoTone sx={{ fontSize: '30px' }} />
                <div>
                    <div className="text-xl font-semibold">
                        Patient Education
                    </div>
                    <div className="font-normal">
                        Patient Information Articles & Resources
                    </div>
                </div>
            </div>

            <Grid container columnSpacing={3} sx={{ mt: "10px" }}>
                {articleData.map((item, val) => (
                    <Grid item xs={12} sm={5.8} md={3.9}>

                        <Card>
                            <Box
                                sx={{
                                    maxHeight: 290,
                                    // border: "1px solid red"
                                }}
                            >
                                <ArticlePost docFeed={item} />
                            </Box>
                            <div className="px-4">
                                {item?.pageData?.fullName?.length > 0 ||
                                    item?.userData?.fullName?.length > 0 ? (
                                    <Typography
                                        sx={{
                                            fontSize: { xs: 14, sm: 14, md: 14 },
                                            fontWeight: 500,
                                            cursor: "pointer",
                                            pl: "5px",
                                            color: "#707070",
                                        }}
                                    >
                                        <span style={{ marginRight: "2px" }}>By</span>
                                        {(
                                            item.pageData?.fullName ||
                                            item.userData?.fullName ||
                                            ""
                                        )
                                            .charAt(0)
                                            .toUpperCase() +
                                            (
                                                item.pageData?.fullName ||
                                                item.userData?.fullName ||
                                                ""
                                            ).slice(1)}
                                    </Typography>
                                ) : (
                                    <Typography sx={{ fontSize: 15 }}>Not Mentioned</Typography>
                                )}

                                <Typography color="#707070">
                                    <Typography
                                        sx={{
                                            fontSize: { xs: 12, sm: 12, md: 12 },
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "5px",
                                            pl: "5px",
                                        }}
                                    >
                                        {`${timeSince(moment.utc(item.createDate).local())} `}
                                    </Typography>
                                    &nbsp;
                                </Typography>
                            </div>

                            {/* <Grid
                container
                spacing={2}
                sx={{
                  p: "5px 5px",
                }}
              >
                <Grid item xs={8} sm={7.7} md={8}>
                  {item?.pageData?.fullName?.length > 0 ||
                    item?.userData?.fullName?.length > 0 ? (
                    <Typography
                      sx={{
                        fontSize: { xs: 14, sm: 14, md: 14 },
                        fontWeight: 500,
                        cursor: "pointer",
                        pl: "5px",
                        color: "#BEBEBE",
                      }}
                    >
                      <span style={{ marginRight: "2px" }}>By</span>
                      {(
                        item.pageData?.fullName ||
                        item.userData?.fullName ||
                        ""
                      )
                        .charAt(0)
                        .toUpperCase() +
                        (
                          item.pageData?.fullName ||
                          item.userData?.fullName ||
                          ""
                        ).slice(1)}
                    </Typography>
                  ) : (
                    <Typography sx={{ fontSize: 15 }}>Not Mentioned</Typography>
                  )}

                  <Typography color="#BEBEBE">
                    <Typography
                      sx={{
                        fontSize: { xs: 12, sm: 12, md: 12 },
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        pl: "5px",
                      }}
                    >
                      {`${timeSince(moment.utc(item.createDate).local())} `}
                    </Typography>
                    &nbsp;
                  </Typography>
                </Grid>
              </Grid> */}
                            {/* <PostActions item={item} /> */}
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box >
    );
};

export default ViewAllArticles;
