const initialState = {
    // postdata: [],
    openBranch: false,
    selectfacility: false,
    patientDetails: {},
    patientData: {},
    fixedPatient: {},
    patientAppointments: [],
    states: [],
    districts: [],
    subdistricts: [],
    searchloc: [],
    languages: [],
    patientLists: [],
    patientInformation: '',
    relationship: [],
    switchProfiles: [],
    allProfiles: [],
};

const paramithaReducer = (state = initialState, action) => {
    // console.log(action,">>>>>>>>>>>>>");
    switch (action.type) {

        case "OPEN_BRANCH":
            return {
                ...state,
                openBranch: action.payload
            };

        case "POST_DATA":
            return {
                ...state,
                postdata: action.payload
            };
        case "SELECT_FACILITY_BRANCH":
            return {
                ...state,
                selectfacility: action.payload
            };
        case "PATIENTDETAILS":
            return {
                ...state,
                patientDetails: action.payload
            };
        case "PATIENTDATA":
            return {
                ...state,
                patientData: action.payload
            };
        case "FIXED_PATIENT":
            return {
                ...state,
                fixedPatient: action.payload
            };
        case "PATIENT_APPOINTMENTS":
            return {
                ...state,
                patientAppointments: action.payload
            };
        case "GET_STATES":
            return {
                ...state,
                states: action.payload
            };
        case "GET_DISTRICTS":
            return {
                ...state,
                districts: action.payload
            };
        case "GET_SUB_DISTRICTS":
            return {
                ...state,
                subdistricts: action.payload
            };
        case "GET_PATIENT_DETAILS":
            return {
                ...state,
                patientLists: action.payload
            };
        case "GET_PATIENT_INFO":
            return {
                ...state,
                patientInformation: action.payload
            };
        case "GET_RELATION_INFO":
            return {
                ...state,
                relationship: action.payload
            };
        case "SWITCH_PROFILES":
            return {
                ...state,
                switchProfiles: action.payload
            };
        case "GET_LANGUAGES":
            return {
                ...state,
                languages: action.payload
            };
        case "ALL_PROFILES":
            return {
                ...state,
                allProfiles: action.payload
            };
        default:
            return state;
    }
};

export default paramithaReducer;
