import { Box, Typography } from '@mui/material';
import React, { useState } from 'react'

function DoctorText({ docFeed }) {
    const [expanded, setExpanded] = useState(false);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    return (
        <div>
            <Typography sx={{ p: "0px 10px", fontSize: 14, fontWeight: 500 }}>
                {/* {docFeed?.description} */}

                {docFeed?.description ? (
                    <>
                        {expanded
                            ? docFeed.description
                            : `${docFeed.description.slice(0, 75)}`}
                        {docFeed.description.length > 80 ? (
                            <Typography
                                component={"span"}
                                onClick={handleExpandClick}
                                sx={{ cursor: "pointer", fontSize: 14, ml: 1 }}
                            >
                                {expanded ? "...see less" : "...see more"}{" "}
                            </Typography>
                        ) : (
                            ""
                        )}
                    </>
                ) : (
                    <Box sx={{ height: { sm: 63, md: 42 } }}></Box>
                )}
            </Typography>
        </div>
    )
}

export default DoctorText;