"use client";
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button, Card, Grid, Rating, Tooltip, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ShareIcon from "@mui/icons-material/Share";
import axios from "axios";
import URL from "../../../services/api_url";
import { ArrowForward } from "@mui/icons-material";

const CustomSlick = ({ value }) => {
  const [reviewData, setReviewData] = useState([]);
  const [showFullText, setShowFullText] = useState({});
  const textRefs = useRef([]);
  const sliderRef = useRef < Slider > (null);
  const gotoNext = () => {
    sliderRef.current?.slickNext();
  };

  const gotoPrev = () => {
    sliderRef.current?.slickPrev();
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          position: "absolute",
          top: "100%",
          marginTop: "33px",
          // left: "-20px",
          left: `calc(50% - 100px)`,

          // marginRight: '5px',
          zIndex: 1,
          cursor: "pointer",
        }}
        onClick={onClick}
      />
    );
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          position: "absolute",
          top: "100%",
          marginTop: "33px",
          // right: "-15px",
          right: `calc(50% - 100px)`,
          // marginLeft: '10px',
          zIndex: 1,
          cursor: "pointer",
        }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    className: "center",
    centerMode: true,
    focusOnSelect: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 3,
    slidesToScroll: 3,
    speed: 500,
    dots: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    pauseOnHover: true,
    cssEase: "linear",
    prevArrow: <PrevArrow onClick={gotoPrev} />,
    nextArrow: <NextArrow onClick={gotoNext} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],

    // className: "center",
    // dots: false,
    // arrows: false,
    // infinite: true,
    // slidesToShow: 2,
    // slidesToScroll: 1,
    // autoplay: true,
    // speed: 6000,
    // autoplaySpeed: 4000,
    // pauseOnHover: true,
    // cssEase: "linear",
    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 1,
    //       infinite: true,
    //       dots: true,
    //     },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       initialSlide: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    // ],
  };

  useEffect(() => {
    // let branchName = "Kompally";
    if (value) {
      axios
        .get(`${URL.getReviews}/paramitha/all`)
        .then((res) => {
          const filteredReviews = res?.data?.filter(
            (item) => item?.rating === 5
          );
          const limitedReviews = filteredReviews.slice(0, 5);
          setReviewData(limitedReviews);
          const initialState = {};
          limitedReviews.forEach((item) => {
            initialState[item.id] = false;
          });
          setShowFullText(initialState);
          // console.log(res);
        })
        .catch((err) => console.log(err));
    }
  }, [value]);

  // console.log(reviewData, 171);

  useEffect(() => {
    // Check if text content exceeds three lines for each item
    reviewData.forEach((item, index) => {
      if (textRefs.current[index]) {
        if (
          textRefs.current[index].scrollHeight >
          textRefs.current[index].clientHeight
        ) {
          setShowFullText((prev) => ({ ...prev, [item.id]: false }));
        } else {
          setShowFullText((prev) => ({ ...prev, [item.id]: true }));
        }
      }
    });
  }, [reviewData]);

  return (
    <div>
      <style>
        {`
                      .slider-container .slick-next:before, .slick-prev:before {
                        font-size: 24px;
                        line-height: 1;
                        opacity: 1;
                        color: #E54290 !important;
                      }
                      .slider-container .slick-prev {
                        // margin-top: 369px !important;
                        // padding-left: 37px;
                        z-index: 3;
                      }
                      .slider-container .slick-next {
                        // margin-top: 369px !important;
                        // padding-right: 55px;
                        z-index: 3;
                      }
                      .slider-container .slick-dots li button:before {
                        font-size: 18px;
                        color:#F5C1D9;
                        opacity:1;
                        margin-top: 20px;
                      }
                      .slider-container .slick-dots .slick-active button:before {
                        font-size: 18px;
                        color:#E54290;
                        opacity:1
                      }
                      .review-text {
                          /* Default to line-clamp-5 */
                          display: -webkit-box;
                          -webkit-line-clamp: 5;
                          -webkit-box-orient: vertical;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        }
                        .review-text.full {
                          /* Show full text if showFullText is true */
                          -webkit-line-clamp: unset;
                          overflow: unset;
                        }
                    `}
      </style>
      <div className="slider-container">
        {reviewData?.length > 0 ?
          <Slider {...settings}>
            {reviewData?.map((item, index) => (
              <div key={index}>
                <Card
                  style={{
                    padding: "20px",
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                >
                  <Grid
                    container
                    gap={1}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Grid item className="flex" alignItems={"center"} gap={1}>
                      {item?.profile_photo_url ? (
                        <Grid>
                          <img
                            src={item?.profile_photo_url}
                            width={30}
                            height={30}
                            style={{ objectFit: "scale-down" }}
                          />
                        </Grid>
                      ) : (
                        <Grid className="rounded-full w-8 h-8 text-white font-bold flex items-center justify-center bg-orange-400 capitalize">
                          {item?.author_name?.slice(0, 1)}
                        </Grid>
                      )}
                      <Grid>
                        <Grid className="text-sm font-bold capitalize">
                          <a href={item?.author_url} target="_blank">
                            {item?.author_name}
                          </a>
                        </Grid>
                        <Grid className="text-xs">
                          {item?.relative_time_description}
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* <Grid item className="text-right">
                    <MoreVertIcon />
                  </Grid> */}
                  </Grid>

                  <Grid paddingTop={2} justifyContent={"center"} container>
                    <div
                      // className={
                      //   showFullText[item.id]
                      //     ? "text-sm text-center"
                      //     : "text-sm line-clamp-5 text-center"
                      // }
                      className={`review-text ${showFullText[item.id] ? "full" : ""
                        }`}
                      ref={(el) => (textRefs.current[index] = el)}
                    >
                      {/* <Tooltip title={item?.text}> */}
                      {item?.text}

                      {/* </Tooltip> */}
                    </div>
                    {/* {!showFullText[item.id] && (
                    <Button
                      variant="text"
                      onClick={() =>
                        setShowFullText((prev) => ({
                          ...prev,
                          [item.id]: true,
                        }))
                      }
                    >
                      More
                    </Button>
                  )}
                  {showFullText[item.id] && (
                    <Button
                      onClick={() =>
                        setShowFullText((prev) => ({
                          ...prev,
                          [item.id]: false,
                        }))
                      }
                    >
                      Show Less
                    </Button>
                  )} */}
                  </Grid>
                  <Grid container pt={3} justifyContent={"center"}>
                    <Grid className="text-xs flex items-center text-center justify-center">
                      {" "}
                      <Rating
                        name="read-only"
                        size="small"
                        value={item?.rating}
                        readOnly
                      />{" "}
                      {/* &nbsp; {item?.relative_time_description} */}
                    </Grid>
                  </Grid>
                </Card>
              </div>
            ))}
          </Slider> : <div className="text-center h-32">No Data Available</div>
        }

      </div>
      {reviewData?.length > 0 &&

        <Grid container justifyContent={"center"} paddingTop={5} paddingBottom={4}>
          {/* <Button variant='outlined'
          sx={{
            borderColor: "#E54290", borderRadius: "12px", color: "#E54290", padding: "5px 10px", textTransform: 'capitalize', mt: "8px", '&:hover': {
              borderColor: "#E54290", bgcolor: '#E54290', color: '#FFFFFF'
            }
          }}
          onClick={() => navigate(`/${value}/testimonial`)}
          className="cursor-pointer"
        >
          See all Reviews
        </Button> */}

          <div className="mt-10">
            <Button
              variant="outlined"
              // onClick={() => navigate(`/${value}/testimonial`)}

              sx={{
                borderColor: "#E54290",
                textTransform: "capitalize",
                color: "#E54290",
                borderRadius: "10px",
                width: { xs: '200px', md: '255px' },
                height: { xs: '44px', md: "54px" },
                '&:hover': { backgroundColor: '#E54290', borderColor: '#E54290', color: 'white' },
              }}
              endIcon={<ArrowForward />}
            >
              <Typography sx={{ fontSize: { xs: '14px', md: "18px", fontWeight: 500 } }}>See all Reviews
              </Typography>
            </Button>
          </div>
        </Grid>
      }
    </div>
  );
};

export default CustomSlick;
