import axios from "axios";
import UserService from "./UserService";
import { localstore } from "../components/localstore/localstore";


const {

  /* headers */
  REACT_APP_MAIN_TENANT: TENANT,
  REACT_APP_PARAMITHA_ID: ORGID,

  /* APIS */
  REACT_APP_API_URL_v4: paramitha_v4,
  REACT_APP_API_URL_v1: paramitha_v1,
  REACT_APP_API_Docsteth: docsteth,
  REACT_APP_API_URL_OTP: DOC_OTP,
  REACT_APP_API_URL_FEED: FEED,
  REACT_APP_API_URL_ADDRESS: ADDRESS,
  REACT_APP_API_URL_ELIB: PY_ELIB,
  REACT_APP_API_URL_LANGUAGE: PY_COMMU,
  REACT_APP_C_M_API_URL: PY_HCM,

} = process.env

const getBaseURL = (apiUrl, realm, headers) => {
  // axios instance for making requests
  const axiosInstance = axios.create({
    baseURL: apiUrl,
    headers: headers || {
      ...(realm && {
        'X-FHIR-TENANT-ID': realm.toLowerCase(),
        'X-FHIR-DSID': `fhir${realm}`,
        'realm': realm
      }),
    }
  });
  return axiosInstance;
};

const getBaseUrlV1 = (apiUrl, realm, headers) => {
  // axios instance for making requests
  const axiosInstance = axios.create({
    baseURL: `${apiUrl}${realm}`,
    headers: headers || {
      ...(realm && {
        'X-FHIR-TENANT-ID': realm.toLowerCase(),
        'X-FHIR-DSID': `fhir${realm}`,
        'realm': realm
      }),
      'Authorization': `Bearer ${localstore.getToken()}`
      // 'Authorization': `Bearer ${UserService.getToken()}`
    }
  });
  // axiosInstance.headers['Authorization'] = `Bearer ${UserService.getToken()}`
  return axiosInstance;
};
const getBaseUrlV4 = (apiUrl, realm, headers) => {
  // axios instance for making requests
  const axiosInstance = axios.create({
    baseURL: `${apiUrl}${realm}`,
    headers: headers || {
      ...(realm && {
        'X-FHIR-TENANT-ID': realm.toLowerCase(),
        'X-FHIR-DSID': `fhir${realm}`,
        'realm': realm
      }),
    }
  });
  return axiosInstance;
};



/* fhir-server APIS - https://dev.api.clinivault.org/fhir-server/api/v4/ */
export const API_FHIR = getBaseUrlV4(process.env.REACT_APP_API_URL_v4, 'parimitha');
export const API = getBaseURL(paramitha_v1, TENANT);
export const API_v1 = getBaseUrlV1(paramitha_v1, TENANT);
export const API_V4 = getBaseUrlV4(paramitha_v4, TENANT)


