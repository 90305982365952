import React, { useEffect, useState } from 'react'
import CommonDialog from '../atoms/commonModal'
import { Autocomplete, Box, Button, Container, Avatar, DialogActions, DialogContent, DialogTitle, Divider, Grid, MenuItem, TextField, Typography, Checkbox, FormControlLabel, CircularProgress, InputAdornment } from '@mui/material';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import FormikCommoncalender, { Commoncalender } from '../atoms/commoncalender';
import { CustomTextField } from '../atoms/TextField';
import SelectOptions from '../atoms/selectoptions';
import { genderOptions } from '../../shared/constants';
import dayjs from 'dayjs';
import { relation } from '../atoms/RelationData';
import { ArrowForward, Logout, Person, Verified } from '@mui/icons-material';
import axios from 'axios';
import { getPatientdatafromibm, getPatientIdDetails, getRelationship, getSwitchProfiles } from '../../redux/actions/actions';
import { useDispatch, useSelector } from 'react-redux';
import { localstore } from '../localstore/localstore';
import Patientdata, { Ibmpatient } from '../atoms/patientdata';
import moment from 'moment';
import URL from '../../services/api_url';
import BranchUrl from '../../services/api_branch_url';
import ContainedButton, { OutlinedButton } from '../atoms/commonbutton';
import ReCAPTCHA from 'react-google-recaptcha';
import ErrorStatusMsg from '../atoms/ErrorStatusMsg';
import { debounce } from 'lodash';
import StringAvatar from '../atoms/stringAvatar';
import LinkedProfiles from './linkedProfiles';


function AddProfile({ open, setProfileOpen, selectedbranch }) {
    const personDetails = useSelector((state) => state.paramitha.patientDetails)
    // console.log(personDetails, "person")
    const dispatch = useDispatch()
    const ibmId = localstore.getIbmId()
    const usertoken = localstore.getToken()
    const [userRes, setUserRes] = useState(0)
    const [show, setShow] = useState(1)
    const [captchaToken, setCaptchToken] = useState('')
    const [otp, setOtp] = useState('')
    const [birthDate, setBirthDate] = useState('')
    const [gender, setGender] = useState('')
    const [patientId, setPatientId] = useState('')
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('');
    const [peopleData, setPeopleData] = useState({})
    const [alertmessage, setAlertmessage] = useState('')
    const [alertstatus, setAlertstatus] = useState('')

    const [showOtp, setShowOtp] = useState(false)
    const [personData, setPersonData] = useState({})

    const urls = BranchUrl()
    const [profileData, setProfileData] = useState([])

    const [phoneNumber, setPhoneNumber] = useState('')
    const [loading, setLoading] = useState(false)

    const [checkedItems, setCheckedItems] = useState({});
    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);

    const signUpValidationSchema = yup.object().shape({
        firstName: yup.string().required('First Name is required'),
        lastName: yup.string().required('Last Name is required'),
        // relatedPerson: yup.string().required("Select Related Person is required"),
        gender: yup.string().required("Gender is required"),
        birthDate: yup.date().required('Date of Birth is required'),
        // phone: yup.string()
        //     .required('Phone Number is required')
        //     .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits'),
    })

    const Authorization = {
        'X-FHIR-TENANT-ID': 'parimitha',
        'X-FHIR-DSID': `fhirparimitha`,
        'Authorization': `Bearer ${usertoken}`,
        'realm': 'parimitha'
    }


    useEffect(() => {
        if (personDetails?.telecom?.[0]?.value) {
            setPhoneNumber(personDetails.telecom[0].value);
        }
    }, [personDetails]);


    const [phoneError, setPhoneError] = useState('');

    const handleChangePhone = (e) => {
        const { value } = e.target;
        const numericValue = value.replace(/\D/g, '').slice(0, 10); // Remove non-numeric characters

        // Update phone state
        setPhoneNumber(numericValue);

        // Validate phone number
        if (numericValue.length !== 10) {
            setPhoneError('Phone number must be exactly 10 digits');
        } else {
            setPhoneError(''); // Clear error if valid
        }
    };


    // const debouncedCheckPhone = debounce((value, setFieldError) => {

    //     axios.get(`${urls.person_tenant_orgId}Patient?phone=${value}&active=true`, {
    //         headers: Authorization
    //     })
    //         .then(res => {
    //             setUserRes(res.data.total)
    //         })
    //         .catch(error => {
    //             if (error.response.status === 500) {
    //                 setFieldError('EmailId', "Internal Server Error");
    //             } else {
    //                 setFieldError('EmailId', error.response.data);
    //             }
    //         });
    // }, 500); //

    const handleChangeOtp = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setOtp(inputValue.slice(0, 6));
        setAlertmessage('')
    }
    const handleRecaptchaChange = (value) => {
        setCaptchToken(value)
        setAlertmessage('')
    };

    const handleEmailAddress = (e) => {
        const value = e.target.value;
        setEmail(value);

        // Basic email validation using regex
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(value)) {
            setEmailError('Please enter a valid email address');
        } else {
            setEmailError(''); // Clear error if email is valid
        }
    };


    const handleSendOtp = async (values, resetForm) => {
        const data = {
            "messagetype": "OTP",
            "apptype": "Paramitha",
            "phoneNumber": `+91${phoneNumber}`,
            "email": "",
            'realm': 'parimitha'
        };

        if (captchaToken) {
            setLoading(true);
            try {
                const res = await axios.post(`${URL.sendOtp}`, data, {
                    headers: {
                        "Authorization": `Bearer ${captchaToken}`,
                        "Usertoken": `${usertoken}`
                    }
                });
                if (res.status === 200 || res.status === 201) {
                    setAlertmessage(res.data.msg);
                    setAlertstatus('success');
                    setShowOtp(true);
                    // handleSubmitData(values, resetForm);
                }
            } catch (error) {
                const errorMessage = error.response ? error.response.data.msg : "An error occurred while sending OTP.";
                setAlertmessage(errorMessage);
                setAlertstatus('error');
            } finally {
                setLoading(false);
            }
        } else {
            setAlertmessage('Please verify the reCAPTCHA!');
            setAlertstatus('error');
        }
    };

    // console.log(userRes, "count")
    const handleSubmitData = async (values, resetForm) => {

        const dob = values?.birthDate?.toISOString();
        const momentDate = moment(dob);
        const birth = momentDate.format('YYYY-MM-DD');
        setBirthDate(birth);
        setGender(values.gender)
        const patientdata = {
            "active": true,
            "address": [],
            "communication": [],
            "identifier": [],
            "gender": values.gender.toLowerCase() || '',
            "birthDate": birth || '',
            "name": [
                {
                    "use": "official",
                    "given": [values.firstName || ''],
                    "family": values.lastName || '',
                    "text": values.firstName || ''
                }
            ],
            "photo": [],
            "resourceType": "Patient",
            "telecom": [
                {
                    "use": "mobile",
                    "value": `${phoneNumber || ''}`,
                    "system": "phone"
                },
                email ? {
                    "use": "home", // Typically, "home" or "work" is used for email
                    "value": email,
                    "system": "email"
                } : null
            ].filter(Boolean),
            "managingOrganization": {
                "reference": `Organization/${selectedbranch?.id || process.env.REACT_APP_PARAMITHA_ID}`
            }
        };


        setPersonData(patientdata)

        try {
            const res = await axios.get(`${urls.patient_resource_tenant}Patient?birthdate=${birth}&phone=${phoneNumber}&gender=${values?.gender}`, {
                headers: Authorization
            });

            const count = res?.data?.total;
            setProfileData(res?.data?.entry || [])

            setUserRes(count)

            if (count === 0) {
                // try {
                //     const personRes = await axios.post(`${urls.person_tenant_orgId}Patient`, patientdata, {
                //         headers: Authorization
                //     });

                //     setPatientId(personRes?.data);
                // hanldeLinking(personRes?.data);
                setShow(2)

                // } catch (err) {
                //     setAlertmessage(err.message);
                //     setAlertstatus('error');
                // }
            } else {
                // try {
                //     const personRes = await axios.post(`${urls.person_tenant_orgId}Patient`, patientdata, {
                //         headers: Authorization
                //     });

                //     setPatientId(personRes.data);
                setShow(3);
                // } catch (err) {
                //     setAlertmessage(err.message);
                //     setAlertstatus('error');
                // }
            }
            // setShowOtp(true)
            // const data = {
            //     "paramitha": "True",
            //     "phoneNumber": `+91${phone}`,
            //     "otp": otp,
            //     "email": "",
            //     'realm': 'parimitha'
            // };

            // setLoading(true);
            // try {
            //     const res = await axios.post(`${URL.verifyOtp}?verify=True`, data, {
            //         headers: {
            //             "usertoken": `Bearer ${usertoken}`,
            //         }
            //     });
            //     if (res.status === 200 || res.status === 201) {
            //         setAlertmessage('OTP verified successfully');
            //         setAlertstatus('success');
            //         updatePatientDetails(patientdata, birth);
            //     }
            // } catch (error) {
            //     setAlertmessage(error.message);
            //     setAlertstatus('error');
            // } finally {
            //     setLoading(false);
            // }

            // if (count !== 0) {
            //     updatePatientDetails(patientdata, birth);

            // }

            // else if (captchaToken && showOtp && otp) {
            //     setShowOtp(true)
            //     const data = {
            //         "paramitha": "True",
            //         "phoneNumber": `+91${phone}`,
            //         "otp": otp,
            //         "email": "",
            //         'realm': 'parimitha'
            //     };

            //     setLoading(true);
            //     try {
            //         const res = await axios.post(`${URL.verifyOtp}?verify=True`, data, {
            //             headers: {
            //                 "usertoken": `Bearer ${usertoken}`,
            //             }
            //         });
            //         if (res.status === 200 || res.status === 201) {
            //             setAlertmessage('OTP verified successfully');
            //             setAlertstatus('success');
            //             updatePatientDetails(patientdata, birth);
            //         }
            //     } catch (error) {
            //         setAlertmessage(error.message);
            //         setAlertstatus('error');
            //     } finally {
            //         setLoading(false);
            //     }
            // } else {
            //     setAlertmessage('Please Verify OTP');
            //     setAlertstatus('error');
            // }
        } catch {

        }
    };

    const updatePatientDetails = async (data, birth) => {
        try {
            const res = await axios.get(`${urls.person_tenant_orgId}Patient?birthdate=${birth}&phone=${phoneNumber}`, {
                headers: Authorization
            });

            const count = res?.data?.total;
            if (count === 0) {
                try {
                    const personRes = await axios.post(`${urls.person_tenant_orgId}Patient`, data, {
                        headers: Authorization
                    });

                    setPatientId(personRes?.data);
                    hanldeLinking(personRes?.data);
                } catch (err) {
                    setAlertmessage(err.message);
                    setAlertstatus('error');
                }
            } else {
                try {
                    const personRes = await axios.post(`${urls.person_tenant_orgId}Patient`, data, {
                        headers: Authorization
                    });

                    setPatientId(personRes.data);
                    setShow(3);
                } catch (err) {
                    setAlertmessage(err.message);
                    setAlertstatus('error');
                }
            }
        } catch (err) {
            setAlertmessage(err.message);
            setAlertstatus('error');
        }
    };


    const [createProfileDisabled, setCreateProfileDisabled] = useState(false);
    const [linkDisabled, setLinkDisabled] = useState(true); // Initially disabled

    // console.log(selectedItems, "select")
    const handleCheckboxChange = (index, item) => {
        const isChecked = !checkedItems[index];
        const newCheckedItems = {
            ...checkedItems,
            [index]: isChecked,
        };
        setCheckedItems(newCheckedItems);

        // Update selectedItems array
        const newSelectedItems = isChecked
            ? [...selectedItems, item]
            : selectedItems.filter(selectedItem => selectedItem !== item);

        setSelectedItems(newSelectedItems);

        // Update button states
        if (Object.values(newCheckedItems).some(value => value)) {
            // If at least one checkbox is checked
            setLinkDisabled(false);
            setCreateProfileDisabled(true);
        } else {
            // If no checkbox is checked
            setLinkDisabled(true);
            setCreateProfileDisabled(false);
        }
    };

    const handleSelectAllChange = () => {
        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);
        setCheckedItems(
            profileData.reduce((acc, _, index) => {
                acc[index] = newSelectAll;
                return acc;
            }, {})
        );
        const newSelectedItems = newSelectAll ? profileData : [];
        setSelectedItems(newSelectedItems);
        if (newSelectAll) {
            setLinkDisabled(false);
            setCreateProfileDisabled(true);
        } else {
            setLinkDisabled(true);
            setCreateProfileDisabled(false);
        }
    };
    useEffect(() => {
        setLinkDisabled(true);
        setCreateProfileDisabled(false);
    }, []);
    // const handleOnChangeDate = (newValue) => {
    //     setBirthDate(newValue?.format("YYYY-MM-DD"))
    // }
    // const handleSearchPatients = async () => {
    //     if (birthDate && phone && gender) {
    //         try {
    //             const res = await axios.get(`${urls.person_tenant_orgId}Patient?birthdate=${birthDate}&phone=${phone}&gender=${gender}`, {
    //                 headers: Authorization
    //             })

    //             setProfileData(res?.data?.entry || [])
    //         } catch (error) {
    //             console.log(error)
    //         }
    //     }
    // }
    // useEffect(() => {
    //     if (birthDate && phone) {
    //         handleSearchPatients()
    //     }
    // }, [birthDate, phone])
    const handleCloseShow1 = () => {
        setProfileOpen(false)
        setShowOtp(false)
        setCaptchToken('')
        setAlertmessage('')
        setOtp('')
        setEmail('')
        setEmailError('')
        setUserRes(0)
    }
    const handleCloseShow2 = () => {
        handleCloseShow1()
        setBirthDate('')
        setSelectedItems([])
        setProfileData([])
        setCheckedItems({})
        setSelectAll(false)
        setShow(1)
    }

    const hanldeLinking = async (patientId) => {
        try {
            const personRes = await axios.post(`${urls.person_tenant_orgId}Patient`, personData, {
                headers: Authorization
            });

            setPatientId(personRes?.data);
            try {
                const res = await axios.get(`${urls.person_tenant_orgId}Person/${ibmId}`, {
                    headers: Authorization
                });
                let patientres = res?.data;

                const newLink = {
                    target: {
                        reference: `Patient/${personRes?.data}` // Assuming the response contains the new patient's ID
                    }
                };

                // Check if the link array exists; if not, initialize it
                if (!patientres.link) {
                    patientres.link = [];
                }

                // Add the new link to the link array
                const updatedLinks = [...patientres.link, newLink];

                // Update the patientdata with the new links array
                const updatedPatientData = {
                    ...patientres,
                    link: updatedLinks
                };
                await axios.put(`${urls.person_tenant_orgId}Person/${ibmId}`, updatedPatientData, {
                    headers: Authorization
                }).then(res => {
                    dispatch(getPatientdatafromibm(urls, usertoken, ibmId))
                    handleCloseShow2()
                })


                // Uncomment and complete as needed
                // dispatch(getSwitchProfiles(urls, fixedIbmId, usertoken));
                // resetForm();
                // setProfileOpen(false);
            } catch (error) {
                console.error("Error handling patient link:", error);
            }

        } catch (err) {
            setAlertmessage(err.message);
            setAlertstatus('error');
        }

    }

    const handleCreateProfile = async () => {
        // setCreateProfile(true)
        try {
            const res = await axios.get(`${urls.person_tenant_orgId}Person/${ibmId}`, {
                headers: Authorization
            });

            const mobilecheck = res.data.telecom?.[0]?.value
            setPeopleData(res.data)
            if (mobilecheck === phoneNumber) {
                hanldeLinking()
            } else {
                setShow(5)
            }
        }
        catch (error) {

        }
    }


    const handleCreatePatient = async (patientdata) => {
        try {
            const personRes = await axios.post(`${urls.person_tenant_orgId}Patient`, patientdata, {
                headers: Authorization
            });

            setPatientId(personRes.data);
        } catch (err) {
            setAlertmessage(err.message);
            setAlertstatus('error');
        }
    }

    const handleVerifyCreatePatient = async () => {
        if (captchaToken && showOtp && otp) {
            setShowOtp(true)
            const data = {
                "paramitha": "True",
                "phoneNumber": `+91${phoneNumber}`,
                "otp": otp,
                "email": "",
                'realm': 'parimitha'
            };

            setLoading(true);
            try {
                const res = await axios.post(`${URL.verifyOtp}?verify=True`, data, {
                    headers: {
                        // "usertoken": `Bearer ${usertoken}`,
                        "usertoken": `${usertoken}`,
                    }
                });
                if (res.status === 200 || res.status === 201) {
                    setAlertmessage('OTP verified successfully');
                    setAlertstatus('success');
                    hanldeLinking()
                }
            } catch (error) {
                setAlertmessage(error.message);
                setAlertstatus('error');
            } finally {
                setLoading(false);
            }
        } else {
            setAlertmessage('Please Verify OTP');
            setAlertstatus('error');
        }


    }
    const handleLinkPatient = async () => {
        try {
            const res = await axios.get(`${urls.person_tenant_orgId}Person/${ibmId}`, {
                headers: Authorization
            });

            const mobilecheck = res.data.telecom?.[0]?.value
            setPeopleData(res.data)
            if (mobilecheck === phoneNumber) {
                handleMobileCheckSubmit(res?.data)
            } else {
                setShow(4)
            }

            // Use let instead of const to allow reassignment
            // let patientDetails = res?.data;
            // const newLinks = selectedItems.map(person => ({
            //     target: {
            //         reference: `Patient/${person.resource.id}`
            //     }
            // }));

            // // Ensure the link field exists and is an array
            // if (!patientDetails.link) {
            //     patientDetails.link = [];
            // }

            // // Add the new links to the existing links
            // // patientDetails.link = [...patientDetails.link, ...newLinks];
            // // Update Person details
            // const existingPatientIds = new Set(patientDetails.link.map(link => link.target.reference));

            // // Filter out new links that are already present in existing links
            // const uniqueNewLinks = newLinks.filter(link => !existingPatientIds.has(link.target.reference));

            // // Add the unique new links to the existing links
            // patientDetails.link = [...patientDetails.link, ...uniqueNewLinks];

            // await axios.put(`${urls.person_tenant_orgId}Person/${ibmId}`, patientDetails, {
            //     headers: Authorization
            // }).then(res => {
            //     setShow(4)

            // })


            // // Uncomment and complete as needed
            // // dispatch(getSwitchProfiles(urls, fixedIbmId, usertoken));
            // // resetForm();
            // // setProfileOpen(false);
        } catch (error) {
            console.error("Error handling patient link:", error);
        }
    }


    const handleMobileCheckSubmit = async (data) => {
        // Use let instead of const to allow reassignment
        let patientDetails = data;
        const newLinks = selectedItems.map(person => ({
            target: {
                reference: `Patient/${person.resource.id}`
            }
        }));

        // Ensure the link field exists and is an array
        if (!patientDetails.link) {
            patientDetails.link = [];
        }

        // Add the new links to the existing links
        // patientDetails.link = [...patientDetails.link, ...newLinks];
        // Update Person details
        const existingPatientIds = new Set(patientDetails.link.map(link => link.target.reference));

        // Filter out new links that are already present in existing links
        const uniqueNewLinks = newLinks.filter(link => !existingPatientIds.has(link.target.reference));

        // Add the unique new links to the existing links
        patientDetails.link = [...patientDetails.link, ...uniqueNewLinks];

        await axios.put(`${urls.person_tenant_orgId}Person/${ibmId}`, patientDetails, {
            headers: Authorization
        }).then(res => {
            dispatch(getPatientdatafromibm(urls, usertoken, ibmId))
            handleCloseShow2()
        })


        // Uncomment and complete as needed
        // dispatch(getSwitchProfiles(urls, fixedIbmId, usertoken));
        // resetForm();

    }
    const handleVerifyOtp = async () => {

        if (captchaToken && showOtp && otp) {
            setShowOtp(true)
            const data = {
                "paramitha": "True",
                "phoneNumber": `+91${phoneNumber}`,
                "otp": otp,
                "email": "",
                'realm': 'parimitha'
            };

            setLoading(true);
            try {
                const res = await axios.post(`${URL.verifyOtp}?verify=True`, data, {
                    headers: {
                        // "usertoken": `Bearer ${usertoken}`,
                        "usertoken": `${usertoken}`,
                    }
                });
                if (res.status === 200 || res.status === 201) {
                    setAlertmessage('OTP verified successfully');
                    setAlertstatus('success');
                    if (show === 2) {
                        hanldeLinking(patientId)
                    } else {
                        // dispatch(getPatientdatafromibm(urls, usertoken, ibmId))
                        // handleCloseShow2()
                        handleMobileCheckSubmit(peopleData)
                    }
                    // updatePatientDetails(patientdata, birth);
                }
            } catch (error) {
                setAlertmessage(error.message);
                setAlertstatus('error');
            } finally {
                setLoading(false);
            }
        } else {
            setAlertmessage('Please Verify OTP');
            setAlertstatus('error');
        }

    }

    // const filteredProfileData = profileData.filter(person => {
    //     const personId = person?.resource?.id;
    //     return !personDetails.link.some(link => link.target.reference.includes(personId));
    // });
    return (
        < CommonDialog maxWidth={show === 1 ? 'md' : 'md'} onClose={() => setProfileOpen(false)}
            open={open}
        >

            <DialogTitle sx={{ p: { xs: '0px 25px', md: "10px 40px 0px 40px" }, }}>
                <div className='text-center text-xl md:text-2xl font-medium'>Add New Profile</div>
                <div className='text-xs text-center font-normal text-[#E44190]'>@ {selectedbranch?.name || 'Paramitha Hospitals'} </div>
                <hr style={{ margin: '10px 0px' }} />
            </DialogTitle>
            {
                show === 1 ?
                    <Formik
                        validationSchema={signUpValidationSchema}
                        initialValues={{ firstName: "", lastName: "", birthDate: "", gender: "", }}
                        enableReinitialize={true}
                        onSubmit={async (values, { resetForm }) => {
                            // if (userRes === 0) {
                            //     handleSendOtp(values, resetForm)
                            // }
                            // else {
                            axios.get(`${URL.check}/check/validMobileNumber/${phoneNumber}`).then(
                                res => {
                                    if (res.status === 200 || res.status === 201) {
                                        handleSubmitData(values, resetForm)
                                        // }
                                    }
                                }).catch(error => {
                                    if (error.response && error.response.status === 404) {
                                        setPhoneError("Invalid Mobile Number")
                                    }
                                })
                        }
                        }
                    >
                        {
                            ({
                                handleSubmit,
                                isValid,
                                values,
                                errors,
                                handleChange,
                                handleBlur,
                                touched,
                                setFieldValue,
                                resetForm,
                                setFieldTouched,
                                setFieldError

                            }) => (
                                <>
                                    <DialogContent sx={{ p: '10px 40px 20px' }}>

                                        <Box >

                                            <Grid container columnSpacing={3} rowSpacing={2} pt={'8px'} >
                                                <Grid item xs={12} md={6}>
                                                    <Box >
                                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>First Name</label>
                                                    </Box>
                                                    <CustomTextField
                                                        placeholder="First Name"
                                                        type={'text'}
                                                        borderColor="#59D8FA"
                                                        value={values.firstName}
                                                        borderRadius="7px"
                                                        // onChange={(event) => { handleChangeReg(event.target.name, event.target.value) }} // Corrected: passing event object
                                                        onChange={handleChange('firstName')}
                                                        handleBlur={handleBlur('firstName')}
                                                        error={touched.firstName && Boolean(errors.firstName)}
                                                    />
                                                    {errors.firstName && touched.firstName && (
                                                        <Typography sx={{ color: '#D32F2F', fontSize: '14px' }}>
                                                            {touched.firstName && errors.firstName}
                                                        </Typography>
                                                    )}
                                                </Grid>



                                                <Grid item xs={12} md={6}>
                                                    <Box >
                                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>Last Name</label>
                                                    </Box>
                                                    <CustomTextField
                                                        placeholder="Last Name"
                                                        type={'text'}
                                                        borderColor="#59D8FA"
                                                        value={values.lastName}
                                                        // onChange={(event) => { handleChangeReg(event.target.name, event.target.value) }} // Corrected: passing event object
                                                        onChange={handleChange('lastName')}
                                                        handleBlur={handleBlur('lastName')}
                                                        borderRadius="7px"
                                                        error={touched.lastName && Boolean(errors.lastName)}
                                                    />
                                                    {errors.lastName && touched.lastName && (
                                                        <Typography sx={{ color: '#D32F2F', fontSize: '14px' }}>
                                                            {touched.lastName && errors.lastName}
                                                        </Typography>
                                                    )}

                                                </Grid>

                                                <Grid item xs={12} md={6}>
                                                    <Box >
                                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>Gender</label>
                                                    </Box>

                                                    <SelectOptions borderRadius="7px" value={values.gender} defaultName="Select your Gender" onChange={(event) => setFieldValue('gender', event.target.value)} error={touched.gender && Boolean(errors.gender)}>
                                                        {genderOptions.map(option => (
                                                            <MenuItem key={option} value={option}>{option}</MenuItem>
                                                        ))}
                                                    </SelectOptions>
                                                    {errors.gender && touched.gender && (
                                                        <Typography sx={{ color: '#D32F2F', fontSize: '14px' }}>
                                                            {touched.gender && errors.gender}
                                                        </Typography>
                                                    )}

                                                </Grid>
                                                <Grid item xs={12} md={6}
                                                    sx={{
                                                        ".MuiStack-root": {
                                                            paddingTop: '0px'
                                                        },
                                                    }}>
                                                    <Box>
                                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>Date of Birth</label>
                                                    </Box>

                                                    <Field
                                                        name="birthDate"
                                                        component={FormikCommoncalender}
                                                        maxDate={dayjs()}
                                                        width="100%"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}
                                                    sx={{
                                                        ".MuiStack-root": {
                                                            paddingTop: '0px'
                                                        },
                                                    }}>
                                                    <Box>
                                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>Phone Number</label>
                                                    </Box>
                                                    {/* <CustomTextField
                                                        placeholder="Phone Number"
                                                        type="text"
                                                        borderColor="#59D8FA"
                                                        value={values.phone}
                                                        // disabled={userRes !== 0}
                                                        padding={"0px"}
                                                        onChange={(e) => handleChangePhone(e, setFieldTouched, setFieldValue, setFieldError)}
                                                        onBlur={handleBlur('phone')}
                                                        borderRadius="7px"
                                                        // icon={
                                                        //     captchaToken && userRes === 0 ? (
                                                        //         <ArrowForward
                                                        //             sx={{
                                                        //                 backgroundColor: "#1B5775",
                                                        //                 fontSize: '40px',
                                                        //                 color: "white",
                                                        //                 borderRadius: "0px 7px 7px 0px",
                                                        //             }}
                                                        //             onClick={handleSendOtp}
                                                        //         />
                                                        //     ) : (
                                                        //         // <Verified />
                                                        //         null
                                                        //     )
                                                        // }
                                                        error={touched.phone && Boolean(errors.phone)}
                                                    /> */}
                                                    <TextField
                                                        fullWidth
                                                        size="small"
                                                        type={'text'}
                                                        value={phoneNumber}
                                                        onChange={handleChangePhone}
                                                        // onBlur={handleBlur('phone')}

                                                        placeholder="Enter Phone Number"
                                                        sx={{
                                                            width: '100%',
                                                            ".MuiInputBase-root": {
                                                                borderRadius: '7px',
                                                                // backgroundColor: "rgb(243, 244, 246)"
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: '#59D8FA',
                                                                },
                                                            },
                                                        }}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    +91
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    // error={touched.phone && Boolean(errors.phone)}

                                                    />


                                                    {phoneError && (
                                                        <Typography sx={{ color: '#D32F2F', fontSize: '14px' }}>
                                                            {phoneError}
                                                        </Typography>
                                                    )}


                                                    {/* {showOtp ? null :
                                                        < Box sx={{ mt: 4, transform: 'scale(0.8)', transformOrigin: '0 0' }}>

                                                            <ReCAPTCHA
                                                                sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
                                                                onChange={handleRecaptchaChange}
                                                                size="normal"
                                                            // style={{ transform: 'scale(0.9)' }}
                                                            />
                                                        </Box>
                                                    } */}

                                                    {/* <div className='flex justify-end'>
                                                        <OutlinedButton
                                                            width="160px"
                                                            height="32px"
                                                            fontSize="14px"
                                                            borderColor="#1B5775"
                                                            text="Request OTP"
                                                            color="#1B5775"
                                                            borderRadius="7px"
                                                            fontWeight={600}
                                                            onClick={handleSendOtp} // onClick event handler

                                                            startIcon={null} />
                                                    </div> */}

                                                </Grid>

                                                {/* {showOtp && captchaToken &&

                                                    < Grid item xs={12} md={6}>

                                                        <Box >
                                                            <label style={{ fontWeight: 500, fontSize: '14px' }}>Verify OTP</label>
                                                        </Box>
                                                        <CustomTextField
                                                            placeholder="Enter OTP"
                                                            type={'text'}
                                                            borderColor="#59D8FA"
                                                            value={otp}
                                                            onChange={handleChangeOtp}
                                                            borderRadius="7px"
                                                        />

                                                    </Grid>
                                                } */}
                                                <Grid item xs={12} md={6}
                                                    sx={{
                                                        ".MuiStack-root": {
                                                            paddingTop: '0px'
                                                        },
                                                    }}>
                                                    <Box>
                                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>Email Address (optional)</label>
                                                    </Box>
                                                    <CustomTextField
                                                        placeholder="Email Address"
                                                        type="text"
                                                        borderColor="#59D8FA"
                                                        value={email}
                                                        // disabled={userRes !== 0}
                                                        padding={"0px"}
                                                        onChange={handleEmailAddress}
                                                        borderRadius="7px"

                                                    />
                                                    {emailError && <Typography sx={{ color: '#D32F2F', fontSize: '14px' }}>
                                                        {emailError}
                                                    </Typography>}

                                                    {/* {errors.phone && touched.phone && (
                                                        <Typography sx={{ color: '#D32F2F', fontSize: '14px' }}>
                                                            {touched.phone && errors.phone}
                                                        </Typography>
                                                    )} */}



                                                </Grid>
                                            </Grid>
                                        </Box>

                                    </DialogContent >
                                    <Divider />
                                    <div className='text-center'><ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} /></div>
                                    <DialogActions sx={{ m: "5px 20px" }}>
                                        <Grid container justifyContent={"space-between"} px={3}>
                                            <Button color='error' sx={{ textTransform: 'capitalize' }} onClick={() => {
                                                resetForm();
                                                handleCloseShow1()

                                            }}>Cancel</Button>

                                            <Button type="submit" variant="contained" sx={{ width: "150px", textTransform: 'capitalize', bgcolor: '#1B5775', "&:hover": { bgcolor: '#1B5775' }, height: "33px", borderRadius: '7px' }} onClick={handleSubmit} >
                                                {/* Submit */}Add Profile
                                            </Button>
                                        </Grid>
                                    </DialogActions>
                                </>

                            )}
                    </Formik> : show === 2 ?
                        <>
                            <DialogContent sx={{ p: '10px 40px 20px' }}>

                                <Box >
                                    <Grid container spacing={3}>

                                        <Grid item xs={12} md={6}
                                            sx={{
                                                ".MuiStack-root": {
                                                    paddingTop: '0px'
                                                },
                                            }}>
                                            <Box>
                                                <label style={{ fontWeight: 500, fontSize: '14px' }}>Phone Number</label>
                                            </Box>
                                            <CustomTextField
                                                placeholder="Phone Number"
                                                type="text"
                                                borderColor="#59D8FA"
                                                value={phoneNumber}
                                                // disabled={userRes !== 0}
                                                padding={"0px"}
                                                // onChange={(e) => handleChangePhone(e, setFieldTouched, setFieldValue, setFieldError)}
                                                // onBlur={handleBlur('phone')}
                                                borderRadius="7px"
                                            // icon={
                                            //     captchaToken && userRes === 0 ? (
                                            //         <ArrowForward
                                            //             sx={{
                                            //                 backgroundColor: "#1B5775",
                                            //                 fontSize: '40px',
                                            //                 color: "white",
                                            //                 borderRadius: "0px 7px 7px 0px",
                                            //             }}
                                            //             onClick={handleSendOtp}
                                            //         />
                                            //     ) : (
                                            //         // <Verified />
                                            //         null
                                            //     )
                                            // }
                                            />

                                            {!showOtp &&
                                                <Box sx={{ mt: 4, transform: 'scale(0.8)', transformOrigin: '0 0' }}>

                                                    <ReCAPTCHA
                                                        sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
                                                        onChange={handleRecaptchaChange}
                                                        size="normal"
                                                    // style={{ transform: 'scale(0.9)' }}
                                                    />
                                                </Box>
                                            }

                                            {/* <div className='flex justify-end'>
                                                <OutlinedButton
                                                    width="160px"
                                                    height="32px"
                                                    fontSize="14px"
                                                    borderColor="#1B5775"
                                                    text="Request OTP"
                                                    color="#1B5775"
                                                    borderRadius="7px"
                                                    fontWeight={600}
                                                    onClick={handleSendOtp} // onClick event handler

                                                    startIcon={null} />
                                            </div> */}

                                        </Grid>

                                        {showOtp &&
                                            < Grid item xs={12} md={6}>

                                                <Box >
                                                    <label style={{ fontWeight: 500, fontSize: '14px' }}>Verify OTP</label>
                                                </Box>
                                                <CustomTextField
                                                    placeholder="Enter OTP"
                                                    type={'text'}
                                                    borderColor="#59D8FA"
                                                    value={otp}
                                                    onChange={handleChangeOtp}
                                                    borderRadius="7px"
                                                />

                                            </Grid>
                                        }
                                    </Grid>
                                </Box>

                            </DialogContent>
                            <Divider />
                            <div className='text-center'><ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} /></div>
                            <DialogActions sx={{ m: "5px 20px" }}>
                                <Grid container justifyContent={"space-between"} px={3}>
                                    <Button color='error' sx={{ textTransform: 'capitalize' }} onClick={() => {
                                        handleCloseShow2()

                                    }}>Cancel</Button>
                                    <Button type="submit" variant="contained" sx={{ width: "150px", textTransform: 'capitalize', bgcolor: '#1B5775', "&:hover": { bgcolor: '#1B5775' }, height: "33px", borderRadius: '7px' }} onClick={showOtp ? handleVerifyOtp : handleSendOtp}>{showOtp ? 'Add Profile' : 'Request OTP'}
                                    </Button>
                                </Grid>
                            </DialogActions>
                        </> : show === 3 ? <>
                            <DialogContent sx={{ p: '10px 40px 20px' }}>
                                {/* <Container> */}


                                <Grid container spacing={3} alignItems={'center'}>

                                    <Grid item xs={12} md={7.5}>
                                        <Typography variant='h6'>Profiles</Typography>


                                    </Grid>
                                    <Grid item xs={12} md={4.5}
                                        sx={{
                                            ".MuiStack-root": {
                                                paddingTop: '0px'
                                            },
                                            display: 'flex', alignItems: 'center', gap: '10px'
                                        }}>

                                        <Commoncalender width="100%" value={dayjs(birthDate)} maxDate={dayjs()} />
                                        {/* <ContainedButton text="Search" width="100%" onClick={handleSearchPatients} /> */}
                                    </Grid>

                                </Grid>
                                <Box sx={{ mt: 1 }}>

                                    {profileData.length > 0 &&
                                        <>
                                            <p className='text-sm mt-2'>Please Verify the below given Profiles which already exists in the System.if those are yours you can link them with this account (or) else you can create a new Profile if those does n't match.</p>
                                            < Grid container alignItems="center" justifyContent="space-between" mt={1}>
                                                <Grid item>
                                                    <Typography fontSize={{ xs: '14px', sm: '14px', md: 16 }} fontWeight={500}>Select All</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <FormControlLabel
                                                        control={<Checkbox checked={selectAll} onChange={handleSelectAllChange} color="primary" />}
                                                        label=""
                                                        sx={{ m: 0 }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </>
                                    }


                                    {profileData.length > 0 ? profileData.map((person, index) => {
                                        return (
                                            <div className='cursor-pointer' key={index}>
                                                <Grid container mt={1} alignItems="center">
                                                    <Grid item xs={10} sm={11} md={3} className="flex items-center" gap={"20px"}>
                                                        <Avatar {...StringAvatar(`${person?.resource?.name?.[0]?.given} ${person?.resource?.name?.[0]?.family}`)} />
                                                        <div>
                                                            <Typography fontSize={'12px'} fontWeight={500} textTransform={'capitalize'}>
                                                                {person?.resource?.name?.[0]?.given} {person?.resource?.name?.[0]?.family}
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={10} sm={11} md={2} className="flex items-center" gap={"20px"}>
                                                        <div>
                                                            <Typography fontSize={'12px'} fontWeight={500} textTransform={'capitalize'}>
                                                                {person?.resource?.gender}
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={10} sm={11} md={3} className="flex items-center" gap={"20px"}>
                                                        <div>
                                                            <Typography fontSize={'12px'} fontWeight={500} textTransform={'capitalize'}>
                                                                {person?.resource?.birthDate}
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={10} sm={11} md={3} className="flex items-center" gap={"20px"}>
                                                        <div>
                                                            <Typography fontSize={'12px'} fontWeight={500} textTransform={'capitalize'}>
                                                                {person?.resource?.telecom?.[0]?.value}
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={2} sm={1} md={1} container justifyContent="flex-end">
                                                        <Checkbox
                                                            checked={checkedItems[index] || false}
                                                            onChange={() => handleCheckboxChange(index, person)}
                                                            color="primary"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        );
                                    }) : (
                                        // <div className='text-center mt-8 text-lg h-32'>No Data Available</div>

                                        <></>
                                    )}

                                    <>
                                        <div className='mt-8 h-32 font-medium'>
                                            {/* It seems that your profile has already been add do you really want to create another */}
                                            Existing Profiles in the account
                                            <div className='pb-4'>

                                                {
                                                    personDetails?.link && personDetails.link.length > 0 ? (
                                                        personDetails.link.map((person, index) => (
                                                            <LinkedProfiles
                                                                key={index}
                                                                person={person}
                                                                StringAvatar={StringAvatar}
                                                            />
                                                        ))
                                                    ) : (
                                                        <div className='text-center mt-1'>No patients found. Add new patients to get started.</div>
                                                    )}
                                                {/* <Grid container key={ind} mt={2} alignItems="center">
                                                <Grid item xs={10} sm={11} md={3} className="flex items-center" gap={"20px"}>
                                                    <Avatar {...StringAvatar(`${profile?.resource?.name?.[0]?.given} ${profile?.resource?.name?.[0]?.family}`)} />
                                                    <div>
                                                        <Typography fontSize={'12px'} fontWeight={500} textTransform={'capitalize'}>
                                                            {profile?.resource?.name?.[0]?.given} {profile?.resource?.name?.[0]?.family}
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={10} sm={11} md={2} className="flex items-center" gap={"20px"}>
                                                    <div>
                                                        <Typography fontSize={'12px'} fontWeight={500} textTransform={'capitalize'}>
                                                            {profile?.resource?.gender}
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={10} sm={11} md={3} className="flex items-center" gap={"20px"}>
                                                    <div>
                                                        <Typography fontSize={'12px'} fontWeight={500} textTransform={'capitalize'}>
                                                            {profile?.resource?.birthDate}
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={10} sm={11} md={3} className="flex items-center" gap={"20px"}>
                                                    <div>
                                                        <Typography fontSize={'12px'} fontWeight={500} textTransform={'capitalize'}>
                                                            {profile?.resource?.telecom?.[0]?.value}
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                            </Grid> */}

                                            </div>

                                        </div>

                                    </>
                                    {/* {createProfile &&
                                        <div className='text-center mt-3 font-medium'>
                                            It seems that your profile has already been add do you really want to add another
                                            <span className='cursor-pointer text-[#E44190]' onClick={handleCreateProfile}> (Yes)</span> / <span className='cursor-pointer text-[#1B5775]' onClick={handleCloseShow2}>(No)</span>
                                        </div>
                                    } */}
                                </Box>
                                {/* </Container> */}
                            </DialogContent>

                            <Divider />
                            {/* <div className='text-center'><ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} /></div> */}
                            <DialogActions sx={{ m: "5px 20px" }}>
                                <Grid container justifyContent={"space-between"} px={3}>
                                    <div>
                                        <Button color='error' sx={{ textTransform: 'capitalize' }} onClick={() => { handleCloseShow2() }}>
                                            Cancel
                                        </Button>
                                        <Button sx={{ textTransform: 'capitalize', color: '#1B5775' }} onClick={() => setShow(1)}>Back</Button>

                                    </div>
                                    <div>
                                        <Button type="submit" variant="contained" sx={{ width: "150px", textTransform: 'capitalize', bgcolor: '#1B5775', "&:hover": { bgcolor: '#1B5775' }, height: "33px", borderRadius: '7px', mr: 2 }} onClick={handleCreateProfile} disabled={createProfileDisabled}
                                        >Create Profile
                                        </Button>

                                        {profileData.length > 0 &&
                                            <Button type="submit" variant="contained" sx={{ width: "150px", textTransform: 'capitalize', bgcolor: '#1B5775', "&:hover": { bgcolor: '#1B5775' }, height: "33px", borderRadius: '7px' }} onClick={handleLinkPatient} disabled={linkDisabled}
                                            >Link
                                            </Button>
                                        }
                                    </div>
                                </Grid>
                            </DialogActions>
                        </> :
                            show === 4 ?
                                <>
                                    <>
                                        <DialogContent sx={{ p: '10px 40px 20px' }}>

                                            <Box >
                                                <Grid container spacing={3}>

                                                    <Grid item xs={12} md={6}
                                                        sx={{
                                                            ".MuiStack-root": {
                                                                paddingTop: '0px'
                                                            },
                                                        }}>
                                                        <Box>
                                                            <label style={{ fontWeight: 500, fontSize: '14px' }}>Phone Number</label>
                                                        </Box>
                                                        <CustomTextField
                                                            placeholder="Phone Number"
                                                            type="text"
                                                            borderColor="#59D8FA"
                                                            value={phoneNumber}
                                                            // disabled={userRes !== 0}
                                                            padding={"0px"}
                                                            // onChange={(e) => handleChangePhone(e, setFieldTouched, setFieldValue, setFieldError)}
                                                            // onBlur={handleBlur('phone')}
                                                            borderRadius="7px"
                                                        // icon={
                                                        //     captchaToken && userRes === 0 ? (
                                                        //         <ArrowForward
                                                        //             sx={{
                                                        //                 backgroundColor: "#1B5775",
                                                        //                 fontSize: '40px',
                                                        //                 color: "white",
                                                        //                 borderRadius: "0px 7px 7px 0px",
                                                        //             }}
                                                        //             onClick={handleSendOtp}
                                                        //         />
                                                        //     ) : (
                                                        //         // <Verified />
                                                        //         null
                                                        //     )
                                                        // }
                                                        />

                                                        {!showOtp &&

                                                            < Box sx={{ mt: 4, transform: 'scale(0.8)', transformOrigin: '0 0' }}>

                                                                <ReCAPTCHA
                                                                    sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
                                                                    onChange={handleRecaptchaChange}
                                                                    size="normal"
                                                                // style={{ transform: 'scale(0.9)' }}
                                                                />
                                                            </Box>
                                                        }

                                                        {/* <div className='flex justify-end'>
                                                <OutlinedButton
                                                    width="160px"
                                                    height="32px"
                                                    fontSize="14px"
                                                    borderColor="#1B5775"
                                                    text="Request OTP"
                                                    color="#1B5775"
                                                    borderRadius="7px"
                                                    fontWeight={600}
                                                    onClick={handleSendOtp} // onClick event handler

                                                    startIcon={null} />
                                            </div> */}

                                                    </Grid>

                                                    {showOtp &&
                                                        < Grid item xs={12} md={6}>

                                                            <Box >
                                                                <label style={{ fontWeight: 500, fontSize: '14px' }}>Verify OTP</label>
                                                            </Box>
                                                            <CustomTextField
                                                                placeholder="Enter OTP"
                                                                type={'text'}
                                                                borderColor="#59D8FA"
                                                                value={otp}
                                                                onChange={handleChangeOtp}
                                                                borderRadius="7px"
                                                            />

                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Box>

                                        </DialogContent>
                                        <Divider />
                                        <div className='text-center'><ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} /></div>
                                        <DialogActions sx={{ m: "5px 20px" }}>
                                            <Grid container justifyContent={"space-between"} px={3}>
                                                <Button color='error' sx={{ textTransform: 'capitalize' }} onClick={() => {
                                                    handleCloseShow2()

                                                }}>Cancel</Button>
                                                <Button type="submit" variant="contained" sx={{ width: "150px", textTransform: 'capitalize', bgcolor: '#1B5775', "&:hover": { bgcolor: '#1B5775' }, height: "33px", borderRadius: '7px' }} onClick={showOtp ? handleVerifyOtp : handleSendOtp}>{showOtp ? 'Link Profile' : 'Request OTP'}
                                                </Button>
                                            </Grid>
                                        </DialogActions>
                                    </>
                                </> : show === 5 ?
                                    <>
                                        <DialogContent sx={{ p: '10px 40px 20px' }}>

                                            <Box >
                                                <Grid container spacing={3}>

                                                    <Grid item xs={12} md={6}
                                                        sx={{
                                                            ".MuiStack-root": {
                                                                paddingTop: '0px'
                                                            },
                                                        }}>
                                                        <Box>
                                                            <label style={{ fontWeight: 500, fontSize: '14px' }}>Phone Number</label>
                                                        </Box>
                                                        <CustomTextField
                                                            placeholder="Phone Number"
                                                            type="text"
                                                            borderColor="#59D8FA"
                                                            value={phoneNumber}
                                                            // disabled={userRes !== 0}
                                                            padding={"0px"}
                                                            // onChange={(e) => handleChangePhone(e, setFieldTouched, setFieldValue, setFieldError)}
                                                            // onBlur={handleBlur('phone')}
                                                            borderRadius="7px"

                                                        />

                                                        {!showOtp &&

                                                            < Box sx={{ mt: 4, transform: 'scale(0.8)', transformOrigin: '0 0' }}>

                                                                <ReCAPTCHA
                                                                    sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
                                                                    onChange={handleRecaptchaChange}
                                                                    size="normal"
                                                                // style={{ transform: 'scale(0.9)' }}
                                                                />
                                                            </Box>
                                                        }



                                                    </Grid>

                                                    {showOtp &&
                                                        < Grid item xs={12} md={6}>

                                                            <Box >
                                                                <label style={{ fontWeight: 500, fontSize: '14px' }}>Verify OTP</label>
                                                            </Box>
                                                            <CustomTextField
                                                                placeholder="Enter OTP"
                                                                type={'text'}
                                                                borderColor="#59D8FA"
                                                                value={otp}
                                                                onChange={handleChangeOtp}
                                                                borderRadius="7px"
                                                            />

                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Box>

                                        </DialogContent>
                                        <Divider />
                                        <div className='text-center'><ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} /></div>
                                        <DialogActions sx={{ m: "5px 20px" }}>
                                            <Grid container justifyContent={"space-between"} px={3}>
                                                <Button color='error' sx={{ textTransform: 'capitalize' }} onClick={() => {
                                                    handleCloseShow2()

                                                }}>Cancel</Button>
                                                <Button type="submit" variant="contained" sx={{ width: "150px", textTransform: 'capitalize', bgcolor: '#1B5775', "&:hover": { bgcolor: '#1B5775' }, height: "33px", borderRadius: '7px' }} onClick={showOtp ? handleVerifyCreatePatient : handleSendOtp}>{showOtp ? 'Link Profile' : 'Request OTP'}
                                                </Button>
                                            </Grid>
                                        </DialogActions>
                                    </>
                                    :
                                    // show === 6 ? <>
                                    //     <DialogContent sx={{ p: '10px 40px 20px' }}>

                                    //         <div className='text-center mt-8 h-32 font-medium'>
                                    //             Please Verify the below Profile which already exist in the System and You can link then is those are yours
                                    //             <span className='cursor-pointer'> (Yes)</span> / <span className='cursor-pointer'>(No)</span>

                                    //         </div>

                                    //     </DialogContent>
                                    //     <Divider />
                                    //     <div className='text-center'><ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} /></div>
                                    //     <DialogActions sx={{ m: "5px 20px" }}>
                                    //         <Grid container justifyContent={"space-between"} px={3}>
                                    //             {/* <Button color='error' sx={{ textTransform: 'capitalize' }} onClick={() => {
                                    //                 handleCloseShow2()

                                    //             }}>Cancel</Button> */}
                                    //             <Button type="submit" variant="contained" sx={{ width: "150px", textTransform: 'capitalize', bgcolor: '#1B5775', "&:hover": { bgcolor: '#1B5775' }, height: "33px", borderRadius: '7px' }} onClick={showOtp ? handleVerifyCreatePatient : handleSendOtp}>{showOtp ? 'Link Profile' : 'Request OTP'}
                                    //             </Button>
                                    //         </Grid>
                                    //     </DialogActions>


                                    // </> :
                                    null
            }


        </CommonDialog >
    )
}

export default AddProfile