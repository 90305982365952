import { Close } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from '@mui/material'
import React from 'react'
import CommonDialog from '../atoms/commonModal'
import ContainedButton from '../atoms/commonbutton'

function DoctorUnfollow({ open, handleClose, handleUnFollowDoc }) {

    return (
        <CommonDialog open={open} >
            <DialogContent>
                <Typography sx={{ fontSize: 18 }}>
                    Do you want to unfollow this Profile?
                </Typography>
                <Divider />
                <div className='flex justify-end mt-2'>
                    <div className='flex justify-between gap-2'>
                        <ContainedButton text="Cancel" borderRadius="10px" bgcolor="red" fontSize="14px" onClick={handleClose} />
                        <ContainedButton text="UnFollow" borderRadius="10px" fontSize="14px" onClick={handleUnFollowDoc} />
                    </div>
                </div>
            </DialogContent>

        </CommonDialog>
    )
}

export default DoctorUnfollow