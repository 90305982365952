import React from 'react'
import Home from '../components/home/home';
import Footer from '../components/home/footer';
import BranchHome from '../components/branchhome/branchHome';
import { Route, Routes, useLocation } from 'react-router-dom';
import DoctorPage from '../components/doctors/page';
import SecondHeader from '../components/header/secondheader';
import { useDispatch, useSelector } from 'react-redux';
import { branchInfo } from '../redux/slices/branchSlice';
import HealthInformationPage from '../components/healthinformation/HealthInformationPage';
import DoctorProfile from '../components/doctors/doctorprofile';
import NoPage from '../components/NoPage';
import AboutUs from '../components/aboutus/AboutUs';
import HealthPortal from '../components/HealthPortal/HealthPortal';
import HealthProfile from '../components/HealthPortal/healthprofile';
import Consultations from '../components/HealthPortal/consultations';
import MedicalRecords from '../components/HealthPortal/medicalrecords';
import Medication from '../components/HealthPortal/medication';
import DiagnosticReports from '../components/HealthPortal/diagnosticreports';
import PaybillOnline from '../components/HealthPortal/paybillonline';
import Condition from '../components/condition/Condition';
import ConditionDetails from '../components/condition/ConditionDetails';
import Healthcaredepart from '../components/healthcare_departs/healthcaredepart';
import HealthcareService from '../components/healthcare_services/healthcareservice';
import HealthcareServicePage from '../components/healthcare_services/page';
import SpecialitiesPage from '../components/healthcare_departs/page';
import PersonDetails from '../components/HealthPortal/PersonDetails';

function PrivateRoutesPage() {
  const [selectedLocationName, setSelectedLocationName] = React.useState(null);
  const dispatch = useDispatch()
  const { pathname } = useLocation();
  const branchName = useSelector((state) => state.branch.branch);
  const selectBranchInfo = useSelector((state) => state.SelectedBranch.selectedbranch);

  React.useEffect(() => {
    let locationInfo = localStorage.getItem("brName");
    if (locationInfo) {
      dispatch(branchInfo(locationInfo))
    }
    setSelectedLocationName(locationInfo);
  }, [selectedLocationName]);

  React.useEffect(() => {
    setSelectedLocationName(branchName);
  }, [branchName])


  return (
    <div>
      {(pathname !== "/" || branchName) && <SecondHeader />}
      <Routes>
        <Route path="/" element={branchName ? <BranchHome /> : <Home />} />
        <Route path={`/${selectBranchInfo?.name ? selectBranchInfo?.name : ''}`}>
          <Route path="doctors" element={<DoctorPage />} />
          <Route path="doctor/:id" replace element={<DoctorProfile />} />
          {/* <Route path="specialities" element={<SpecialitiesPage />} /> */}
          <Route path="healthcaredept" element={<SpecialitiesPage />} />
          <Route path="healthcaredept/:name/:id" element={<Healthcaredepart />} />

          <Route path="healthcareservices" element={<HealthcareServicePage />} />
          <Route path="healthcareservices/:name/:id" element={<HealthcareService />} />

          <Route path="healthinformation" element={<HealthInformationPage />} />
          <Route path="conditions" element={<Condition />} />
          <Route path="condition/:id/:name" element={<ConditionDetails />} />
          <Route path="/healthportal" element={<HealthPortal />} />
          < Route path="patient" element={<PersonDetails />} >
            <Route index element={<HealthProfile />} />
            <Route path="healthprofile" element={<HealthProfile />} />
            <Route path="consultations" element={<Consultations />} />
            <Route path="medicalrecords" element={<MedicalRecords />} />
            <Route path="medication" element={<Medication />} />
            <Route path="diagnostic" element={<DiagnosticReports />} />
            <Route path="paybill" element={<PaybillOnline />} />
          </Route>
          <Route path="privacy" element={<HealthInformationPage />} />
          <Route path="AboutUs" element={<AboutUs />} />
        </Route>
        <Route path="*" element={<NoPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default PrivateRoutesPage;
