import React, { useEffect, useRef, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { BsChatRightTextFill, BsEmojiSmile } from "react-icons/bs";
import { Box, Button, IconButton, InputAdornment, TextField } from "@mui/material";
import "./chat.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import axios from "axios";
// import { ActiveChat } from "./ActiveChat";
import { ActiveChat } from "./activeChat";
import { localstore } from "../localstore/localstore";
import { CustomTextField } from "../atoms/TextField";
import { createInteractionData } from "../atoms/getLocations";
import { closeWebSocket, initializeWebSocket } from "../doctors/webSocketManager";

const SendMessages = ({ sendJsonMessage, name, phone, ptId, messageHistory, handleChangeName, handleChangePhone, handleSubmit, typing }) => {
    return (
        <Box>
            {
                ptId ?
                    <div style={{ display: 'flex', flexDirection: 'column', }
                    } >
                        <ActiveChat sendJsonMessage={sendJsonMessage} messageHistory={messageHistory} typing={typing} />
                    </div >
                    :
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '50px', gap: '35px', justifyContent: 'center', padding: '10px 20px' }} className="pt-2">
                        {/* <input
                    name="name"
                    placeholder="Name"
                    onChange={handleChangeName}
                    value={name}
                    className="shadow-sm sm:text-sm border-gray-300 bg-gray-100 rounded-md px-3 py-1"
                /> */}
                        <CustomTextField type="text" name="name" placeholder="Name" value={name}
                            backgroundColor="rgb(243, 244, 246)" borderRadius="7px" onChange={handleChangeName}
                        />
                        {/* <CustomTextField type="text" name="phone" placeholder="Enter your Phone Number" value={phone}
                    backgroundColor="rgb(243, 244, 246)" borderRadius="7px" onChange={handleChangePhone}
                /> */}
                        <TextField
                            fullWidth
                            size="small"
                            type={'text'}
                            value={phone}
                            onChange={handleChangePhone}
                            placeholder="Enter Phone Number"
                            sx={{
                                width: '100%', mt: "5px",
                                ".MuiInputBase-root": {
                                    borderRadius: '7px',
                                    backgroundColor: "rgb(243, 244, 246)"
                                },
                                '& .MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#59D8FA',
                                    },
                                },
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        +91
                                    </InputAdornment>
                                )
                            }}


                        />

                        {/* <input
                    name="phone"
                    placeholder="Enter Your Phone"
                    onChange={handleChangePhone}
                    type="number"
                    value={phone}
                    className="shadow-sm sm:text-sm border-gray-300 bg-gray-100 rounded-md px-3 py-1"
                /> */}
                        {/* <textarea
                    name="message"
                    placeholder=" Write your first Message"
                    onChange={handleChangeMessage}
                    value={message}
                    rows={4}
                    className="shadow-sm sm:text-sm border-gray-300 bg-gray-100 rounded-md px-3 py-1"
                    style={{ color: 'black' }}
                /> */}

                        {/* <TextField
                    name="message"
                    placeholder="Write your first message"
                    onChange={handleChangeMessage}
                    value={message}
                    multiline
                    rows={4}
                    variant="outlined" // or "filled", "standard" based on your preference
                    fullWidth
                    InputProps={{
                        style: {
                            backgroundColor: '#f3f4f6', // Similar to bg-gray-100
                        },
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderColor: '#d1d5db', // Similar to border-gray-300
                            borderRadius: '0.375rem', // Similar to rounded-md
                            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)', // Similar to shadow-sm
                        },
                        '& .MuiOutlinedInput-root:hover': {
                            borderColor: '#d1d5db', // Maintain the same border color on hover
                        },
                        '& .MuiOutlinedInput-root.Mui-focused': {
                            borderColor: '#d1d5db', // Maintain the same border color when focused
                        },
                        '& .MuiInputBase-input': {
                            padding: '8px 12px', // Similar to px-3 py-1
                        },
                    }}
                /> */}
                        <Button variant="outlined" sx={{ borderRadius: '7px', borderColor: '#E44190', mt: 2, color: '#E44190', textTransform: 'capitalize', '&:hover': { borderColor: '#E44190', color: '#E44190' } }}
                            className=" bg-gray-200 px-3 py-2 text-[#E44190]"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                        {/* <hr /> */}
                        {/* <ul>
                    {messageHistory.map((message, idx) => (
                        <div className="border border-gray-200 py-3 px-3" key={idx}>
                            {message.senderName}: {message.content}
                        </div>
                    ))}
                </ul> */}


                    </div>
            }
        </Box>
    )
}

export default function Chat({ messages }) {
    const [welcomeMessage, setWelcomeMessage] = useState("");
    const [messageHistory, setMessageHistory] = useState([]);
    const [show, setShow] = useState(1)
    // console.log(messageHistory, "hello")
    // console.log(messages, "contentmessages")
    const [message, setMessage] = useState("");
    const [name, setName] = useState(null);
    const [phone, setPhone] = useState("");
    // const [visitorId, setVisitorId] = useState("");
    const [typing, setTyping] = useState(false);

    const [chatData, setChatData] = useState('')
    const [participantId, setParticipantId] = useState("");
    const [socketUrl, setSocketUrl] = useState("");
    const [userListOpen, setUserListopen] = useState(false);
    const [userDetails, setUserDetails] = useState({});
    const [latLong, setLatLong] = useState(null);
    const [reply, setReply] = useState(false)
    const usertoken = localstore.getToken()
    let ptId = localStorage.getItem("participantId");
    let visitorId = localStorage.getItem("visitorId");
    const roomId = localStorage?.getItem('roomId')
    const timeoutRef = useRef(null);

    const pageTitle = "Paramitha Hospitals"
    const activity = ""
    const pageId = ""
    useEffect(() => {
        sendJsonMessage({
            "roomid": roomId,
            "type": "message.seen",
        })
    }, [])

    const { getWebSocket, readyState, sendJsonMessage } = useWebSocket(socketUrl, {
        onOpen: () => {
            // console.log("Connected!");
        },
        onClose: () => {
            // console.log("Disconnected!");
        },
        onMessage: (e) => {
            const data = JSON.parse(e.data);
            // console.log(e, "eeeeeeeeee")
            switch (data.type) {
                case "welcome_message":
                    setWelcomeMessage(data.message);
                    break;
                case "chat.message":
                    sendJsonMessage({
                        "roomid": roomId,
                        "type": "message.seen",
                        "user": "patient"
                    })
                    setMessageHistory((prev) => [data, ...prev]);
                    break;
                case "message.typing":
                    console.log("typpinggggg", data)

                    if (data?.user === "admin") {

                        setTyping(true)
                    }
                    if (timeoutRef.current) {
                        clearTimeout(timeoutRef.current);
                    }

                    // Set a timeout to set typing to false after 3 seconds
                    timeoutRef.current = setTimeout(() => {
                        setTyping(false);
                    }, 3000);


                    // console.log("typpinggggg")
                    // setTyping(true)
                    // if (timeoutRef.current) {
                    //     clearTimeout(timeoutRef.current);
                    // }

                    // // Set a timeout to set typing to false after 3 seconds
                    // timeoutRef.current = setTimeout(() => {
                    //     setTyping(false);
                    // }, 3000);

                    break;
                default:
                    // console.log("Unknown message type!");
                    break;
            }
        },
        shouldReconnect: (closeEvent) => true,
    });


    // console.log(typing, "typing")

    // useEffect(() => {
    //     if (visitorId) {
    //         const setupWebSocket = async () => {
    //             // Create the initial interaction data
    //             const initialInteractionData = await createInteractionData(pageId, pageTitle, activity);

    //             // Define a message handler to process incoming messages
    //             const handleMessage = (message) => {
    //                 console.log("Message received:", message);
    //                 setMessages((prevMessages) => [...prevMessages, message] || []); // Append the message to the state
    //             };

    //             // Initialize WebSocket with the interaction data and message handler
    //             initializeWebSocket(initialInteractionData, handleMessage);
    //         };

    //         // Setup WebSocket when visitorId is available
    //         setupWebSocket();

    //         // Cleanup WebSocket when component unmounts
    //         return () => {
    //             closeWebSocket();
    //         };
    //     }
    // }, [visitorId, pageId, pageTitle, activity]);

    // Safely parse each message if it's JSON
    const connectionStatus = {
        [ReadyState.CONNECTING]: "Connecting",
        [ReadyState.OPEN]: "Open",
        [ReadyState.CLOSING]: "Closing",
        [ReadyState.CLOSED]: "Closed",
        [ReadyState.UNINSTANTIATED]: "Uninstantiated",
    }[readyState];

    useEffect(() => {
        return () => {
            const socket = getWebSocket();
            if (socket && socket.readyState === WebSocket.OPEN) {
                socket.close();
            }
        };
    }, [getWebSocket]);

    useEffect(() => {
        if (participantId) {
            axios.get(`${process.env.REACT_APP_CHAT_URL}GetParticipant/${participantId}`).then((res) => {
                if (res?.status === 204) {
                    setUserDetails({});
                } else {
                    setUserDetails(res?.data);
                }
            }).catch((err) => console.log(err));
        }
    }, [participantId]);

    useEffect(() => {
        if (localstore.getToken()) {
            axios.get(`${process.env.REACT_APP_CHAT_URL}GetParticipantIds?userId=${localstore.getUserId()}`, {
                headers: {
                    "Authorization": `Bearer ${usertoken}`
                }
            }).then((res) => {
                if (res?.data?.length === 0) {
                    const data = {
                        "name": localstore.getUsername(),
                        "phone": '',
                        "email": ""
                    };
                    axios.post(`createNewParticipant`, data).then((res) => {
                        setUserDetails(res?.data);
                        localStorage.setItem("participantId", res?.data?.ParticipantId);
                        setParticipantId(res?.data?.ParticipantId);
                        localStorage.setItem("roomId", res?.data?.roomid);
                    }).catch((err) => console.log(err));
                } else {
                    setUserDetails(res?.data?.[0]);
                    localStorage.setItem("participantId", res?.data?.[0]?.Participant?.participentId);
                    setParticipantId(res?.data?.[0]?.Participant?.participentId);
                    localStorage.setItem("roomId", res?.data?.[0]?.roomid);
                }
            }).catch((err) => console.log(err));
        }
    }, [localstore.getToken()]);


    const handleOnNearestLocation = async () => {
        // setLoading(true); // Start loading

        try {
            const position = await new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject);
            });

            const { latitude, longitude } = position.coords;
            const latLongString = `${latitude},${longitude}`;
            setLatLong(latLongString)
        } catch (error) {
            console.error('Error fetching geolocation data:', error);

        } finally {
            console.log(false); // End loading
        }
    }


    useEffect(() => {
        if (visitorId && ptId) {
            let surl = usertoken
                ? `wss://${process.env.REACT_APP_WEBSOCKET_URL}socket/group?visitorId=${visitorId}&participantId=${ptId}&patient=${usertoken}`
                : `wss://${process.env.REACT_APP_WEBSOCKET_URL}socket/group?participantId=${ptId}&visitorId=${visitorId}${latLong ? `&latLong=${latLong}` : ''}`;
            setSocketUrl(surl);
        }
    }, [visitorId, ptId, latLong]);


    useEffect(() => {
        if (socketUrl) {
            getWebSocket();
        }
    }, [socketUrl]);

    function handleChangeMessage(e) {
        setMessage(e.target.value);
    }

    function handleChangeName(e) {
        setName(e.target.value);
    }

    function handleChangePhone(e) {
        setPhone(e.target.value?.replace(/\D/g, "")?.slice(0, 10));
    }

    console.log(latLong, "latlong")
    useEffect(() => {
        if (roomId) {
            fetchMessages();
        }
        handleOnNearestLocation()
    }, [roomId]);

    const updateUserInfo = async () => {
        const data = {
            "name": name,
            "phone": phone,
            "email": ""
        };
        try {
            const res = await axios.post(`${process.env.REACT_APP_CHAT_URL}createNewParticipant`, data);
            setUserDetails(res?.data);
            localStorage.setItem("participantId", res?.data?.Participant?.participentId);
            localStorage.setItem("roomId", res?.data?.roomid);
            return res?.data;
        } catch (err) {
            console.log(err);
        }
    };

    const fetchMessages = () => {
        axios.get(`${process.env.REACT_APP_CHAT_URL}getRoomConversations/${roomId}`, {

        }).then((res) => {
            setMessageHistory(res?.data);
        }).catch((err) => console.log(err));
    };

    const handleSubmit = async () => {
        if (!name.trim()) {
            alert("Please enter your name.");
            return;
        }

        if (!phone.trim() || !/^\d{10}$/.test(phone)) {
            alert("Please enter a valid 10-digit phone number.");
            return;
        }

        const userInfo = await updateUserInfo();
        if (userInfo) {
            if (visitorId && ptId) {
                const surl = localstore.getToken()
                    ? `wss://${process.env.REACT_APP_WEBSOCKET_URL}socket/group?visitorId=${visitorId}&participantId=${ptId}&patient=${usertoken}`
                    : `wss://${process.env.REACT_APP_WEBSOCKET_URL}socket/group?participantId=${ptId}&visitorId=${visitorId}${latLong ? `&latLong=${latLong}` : ''}`;
                setSocketUrl(surl);
            }

            // sendJsonMessage({
            //     "roomid": localStorage.getItem('roomId'),
            //     // "content": message,
            //     "attachment": "",
            //     "urls": "",
            //     "subject": "help"    
            // });

            setName("");
            setMessage("");
            setPhone("");
        }
    };
    console.log(userDetails, "userDetails")
    return (
        <>
            {!userListOpen ? (
                <div
                    // className="message-box"
                    className="mt-1"
                // style={{ padding: "5px 20px" }}
                >
                    <IconButton
                        sx={{
                            backgroundColor: '#E44190',
                            // color: '#FFFFFF',
                            width: 56,
                            height: 56,
                            '&:hover': {
                                backgroundColor: '#E44190',
                            },
                            // mt: 1
                        }}
                        onClick={() => setUserListopen(!userListOpen)}
                    >
                        <BsChatRightTextFill style={{ color: '#FFFFFF' }} className="text-2xl" />
                    </IconButton>
                    {/* <Button
                        variant="contained"
                        size="large"
                        // sx={{ textTransform: "capitalize", fontSize: "16px", backgroundColor: '#E44190' }}
                        onClick={() => setUserListopen(!userListOpen)}
                    > */}
                    {/* </Button> */}
                </div>
            ) : (
                <div
                    className="drop-shadow-lg message-box mt-3"
                    style={{
                        backgroundColor: userDetails?.Participant?.participentId ? '' : "#fdf3f7",
                        width: "320px",
                        height: `${window.innerHeight - 100}px`,
                        borderTopLeftRadius: '20px', borderTopRightRadius: '20px',

                    }}
                >
                    <nav className="flex justify-between" style={{ backgroundColor: '#E44190', color: '#FFFFFF', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', padding: '10px 20px' }}>
                        <div >
                            <div className="text-xl">Paramitha Hospitals</div>
                            {typing && <p className="text-white-500 text-xs">Typing...</p>}


                            {/* <div style={{ fontSize: '10px' }}>The WebSocket is currently {connectionStatus}</div> */}
                        </div>
                        <IconButton onClick={() => {
                            setUserListopen(!userListOpen)
                            setShow(1)
                        }} >
                            <AiOutlineCloseCircle className="text-[#FFFFFF] text-xl" />
                        </IconButton>
                    </nav>
                    {/* {userDetails?.Participant?.participentId ? */}


                    {/* {
                        show === 1 ? (
                            <div style={{ display: 'flex', flexDirection: 'column', }
                            } >
                                <div className="flex-col" style={{ background: "#fdf3f7" }}>
                                    <div
                                        id="scrollableDiv"
                                        style={{ backgroundSize: "cover" }}
                                        className="flex flex-col-reverse relative w-full px-2 overflow-y-auto"
                                    ></div>
                                    {messages.length > 0 ?
                                        <ol type="1">
                                            {messages.map((msg, index) => {

                                                // try {
                                                const parsedMessage = JSON.parse(msg);
                                                console.log(parsedMessage, "parsedmessge")
                                                //     console.log(parsedMessage, `Parsed message ${index}`);
                                                // } catch (error) {
                                                //     console.error(`Error parsing message ${index}:`, error);
                                                // }
                                                return (
                                                    <div style={{ marginTop: '10px' }} >
                                                        <div>
                                                            {parsedMessage?.content}
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                        </ol> : <SendMessages name={name} phone={phone} handleChangeName={handleChangeName} handleChangePhone={handleChangePhone} sendJsonMessage={sendJsonMessage} messageHistory={messageHistory} handleSubmit={handleSubmit} ptId={ptId} />

                                    }
                                    {messages.length > 0 &&
                                        <div className="flex items-center p-2 border-t border-gray-200 bg-white">
                                            <span className="p-2">
                                                <BsEmojiSmile />
                                            </span>
                                            <textarea
                                                // style={{ overflow: 'hidden' }}
                                                // value={message}
                                                // onChange={handleChangeMessage}
                                                // ref={inputReference}
                                                className="w-full border-none p-2 rounded-md resize-none"
                                                rows={1}
                                                placeholder="Type a message"
                                            // onKeyDown={(e) => {
                                            //     if (e.key === "Enter") {
                                            //         handleSubmit()
                                            //     }
                                            // }} 
                                            />
                                            <Button className="text-blue-500 capitalize" style={{ textTransform: 'capitalize' }}>
                                                reply
                                            </Button>
                                        </div>
                                    }
                                </div>
                            </div >
                        ) : show === 2 ? (
                            <> <SendMessages name={name} phone={phone} handleChangeName={handleChangeName} handleChangePhone={handleChangePhone} sendJsonMessage={sendJsonMessage} messageHistory={messageHistory} handleSubmit={handleSubmit} ptId={ptId} /></>
                        ) : null
                    } */}
                    {show === 1 ? (
                        // Chat view with messages
                        <div>

                            {messages.length > 0 ? (
                                <div className="flex-grow overflow-y-auto flex flex-col-reverse p-2" style={{ height: `${window.innerHeight - 225}px` }}>
                                    <ol type="1">
                                        {messages.map((msg, index) => {
                                            const parsedMessage = JSON.parse(msg);
                                            return (
                                                <div key={index} style={{ marginTop: '10px' }}>
                                                    <div>{parsedMessage?.content}</div>
                                                </div>
                                            );
                                        })}
                                    </ol>
                                </div>
                            ) : (
                                <div>
                                    <SendMessages
                                        name={name}
                                        phone={phone}
                                        handleChangeName={handleChangeName}
                                        handleChangePhone={handleChangePhone}
                                        sendJsonMessage={sendJsonMessage}
                                        messageHistory={messageHistory}
                                        handleSubmit={handleSubmit}
                                        ptId={ptId}
                                        typing={typing}
                                    />
                                </div>
                            )}

                            {/* Textarea fixed at the bottom */}
                            {messages.length > 0 &&

                                <div className="flex items-center p-2 border-t border-gray-200 bg-white sticky bottom-0">
                                    <span className="p-2">
                                        <BsEmojiSmile />
                                    </span>
                                    <textarea
                                        className="w-full border-none p-2 rounded-md resize-none"
                                        rows={1}
                                        placeholder="Type a message"
                                    />
                                    <Button className="text-blue-500 capitalize" style={{ textTransform: 'capitalize' }} onClick={() => setShow(2)}>
                                        Reply
                                    </Button>
                                </div>
                            }
                        </div>
                    ) : show === 2 ? (
                        // Alternative view (for example, a "Send Messages" component or a placeholder)
                        <SendMessages
                            name={name}
                            phone={phone}
                            handleChangeName={handleChangeName}
                            handleChangePhone={handleChangePhone}
                            sendJsonMessage={sendJsonMessage}
                            messageHistory={messageHistory}
                            handleSubmit={handleSubmit}
                            ptId={ptId}
                            typing={typing}

                        />
                    ) : null}


                    {/* <ol type="1">
                        {messages.length > 0 ? messages.map((msg, index) => {

                            // try {
                            const parsedMessage = JSON.parse(msg);
                            console.log(parsedMessage, "parsedmessge")
                            //     console.log(parsedMessage, `Parsed message ${index}`);
                            // } catch (error) {
                            //     console.error(`Error parsing message ${index}:`, error);
                            // }
                            return (
                                <div style={{ marginTop: '10px' }} >
                                    {parsedMessage?.content}
                                    <div onClick={() => setReply(true)}>reply?</div>
                                </div>
                            )
                        }) : <SendMessages />
                        }

                    </ol>
                    {reply && <SendMessages />} */}
                </div >
            )
            }
        </>
    );
}
