// import React, { useState } from "react";
// import { Box, CardMedia, Typography } from "@mui/material";

// function DoctorImage({ docFeed }) {
//     const image = docFeed.sourceLink.replace(/,\s*$/, "");
//     const [expanded, setExpanded] = useState(false);
//     const handleExpandClick = () => {
//         setExpanded(!expanded);
//     };
//     return (
//         <Box>
//             <Typography sx={{ p: "0px 10px", fontSize: 14, fontWeight: 500, height: 50 }}>

//                 {docFeed?.description ? (
//                     <>
//                         {expanded
//                             ? docFeed.description
//                             : `${docFeed.description.slice(0, 75)}`}
//                         {docFeed.description.length > 80 ? (
//                             <Typography
//                                 component={"span"}
//                                 onClick={handleExpandClick}
//                                 sx={{ cursor: "pointer", fontSize: 14, ml: 1 }}
//                             >
//                                 {expanded ? "...see less" : "...see more"}{" "}
//                             </Typography>
//                         ) : (
//                             ""
//                         )}
//                     </>
//                 ) : (
//                     <Box sx={{ height: { sm: 63, md: 42 } }}></Box>
//                 )}
//             </Typography>
//             <div className="mt-0.5">
//                 {docFeed.sourceLink ? (
//                     <Box sx={{ width: "100%" }}>
//                         <CardMedia
//                             className="object-fill"
//                             component="img"
//                             // height="194"
//                             sx={{
//                                 maxHeight: { xs: "200px", sm: "200px", md: "220px" },
//                                 //   border: "1px solid red",
//                             }}
//                             src={image}
//                             alt="Paella dish"
//                         />
//                     </Box>
//                 ) : null}
//             </div>
//         </Box>
//     );
// }

// export default DoctorImage;
import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { ReactPhotoCollage } from "react-photo-collage";

function DoctorImage({ docFeed }) {
    const [expanded, setExpanded] = useState(false);

    // Convert sourceLink to an array of images
    const images = docFeed.sourceLink
        ? docFeed?.sourceLink.split(',').slice(0, -1).map((image) => image.trim())
        : [];

    // Settings for the photo collage (used when there are multiple images)
    const setting = {
        width: "100%",
        height: ["100px", "100px"],
        layout: [1, 3],  // Adjust layout as per number of images
        photos: images.map((img) => ({ source: img })),  // Map over your images array
        showNumOfRemainingPhotos: true,
    };

    // Function to handle description expand/collapse
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Box sx={{ p: "0px 5px" }}>
            {/* Description with expand/collapse logic */}
            <Typography sx={{ fontSize: 13, p: "0px 5px", height: 62 }}>
                {docFeed?.description ? (
                    <>
                        {expanded
                            ? docFeed.description
                            : `${docFeed.description.slice(0, 75)}`}
                        {docFeed.description.length > 80 ? (
                            <Typography
                                component={"span"}
                                onClick={handleExpandClick}
                                sx={{ cursor: "pointer", fontSize: 14, ml: 1 }}
                            >
                                {expanded ? "...see less" : "...see more"}
                            </Typography>
                        ) : (
                            ""
                        )}
                    </>
                ) : (
                    <Box sx={{ height: { sm: 63, md: 62, lg: 62 } }}></Box>
                )}
            </Typography>

            {/* Space below the description */}
            <Box sx={{ height: "13px" }}></Box>

            {/* Display either single image or collage based on number of images */}
            <div>
                {images.length === 1 ? (
                    <img
                        src={images[0]}
                        alt="Single"
                        style={{ width: "100%", height: "235px", objectFit: "cover" }} // Full height image styling
                    />
                ) : (
                    <ReactPhotoCollage {...setting} />
                )}
            </div>
        </Box>
    );
}

export default DoctorImage;
