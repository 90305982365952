import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Autocomplete, Paper, TextField, IconButton, Checkbox, Button, CardMedia, InputAdornment } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import doctorPinkicon from '../../assets/home-images/doctor-PINK.svg'
import stethPinkIcon from '../../assets/home-images/Medical speicality-Pink.svg'
import { DoctorCard } from "./DoctorCard";
import healthserviceicon from '../../assets/healthcareprograms/Healthcare Service 1.svg';
import healthdeparticon from '../../assets/healthcareprograms/Health Department 2.svg';
import healthdepartPinkIcon from '../../assets/home-images/HealthDepartment-Pink.svg'
import BranchUrl from "../../services/api_branch_url";
import { FiSearch } from "react-icons/fi";
import { API_V4 } from "../../services/ApiService";


const FindDoctorPage = () => {
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState([]);
    const [visibleCount, setVisibleCount] = useState(4);
    const [searchSpeciality, setSearchSpeciality] = useState('')
    const [ploading, setPLoading] = useState(false)
    const [svLoading, setSvLoading] = useState(false)
    const [deptLoading, setDeptLoading] = useState(false)
    const [serviceValue, setServiceValue] = React.useState(null);
    const [inputServiceValue, setInputServiceValue] = React.useState("");
    const [specialistInfo, setSpecialistInfo] = React.useState([]);
    const [departmentInfo, setDepartmentInfo] = React.useState([]);
    const [practitionerRole, setPractitionerRole] = useState([])
    const selectedLocationName = useSelector((state) => state.branch.branch);
    const branchName = useSelector((state) => state.branch.branch);
    const selectBranchInfo = useSelector((state) => state.SelectedBranch.selectedbranch);
    const navigate = useNavigate()
    const urls = BranchUrl()

    const handleSearchSpeciality = (e) => {
        setSearchSpeciality(e.target.value)
    }

    const filteredSpeciality = specialistInfo?.filter(specialty =>
        specialty?.resource?.name.toLowerCase().includes(searchSpeciality?.toLowerCase())
    );

    useEffect(() => {
        // axios.get(`${urls.fhir_auth}HealthcareService?_count=200&organization=${selectBranchInfo?.id}`, {
        API_V4.get(`${urls.orgId}HealthcareService?_count=200`).then(res => {
            // let sortList = res.data?.entry ? res.data?.entry.sort((a, b) => a?.resource?.identifier?.filter((e) => e.system == "https://positionofservices/")?.[0]?.value - b?.resource?.identifier?.filter((e) => e.system == "https://positionofservices/")?.[0]?.value) : []
            // console.log(sortList, "sorttt")
            let sortList = res.data.entry.sort((a, b) =>
                (a.resource.identifier?.find(id => id.system === 'https://positionofservices/')?.value || '999')
                    .localeCompare(b.resource.identifier?.find(id => id.system === 'https://positionofservices/')?.value || '999')
            );
            setSpecialistInfo(sortList || [])
        }).catch((err) => console.log(err))
    }, [selectBranchInfo])



    useEffect(() => {
        setPLoading(true);
        API_V4.get(`${urls.orgId}PractitionerRole?role=doctor`).
            then((res) => {
                setPractitionerRole(res?.data?.entry || []);
            }).catch((err) => console.log(err)).finally(() => {
                setPLoading(false);
            });
    }, [selectBranchInfo]);

    useEffect(() => {
        API_V4.get(`${urls.orgId}Organization?_count=200&type=dept`)
            .then((res) => {
                let sortList = res.data.entry.sort((a, b) =>
                    (a.resource.identifier?.find(id => id.system === 'https://positionofdepartments/')?.value || '999')
                        .localeCompare(b.resource.identifier?.find(id => id.system === 'https://positionofdepartments/')?.value || '999')
                );
                setDepartmentInfo(sortList || [])
            }).catch((err) => console.log(err))
    }, [selectBranchInfo])

    const handleSearchDoctor = () => {

    }
    // const loadPractitionerData = async (currentCount) => {
    //     try {
    //         const response = await axios.get(`${urls.fhir_auth}Practitioner`, {
    //             headers: {
    //                 'X-FHIR-TENANT-ID': 'parimitha',
    //                 'Realm': 'parimitha',
    //                 'X-FHIR-DSID': `fhir${selectBranchInfo?.tenant}`
    //             },
    //             params: {
    //                 _count: currentCount,
    //             }
    //         });
    //         setPractitionerData(response.data.entry || []);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    // useEffect(() => {
    //     loadPractitionerData(visibleCount); // Load initial data
    // }, [selectBranchInfo]);

    // const handleLoadMore = () => {
    //     const newCount = visibleCount + 6;
    //     setVisibleCount(newCount);
    //     loadPractitionerData(newCount);
    // };

    const handleSelectDept = (title, id) => {
        let name = title
        navigate(branchName ? `/${branchName}/healthcaredept/${name?.replace(/[\s,]+/g, '')}/${id}` : `/healthcaredept/${name?.replace(/[\s,]+/g, '')}/${id}`)
    }
    const handleSelectService = (title, id) => {
        let name = title
        navigate(branchName ? `/${branchName}/healthcareservices/${name?.replace(/[\s,]+/g, '')}/${id}` : `/healthcareservices/${name?.replace(/[\s,]+/g, '')}/${id}`)
    }
    const options = ["speaciality1,speaciality2,speaciality3"]
    return (

        <div className="pt-8">
            {/* Healthcare Departments */}
            <div className="mt-10">
                <div className="px-5 sm:px-20">
                    <div className='text-center pt-2 md:pt-10' >
                        <span className="text-lg font-medium md:text-3xl lg:text-4xl flex justify-center gap-2" style={{ color: '#E54290' }}>
                            <img src={stethPinkIcon} alt="steth" style={{
                                width: '34px',
                                height: 34,
                            }} />
                            <div> Find the Right Specialist Care</div>
                        </span>
                        {/* <br /> */}
                        <div style={{ color: '#000000' }} className="text-sm font-light md:text-xl mt-1">
                            Know About Clinical Specialities at {selectedLocationName || 'Paramitha Hospital'}
                        </div>
                    </div>
                    <Box sx={{ minHeight: { md: '400px', lg: '420px' }, mt: "16px" }}>
                        {specialistInfo && specialistInfo.length > 0 &&
                            <Grid container>

                                <Grid item xs={12} sm={7} md={6}>
                                    <TextField

                                        sx={{
                                            ".MuiOutlinedInput-root": {
                                                paddingRight: "0px",
                                            },
                                            ".MuiInputBase-root": {
                                                borderRadius: "25px",
                                                backgroundColor: '#ffffff',
                                            },
                                            ".MuiOutlinedInput-notchedOutline": {
                                                // height: "50px",
                                                border: "1px solid #E54290"
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '&.Mui-focused fieldset': {
                                                    // borderColor: props.borderColor,
                                                },
                                                paddingRight: '10px'
                                            },
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            width: "100%",
                                            // maxWidth: '500px',
                                        }}
                                        // label={props.label}
                                        placeholder="Write your Problem to find Right Speciality care"
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        value={searchSpeciality}
                                        onChange={handleSearchSpeciality}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <FiSearch style={{ width: 25, height: 25, color: '#E54290' }} />
                                                </InputAdornment>
                                            ),

                                        }}

                                    />
                                </Grid>
                            </Grid>
                        }
                        <div className="mt-3">
                            <Grid container columnSpacing={5} rowSpacing={4}>
                                {filteredSpeciality && filteredSpeciality?.length > 0 ?
                                    filteredSpeciality?.slice(0, 9).map((val, index) => {
                                        const imgSrc = val?.resource?.photo?.url
                                        return (
                                            <Grid item xs={12} sm={4} md={4} key={index} >
                                                <Box elevation={2} className='flex items-center cursor-pointer px-2 py-2'
                                                    sx={{
                                                        display: "flex",
                                                        cursor: "pointer",
                                                        opacity: 1,
                                                        alignItems: 'center',
                                                        p: "10px 20px",
                                                        gap: "10px",
                                                        // backgroundColor: '#FDF3F7',
                                                        height: '84px',
                                                        border: '1px solid #E44190',
                                                        borderRadius: '7px',
                                                        width: { xs: "100%", md: '100%' },
                                                        transition: 'box-shadow 0.3s ease, border 0.3s ease',
                                                        '&:hover': {
                                                            border: '4px solid #E44190',
                                                            backgroundColor: '#FDF3F7',
                                                            // boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)', // Increase the elevation effect
                                                        },
                                                        // boxShadow:
                                                        //     "1px 4px 8px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0)",
                                                    }}
                                                    onClick={() => handleSelectService(val?.resource?.name, val?.resource?.id)} >
                                                    <img src={imgSrc || healthserviceicon}
                                                        alt="" style={{ width: '54px', height: '54px', marginRight: '8px' }} />

                                                    <Box className="truncate" >
                                                        <Grid sx={{
                                                            color: "#525C79",
                                                            color: "#1D5775",
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                            textTransform: 'capitalize',
                                                            opacity: "100%",
                                                        }} >
                                                            {val?.resource?.name}
                                                        </Grid>
                                                        <Grid className="truncate text-sm font-light" sx={{ color: "#525C79" }}  >
                                                            {val?.resource?.comment}
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        );
                                    })
                                    :
                                    <Grid item xs={12}>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                            <Typography variant="h6" sx={{ color: "#525C79" }}>
                                                No Specialities available
                                            </Typography>
                                        </Box>
                                    </Grid>}
                            </Grid>
                        </div>

                    </Box>
                    {specialistInfo && specialistInfo?.length >= 9 &&
                        <Box sx={{ display: "flex", justifyContent: "center", marginTop: { xs: 3, sm: 3, md: 5, lg: "10px" }, }}>

                            <Button
                                variant="outlined"
                                onClick={() => navigate(branchName ? 'healthcareservices' : 'healthcareservices')}

                                sx={{
                                    borderColor: "#E54290",
                                    textTransform: "capitalize",
                                    color: "#E54290",
                                    borderRadius: "10px",
                                    width: { xs: '200px', md: '255px' },
                                    height: { xs: '44px', md: "54px" },
                                    '&:hover': { backgroundColor: '#E54290', borderColor: '#E54290', color: 'white' },
                                }}
                                endIcon={<ArrowForward />}
                            >
                                <Typography sx={{ fontSize: { xs: '14px', md: "18px", fontWeight: 500 } }}>Find All Specialities</Typography>
                            </Button>
                        </Box>
                    }
                </div>

            </div>


            <div className="pt-10 pb-8" >
                <div className="px-5 sm:px-20 ">
                    <div className='font-medium text-lg md:text-3xl lg:text-4xl text-center flex justify-center items-center gap-2' style={{ color: '#E54290' }}>
                        <img src={doctorPinkicon} alt="doctor" style={{
                            width: '34px',
                            height: 34,
                        }} />
                        Find the right Doctor for you

                    </div>
                    <div className="text-center text-sm md:text-xl mt-1 font-light" style={{ color: '#000000', lineHeight: '31px' }}>
                        Your Favourite Doctors at {selectedLocationName || 'Paramitha Hospital'}
                    </div>
                    {/* <Grid container spacing={1} sx={{ display: "flex", justifyContent: "center", }} >
                        <Grid item xs={12} sm={12} md={12}>
                            <FilterListIcon style={{ color: '#fff' }} />
                            <Typography
                                variant="body"
                                className="text-white "
                            >
                                Filter & Search
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Grid container Spacing={1}>
                                <Grid item xs={12} sm={6} md={3.3} sx={{ p: "10px" }} >
                                    <Box>
                                        <Autocomplete
                                            value={serviceValue}
                                            onChange={(event, newValue) => {
                                                setServiceValue(newValue);
                                            }}
                                            inputValue={inputServiceValue}
                                            onInputChange={(event, newInputValue) => {
                                                setInputServiceValue(newInputValue);
                                            }}
                                            id="controllable-states-demo"
                                            options={serviceOptions}
                                            sx={{
                                                width: "100%",
                                                background: '#fff',
                                                borderRadius: '4px',
                                                "& .MuiAutocomplete-inputRoot": { height: 33 },
                                                ".MuiOutlinedInput-root": { padding: "0px" },
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Service Category"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        startAdornment: (
                                                            <IconButton>
                                                                <FmdGoodIcon sx={{ fontSize: "15px" }} />
                                                            </IconButton>
                                                        ),
                                                    }}

                                                />
                                            )}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3.3} sx={{ p: "10px" }}>
                                    <Box>
                                        <Autocomplete
                                            value={value}
                                            onChange={(event, newValue) => {
                                                setValue(newValue);
                                            }}
                                            limitTags={2}
                                            inputValue={inputValue}
                                            onInputChange={(event, newInputValue) => {
                                                setInputValue(newInputValue);
                                            }}
                                            multiple
                                            id="controllable-states-demo"
                                            options={SpecialistTab}
                                            sx={{
                                                width: "100%",
                                                background: '#fff',
                                                borderRadius: '4px',
                                                "& .MuiAutocomplete-inputRoot": { height: 33 },
                                                ".MuiOutlinedInput-root": { padding: "0px" },
                                            }}
                                            getOptionLabel={(option) => option.title}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Select Speciality"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        startAdornment: (
                                                            <IconButton>
                                                                <img src={StethIcon} width={20} height={20} alt="stethoscope"
                                                                    sx={{ fontSize: "12px", mr: "5px" }}
                                                                />
                                                            </IconButton>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid> */}
                    <Box sx={{ bgcolor: '#FDF3F7', p: "20px 16px", mt: 2 }}>

                        <Grid container spacing={3} sx={{ p: '0px 25px' }}>
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    value={value}
                                    onChange={(event, newValue) => {
                                        setValue(newValue);
                                    }}
                                    inputValue={inputValue}
                                    onInputChange={(event, newInputValue) => {
                                        setInputValue(newInputValue);
                                    }}
                                    id="controllable-states-demo"
                                    options={specialistInfo}
                                    getOptionLabel={(option) => option?.resource?.specialty?.[0]?.coding?.[0]?.display}
                                    sx={{
                                        width: "100%",
                                        ".MuiAutocomplete-inputRoot": { borderRadius: '16px', backgroundColor: '#FDF3F7' },
                                        ".MuiOutlinedInput-root": {
                                            padding: "0px", borderColor: '#E54290'
                                        },
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Filter by Speciality"
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <IconButton>
                                                        <img src={stethPinkIcon} alt="speciality" />
                                                    </IconButton>
                                                ),
                                            }}
                                            sx={
                                                {
                                                    ".MuiOutlinedInput-notchedOutline": {
                                                        // height: "50px",
                                                        border: "1px solid #E54290"
                                                    },
                                                    ".MuiInputBase-root": {
                                                        backgroundColor: '#ffffff',
                                                    },
                                                }
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <TextField

                                    sx={{
                                        ".MuiOutlinedInput-root": {
                                            paddingRight: "0px",
                                        },
                                        ".MuiInputBase-root": {
                                            borderRadius: "25px",
                                            backgroundColor: '#ffffff',
                                        },
                                        ".MuiOutlinedInput-notchedOutline": {
                                            // height: "50px",
                                            border: "1px solid #E54290"
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                // borderColor: props.borderColor,
                                            },
                                            paddingRight: '10px'
                                        },
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: "100%",
                                        // maxWidth: '500px',
                                    }}
                                    onChange={handleSearchDoctor}
                                    // onChange={(e) => {
                                    //     if (e.target.value.length > 0) {
                                    //         const filteredDoctors = practitionerRole.filter((item) => {
                                    //             // Accessing the text property inside the name array, if it exists
                                    //             const doctorName = item?.resource?.name?.[0]?.text || '';
                                    //             // Using indexOf() to check if the doctorName contains the input value
                                    //             return doctorName.toLowerCase().includes(e.target.value.toLowerCase());
                                    //         });
                                    //         console.log(filteredDoctors)
                                    //         setPractitionerRole(filteredDoctors)
                                    //     } else {
                                    //         // loadPractitionerData(visibleCount);
                                    //     }
                                    // }}
                                    // label={props.label}
                                    placeholder="Search Doctor Name"
                                    size="small"
                                    fullWidth
                                    variant="outlined"

                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <FiSearch style={{ width: 25, height: 25, color: '#E54290' }} />

                                            </InputAdornment>
                                        ),
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img src={doctorPinkicon} alt="doctorpink" width={30} height={30} />
                                                {/* <IconButton
                                                    sx={{
                                                        " &.MuiIconButton-root": {
                                                            // padding: props.padding,
                                                        },
                                                        pr: '10px'
                                                    }}>
                                                </IconButton> */}

                                            </InputAdornment>
                                        ),
                                    }}

                                />

                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={6} rowSpacing={4} className="pt-7 px-7 py-7">
                            {practitionerRole && practitionerRole?.length > 0 ? (
                                practitionerRole?.slice(0, 4).map((val, index) => (
                                    <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
                                        <DoctorCard val={val} />
                                    </Grid>
                                ))
                            ) : (
                                <Grid item xs={12}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                        <Typography variant="h6" sx={{ color: "#525C79" }}>
                                            No Doctors available
                                        </Typography>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                    {/* {practitionerData.length >= visibleCount && */}
                    {practitionerRole?.length >= 4 &&

                        <Box sx={{ display: "flex", justifyContent: "center", marginTop: { xs: 3, sm: 3, md: 3, lg: "20px" } }}>
                            <Button variant="outlined"
                                onClick={() => navigate(branchName ? `doctors` : '/doctors')}
                                sx={{
                                    borderColor: "#E54290", textTransform: "capitalize", color: '#E54290', '&:hover': { backgroundColor: '#E54290', borderColor: '#E54290', color: 'white' },
                                    width: { xs: '200px', md: 255 }, borderRadius: "10px", mt: 2, height: { xs: 44, md: "54px" }
                                }}
                                endIcon={<ArrowForward />}
                            >
                                <Typography sx={{ fontSize: { xs: '14px', md: "18px", fontWeight: 500 } }}>  Find All Doctors</Typography>
                            </Button>
                        </Box>
                    }
                </div>
            </div>

            {/* healthcare services */}

            <div className="px-5 sm:px-20">
                <div className='text-center pt-6'>
                    <span className="text-lg md:text-3xl lg:text-4xl font-medium flex justify-center items-center gap-2" style={{ color: '#E54290' }}>
                        <img src={healthdepartPinkIcon} alt="healthdepart" style={{
                            width: '34px',
                            height: 34,
                        }} />
                        <div> Hospital Facilities & Departments</div>
                    </span>
                    {/* <br /> */}
                    <div style={{ color: '#000000', }} className="text-sm md:text-xl mt-1 font-light">
                        Find Healthcare Service based on Departments at  {selectedLocationName || 'Paramitha Hospital'}
                    </div>
                </div>

                <Box sx={{ height: { md: 600, lg: 430 } }}>

                    <Grid container columnSpacing={6} rowSpacing={4} className="pt-5">

                        {departmentInfo && departmentInfo?.length > 0 ? (

                            departmentInfo?.slice(0, 8)?.map((val, index) => {
                                // const imgSrc = val?.resource?.identifier?.[0]?.value
                                // const isValidImage = typeof imgSrc === 'string' && imgSrc.trim() !== '' && isNaN(imgSrc);
                                const imgSrc = val?.resource?.identifier?.filter((val) => val?.system === `${process.env.REACT_APP_API_Docsteth}logo`)?.[0]?.value;                                        // const imgSrc = val?.resource?.identifier?.[0]?.value

                                // filter((item) => item?.system === 'https://dev.api.docsteth.com/api/api/v1/logo');
                                return (
                                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                        <Box className='flex items-center cursor-pointer px-2'
                                            elevation={2}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                textAlign: 'center',
                                                cursor: 'pointer',
                                                // mt: 1,
                                                opacity: 1,
                                                p: { xs: '15px 10px', md: '25px 10px' },
                                                backgroundColor: '#FDF3F7',
                                                height: { xs: '140px', md: '160px' },
                                                border: '0.5px solid #E54290',
                                                borderRadius: '12px',
                                                color: "#1D5775",

                                                "&:hover": {
                                                    backgroundColor: "#E54290",
                                                    color: '#FFFFFF'
                                                }
                                            }}
                                            onClick={() => handleSelectDept(val?.resource?.name, val?.resource?.id)}
                                        >
                                            <img
                                                src={imgSrc || healthdeparticon}  // Use imgSrc if valid; otherwise, use the static image
                                                style={{ width: '54px', height: '54px', marginRight: '8px' }}
                                                alt="department"
                                            />
                                            <Box className="truncate" sx={{ width: '100%' }}>
                                                <Typography
                                                    sx={{
                                                        fontSize: "16px",
                                                        fontWeight: "500",
                                                        textTransform: 'capitalize',
                                                        opacity: "100%",

                                                    }}
                                                >
                                                    {val?.resource?.name}
                                                </Typography>

                                            </Box>
                                        </Box>
                                    </Grid>
                                )
                            })
                        ) : <Grid item xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <Typography variant="h6" sx={{ color: "#525C79" }}>
                                    No data available
                                </Typography>
                            </Box>
                        </Grid>}
                    </Grid>
                </Box>

                {departmentInfo?.length >= 8 &&
                    <Box sx={{ display: "flex", justifyContent: "center", mt: { xs: 3, sm: 3, md: 5, lg: 2 } }}>
                        <Button
                            variant="outlined"
                            onClick={() => navigate(branchName ? 'healthcaredept' : 'healthcaredept')}
                            sx={{
                                borderColor: "#E54290",
                                textTransform: "capitalize",
                                color: "#E54290",
                                borderRadius: "10px",
                                width: { xs: '200px', md: '255px' },
                                height: { xs: '44px', md: "54px" },
                                '&:hover': { backgroundColor: '#E54290', borderColor: '#E54290', color: 'white' },
                            }}
                            endIcon={<ArrowForward />}
                        >
                            <Typography sx={{ fontSize: { xs: '14px', md: "18px", fontWeight: 500 } }}> See All Departments</Typography>
                        </Button>
                    </Box>
                }
            </div>

        </div >
    );
};

export default FindDoctorPage;
