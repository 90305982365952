import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Grid, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import followIcon from '../../assets/images/Follow.svg';
// import Overview from './Overview';
import { ShareOutlined } from '@mui/icons-material';
import { OutlinedButton } from '../atoms/commonbutton';
// import PostPublications from './PostPublications';
import URL from '../../services/api_url';
import axios from 'axios';
import { animateScroll as scroll } from "react-scroll";
import './Navbar.css'
import ServiceOverview from './ServiceOverview';
import healthServiceIcon from '../../assets/healthcareprograms/Healthcare Service 1.svg';
import BranchUrl from '../../services/api_branch_url';
import EligibilityCriteria from './eligibilityCriteria';
import SimilarServices from './similarServices';
import PostArticles from './postArticles';
import PatientEducate from './patientEducate';
import AskedQuestions from './askedQuestions';
import Mediagalleryservices from './Mediagalleryservices';
import { PractitionerCard } from './PractitionerCard';
import HealthServiceContacts from './healthServiceContacts';
import SocialMediaShare from '../atoms/socialMediaShare';
import { localstore } from '../localstore/localstore';
import DoctorUnfollow from '../doctors/doctorUnfollow';
import NoAccess from '../atoms/NoAccess';


const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
const daysCompare = { mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6, sun: 7 };

const ContactInfo = ({ contactDetails, system }) => {
    // console.log(contactDetails)
    const phoneInfo = contactDetails?.find(info => info.system === system);
    return (
        <div>{phoneInfo ? (<p className='text-sm' style={{ color: '#0A0AC9' }}>{phoneInfo.value}</p>) : '-------'}</div>
    );
};

const HealthcareService = () => {
    const [serviceData, setServiceData] = React.useState({});

    const [practitioner, setPractitioner] = useState([])
    const [timings, setTimings] = useState({});

    const [WorkingDays, setWorkingDays] = useState([]);
    const [diseaseInfo, setDiseaseInfo] = useState([]);
    const [showStickyHeader, setShowStickyHeader] = useState(false);
    const [activeIndex, setActiveIndex] = useState(-1);
    const [serviceTabs, setServiceTabs] = useState([])
    const [openShare, setOpenShare] = useState(false)
    const [beingFollowed, setBeingFollowed] = useState()
    const [isFollowing, setIsFollowing] = useState();
    const [followData, setFollowData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [openMessage, setOpenMessage] = useState(false)
    const [ind, setInd] = useState('')
    const [modal, setModal] = useState(false)
    const { id } = useParams()
    const urls = BranchUrl()
    const usertoken = localstore.getToken()
    const [activeSection, setActiveSection] = useState('overview');
    const branchName = useSelector((state) => state.branch.branch)

    const scrollToTop = () => {
        scroll.scrollToTop();
    };


    useEffect(() => {
        if (id) {
            axios.get(`${urls.fhir_tenant_orgId}HealthcareService/${id}`, {
                headers: {
                    'X-FHIR-TENANT-ID': 'parimitha',
                    'Realm': 'parimitha',
                    'X-FHIR-DSID': `fhirparimitha`,
                    'Cache-Control': 'no-cache',
                }
            }).then((res) => {
                setServiceData(res?.data || {})
                let timingInfo = res?.data?.availableTime
                // getLocationService(res?.data?.coverageArea?.[0]?.reference)
                if (timingInfo) {
                    let data = [];
                    const date = new Date();
                    const today = days[date.getDay() - 1];
                    {
                        timingInfo?.map((item, i) => {
                            let openingTime = item.openingTime;
                            let closingTime = item.closingTime;
                            if (item.allDay == true) {
                                item?.daysOfWeek?.map((child) => {
                                    data.push({
                                        day: child,
                                        allDay: item.allDay,
                                        today: child == today ? true : false,
                                    });
                                });
                            } else if (item.allDay == false) {
                                item.daysOfWeek?.map((child) => {
                                    data.push({
                                        day: child,
                                        allDay: item.allDay,
                                        today: child == today ? true : false,
                                    });
                                });
                            } else {
                                item.daysOfWeek.map((child) => {
                                    data.push({
                                        day: child,
                                        workingHours: [
                                            { openingTime: openingTime, closingTime: closingTime },
                                        ],
                                        today: child == today ? true : false,
                                    });
                                });
                            }
                        });
                    }


                    const mergedSchedule = [];

                    for (let i = 0; i < data.length; i++) {
                        let day = data[i].day;
                        let hours = data[i].workingHours;

                        let existingDay = mergedSchedule.find((item) => item.day === day);
                        // console.log(existingDay, "existingDay")
                        if (existingDay) {
                            existingDay.workingHours = existingDay.workingHours.concat(hours);
                            // console.log(existingDay, "existingDay11")
                        } else {
                            mergedSchedule.push(data[i]);
                        }
                    }
                    // console.log(mergedSchedule, "mergedSchedule,258");

                    const sorted = mergedSchedule.sort((a, b) => {
                        return daysCompare[a.day] - daysCompare[b.day];
                    });
                    // console.log(sorted, "sorted");
                    setWorkingDays(sorted);
                }
            }).catch((err) => console.log(err))
        }
    }, [])


    useEffect(() => {
        const fetchPractitionerRole = async () => {
            if (id) {
                axios.get(`${urls.fhir_tenant_orgId}PractitionerRole?service=${id}`,
                    {
                        headers: {
                            'X-FHIR-TENANT-ID': 'parimitha',
                            'X-FHIR-DSID': `fhirparimitha`,
                            'realm': 'parimitha',
                            'Cache-Control': 'no-cache',
                        }
                    }
                ).then((res) => {
                    setPractitioner(res?.data?.entry || [])
                }).catch((err) => console.log(err))
            }
        }
        fetchPractitionerRole()

    }, [id])

    // const removeDuplicates = (data) => {
    //     const uniquePractitioners = {};
    //     const uniqueData = [];

    //     data.forEach(item => {
    //         const practitionerId = item.practitioner.resource.id;
    //         if (!uniquePractitioners[practitionerId]) {
    //             uniquePractitioners[practitionerId] = true;
    //             uniqueData.push(item);
    //         }
    //     });

    //     return uniqueData;
    // }

    useEffect(() => {
        if (serviceData?.specialty && serviceData?.specialty?.length > 0) {
            var displayValues = serviceData?.specialty?.map(function (specialty) {
                return specialty.coding[0].display;
            });

            // Creating comma-separated string
            var commaSeparatedString = displayValues.join(", ");

            // console.log(commaSeparatedString)

            axios.get(`${URL.getDiseasesBySpecialityname}/${commaSeparatedString}`).then((res) => {
                setDiseaseInfo(res?.data || [])
            }).catch((err) => console.log(err))

        }
    }, [serviceData])


    useEffect(() => {
        const date = new Date();
        const today = days[date.getDay() - 1];
        WorkingDays?.find((item) => {
            if (item.day == today) {
                // console.log(item)
                setTimings(item);
            }
        });
    }, [WorkingDays]);

    useEffect(() => {
        const handleScroll = () => {
            // Check if the scroll position is greater than or equal to 48vh
            // console.log(window.scrollY >= window.innerHeight * 0.48)
            if (window?.scrollY >= window?.innerHeight * 0.48) {
                setShowStickyHeader(true);
            } else {
                setShowStickyHeader(false);
            }
        };
        // Add the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {
        // window.scrollTo({ top: 0 });
    }, []);
    useEffect(() => {
        if (id) {
            axios.get(`${URL.docsteth}getcomponentsByOrgId/${id}`)
                .then(res => {
                    setServiceTabs(res?.data || [])
                }).catch(error => {
                    console.log(error)
                })
        }
    }, [id])

    const scrolldiv = (index, val) => {
        const elem = document.getElementById(val);
        const container = document.getElementById('main');
        if (elem && container) {
            const top = elem.offsetTop - container.offsetTop;
            container.scrollTo({
                top: top,
                behavior: 'smooth',
            });
        }
        if (val === 'overview') {
            setActiveSection('overview');
            setActiveIndex(-1);
        } else {
            setActiveSection(val);

            setActiveIndex(index);

        }
    };

    const handleGetFollowing = () => {
        axios.get(`${URL.publish}getfollowing?pageId=${id}`, {
            headers: {
                "Authorization": `Bearer ${usertoken}`
            }
        }).then(res => {
            setFollowData(res?.data)
            setBeingFollowed(res?.data?.follower)
        })
    }
    useEffect(() => {
        if (usertoken) {
            handleGetFollowing()
        }
    }, [])

    useEffect(() => {
        setIsFollowing(beingFollowed)
    }, [beingFollowed])

    const handleFollow = (index) => {
        if (usertoken) {
            setInd(index)
            setLoading(true);

            const data = {
                "pageId": id
            };
            axios.post(`${URL.publish}follow`, data, {
                headers: {
                    "Authorization": `Bearer ${usertoken}`
                }
            })
                .then((res) => {
                    // Handle success response here
                    handleGetFollowing()

                    console.log("Follow successful:", res.data);
                })
                .catch((err) => {
                    // Handle error here
                    console.error("Error following:", err);
                }).finally(() => {
                    setLoading(false);
                    setInd('');
                });
        } else {
            setOpenMessage(true)
            // alert("Please login and follow");
        }
    };


    const handleUnFollowDoc = () => {
        setLoading(true)
        axios.delete(`${URL.publish}unfollow/${followData?.id}`, {
            headers: {
                "Authorization": `Bearer ${usertoken}`
            }
        }).then((res) => {
            handleGetFollowing()
            setModal(false)
        }).catch((err) => {
            // Handle error here
            console.error("Error following:", err);
        }).finally(() => {
            setLoading(false);
        });
        setIsFollowing(!isFollowing);
    }

    const title = serviceData?.name

    const url = `https://www.paramithahospitals.in/${branchName ? `${branchName?.replace(/[\s,]+/g, '%20')}/healthcareservices/${title?.replace(/[\s,]+/g, '')}/${id}` : `/healthcareservices/${title.replace(/[\s,]+/g, '')}/${id}`}`;

    return (
        <Box>
            <div style={{
                position: 'sticky',
                top: 64,
                zIndex: 10,
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        <Box style={{ marginTop: 0 }}>
                            <Card className='px-4 pt-3' sx={{ p: { sm: '10px 40px 0px 40px', md: '10px 80px 0px 80px', lg: '10px 100px 0px 100px', xl: '10px 125px 0px 125px' } }}>
                                <div className='flex flex-col md:flex-row justify-between'>
                                    <div className='capitalize flex'>
                                        <img
                                            src={serviceData?.photo?.url || healthServiceIcon}
                                            alt="healthservice"
                                            width={40}
                                            height={40}
                                            style={{ marginRight: "8px", height: "50px" }}
                                        />
                                        <div>
                                            <span className='text-2xl font-medium'>{serviceData?.name}</span>
                                            <br />
                                            <span className='text-sm'>{serviceData?.comment}</span>
                                        </div>
                                    </div>
                                    <div className='flex gap-5 items-center justify-end  mt-1 md:m-0'>

                                        <Button
                                            variant="outlined"
                                            startIcon={<img src={followIcon} width={20} height={20} alt="tele" />}
                                            sx={{
                                                height: "30px",
                                                fontSize: { xs: "14px", lg: "16px" },
                                                // justifyContent: 'space-between',
                                                // padding:'5px',
                                                textTransform: "capitalize",
                                                borderRadius: "10px",
                                                borderColor: "#205072",
                                                color: "#205072",
                                                "&:hover": { borderColor: "#205072" },
                                            }}
                                            onClick={() => {
                                                if (loading === false) {
                                                    if (isFollowing) {
                                                        setModal(true)
                                                    } else {
                                                        handleFollow(ind);
                                                    }
                                                    // setIsFollowing(!isFollowing);
                                                } else {
                                                    return;
                                                }
                                            }}

                                        >
                                            {isFollowing ? "following" : "follow"}
                                        </Button>

                                        <OutlinedButton
                                            text="Refer"
                                            borderRadius="10px"
                                            borderColor="#205072"
                                            color="#205072"
                                            width="110px"
                                            height="30px"
                                            fontSize={{ xs: "14px", lg: "16px" }}
                                            startIcon={<ShareOutlined sx={{ fontSize: 12 }} />}
                                            onClick={() => setOpenShare(true)}
                                        />
                                    </div>
                                </div>
                                <hr style={{ marginTop: '10px' }} />

                                <Box sx={{ width: '100%' }}>

                                    <nav className="navbar">
                                        <div className="nav-menu-container">
                                            <ul className="nav-menu">
                                                {/* <li className={`nav-item cursor-pointer ${activeSection === "overview" ? "active" : "pt-2.5"}`} onClick={() => { scrolldiv(0, 'overview') }}> */}
                                                <li
                                                    className={`nav-item cursor-pointer ${activeSection === 'overview' ? 'active' : 'pt-2.5'}`}
                                                    onClick={() => { scrolldiv(0, 'overview') }}
                                                >
                                                    Overview
                                                </li>
                                                {
                                                    serviceTabs?.hashcomponents?.map((service, index) => {
                                                        return (
                                                            <li
                                                                key={index}
                                                                className={`nav-item cursor-pointer ${index === activeIndex ? "active" : "pt-2.5"}`}
                                                                onClick={() => scrolldiv(index, service.name)}
                                                            >
                                                                {service?.name === "Practitioners Team" ? "Our Experts" : service?.name}
                                                            </li>
                                                        );
                                                    })
                                                }
                                                <li
                                                    className={`nav-item cursor-pointer ${activeSection === 'contact' ? 'active' : ''}`}
                                                    onClick={() => scrolldiv(serviceTabs?.hashcomponents?.length + 1, 'contact')}
                                                >
                                                    Contact
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </Box>
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
            </div>
            <Box sx={{ p: { xs: '0px 20px', sm: '0px 40px', md: '0px 80px', lg: '0px 100px', xl: "0px 125px" } }}>
                <Grid container columnSpacing={5}>
                    <Grid item xs={12} md={12}>

                        <Box className="pt-3"
                            sx={{
                                overflowY: 'scroll', maxHeight: 'calc(100vh - 240px)', '&::-webkit-scrollbar': { display: 'none' },
                                padding: '0px 10px'
                            }} id="main">
                            <Stack spacing={6}>
                                <div id="overview">
                                    <Box className='pt-2'>
                                        <ServiceOverview deptData={serviceData} />
                                    </Box>
                                </div>
                                {serviceTabs?.hashcomponents?.map((service, index) => (
                                    <div key={index} id={service.name} >
                                        {service.name === 'Eligibility Criteria' && (
                                            <div className='mt-1'>
                                                <EligibilityCriteria deptData={serviceData} />                                            </div>
                                        )}
                                        {service.name === 'Practitioners Team' && (<>
                                            <div className='font-semibold text-lg mt-1'>Meet Our Experts</div>
                                            <div className='pt-1 px-0 lg:px-0'>
                                                <Grid container columnSpacing={5}>
                                                    {practitioner?.map((val, index) => (
                                                        <Grid item xs={12} sm={6} key={index} md={6} lg={6} sx={{ p: { xs: "10px", sm: '10px', md: '10px', lg: '10px ' } }}>
                                                            <PractitionerCard val={val} />
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </div>
                                        </>
                                        )}
                                        {service.name === 'Similar Services' && (
                                            <div className='mt-1'>
                                                <SimilarServices />
                                            </div>
                                        )}
                                        {service.name === 'Posts & Articles' && (
                                            <div className='mt-1'><PostArticles /></div>
                                        )}
                                        {service.name === 'Patient Education' && (
                                            <div className='mt-1'>
                                                <PatientEducate />
                                            </div>
                                        )}
                                        {service.name === 'Frequently Asked Question' && (
                                            <div className='mt-1'>
                                                <AskedQuestions id={id} />
                                            </div>
                                        )}
                                        {service.name === 'Media Gallery' && (
                                            <div className='mt-1'>
                                                <Mediagalleryservices />
                                            </div>
                                        )}
                                    </div>
                                ))}
                                <div id="contact">
                                    <Box >
                                        <HealthServiceContacts deptData={serviceData} ContactInfo={ContactInfo} deptImg={serviceData?.photo?.url} timings={timings} setTimings={setTimings} WorkingDays={WorkingDays} />
                                    </Box>
                                </div>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <SocialMediaShare open={openShare} handleClose={() => setOpenShare(false)} url={url} title={title} />
            <DoctorUnfollow open={modal} handleClose={() => setModal(false)} handleUnFollowDoc={handleUnFollowDoc} />
            <NoAccess open={openMessage} handleClose={() => setOpenMessage(false)} />

        </Box >
    );
}

export default HealthcareService;