import React, { useState } from "react";
import { Box, CardMedia, Grid, Typography } from "@mui/material";
import dummyimage from '../../../assets/youcandoit.jpg';
import { OutlinedButton } from "../../atoms/commonbutton";

const DoctorArticle = ({ docFeed }) => {
    const articleData = docFeed.articleData;
    const [expand, setExpand] = useState(false);

    const truncateText = (text, maxLength) => {
        if (text?.length <= maxLength) {
            return text;
        }
        return text.slice(0, maxLength) + "...";
    };

    const articleDescription = expand ? articleData.articleDescription : truncateText(articleData.articleDescription, 100);

    return (
        <Box sx={{
            height: "280px", overflowY: 'scroll',
            '&::-webkit-scrollbar': {
                display: 'none',
            }
        }}>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} position="relative">
                    {docFeed?.articleData?.coAuthors && (
                        <Typography sx={{ p: "0px 10px", fontSize: "14px" }}>
                            Co-Authors:{" "}
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: docFeed.articleData.coAuthors,
                                }}
                            />
                        </Typography>
                    )}
                    {docFeed?.articleData?.contributors && (
                        <Typography sx={{ p: "0px 10px 5px 10px", fontSize: "14px" }}>
                            Contributors:{" "}
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: docFeed.articleData.contributors,
                                }}
                            />
                        </Typography>
                    )}
                    <div className="font-medium">
                        <div
                            style={{ height: expand ? 'auto' : 30 }}
                            className="text-xs px-2"
                            dangerouslySetInnerHTML={{
                                __html: articleDescription
                            }}
                        />
                        <Typography
                            component="span"
                            onClick={() => setExpand(!expand)}
                            sx={{ cursor: "pointer", fontSize: 13, ml: 1 }}
                        >
                            {expand ? "...see less" : "...see more"}{" "}
                        </Typography>
                    </div>
                    <CardMedia
                        className="object-fill"
                        component="img"
                        sx={{
                            maxHeight: { xs: "150px", sm: "200px", md: "220px" },
                        }}
                        src={docFeed.sourceLink ? docFeed.sourceLink : dummyimage}
                        alt="feedimg"
                    />
                    {docFeed?.articleData?.articleTitle && (
                        <div
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                right: 0,
                                backgroundColor: "#e8e8e0",
                                display: "flex",
                                padding: "10px 20px",
                                opacity: 0.9,
                                justifyContent: "space-between",
                            }}
                            className="d-flex cursor"
                        >
                            {articleData?.articleTitle && (
                                <p style={{ overflow: "hidden", flex: 1 }}>
                                    <Typography
                                        className="font-bold capitalize add-cover text-ellipsis"
                                        style={{ pt: 1, fontSize: "18px" }}
                                    >
                                        {articleData.articleTitle}
                                    </Typography>
                                </p>
                            )}
                            <OutlinedButton text="Read Article" fontSize="14px" />
                        </div>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default DoctorArticle;
