import React from "react";
import { Button, Typography } from "@mui/material";
import styles from "../../header/styles.module.css";
import AutoPlayVideo from "./AutoPlayVideo";
import videosrc from '../../../assets/images/prettymother.mp4'

const Firstslide = () => {
  return (
    <div style={{ width: "100%", height: "10vh" }} className={styles.carouselHead} >
      <AutoPlayVideo
        src={videosrc}
      />

      <div className={styles.firstslideContainer}>
        <h1 className={[styles.slidefirstheading].join(" ")}>
          A PROUD CHOICE IN YOUR PARENTING JOURNEY
        </h1>
        <p
          style={{
            fontSize: "22px",
            color: "white",
            marginTop: '10px',
            // border:"1px solid red"
          }}
          className={styles.firstSlideSubtext}
        >
          Experience the best at every step of care
        </p>
        <div className={styles.registorbtn}>
          <Button
            variant="contained"
            sx={{
              background: "#ffffff !important",
              color: "#000",
              borderRadius: "7px",
              textTransform: "capitalize",
              mt: '10px'
            }}
          >
            <Typography sx={{ fontSize: "16px" }}>Register Now</Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Firstslide;
