import { Grid, Paper, CircularProgress, Box } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import URL from '../../services/api_url';
import DoctorsPosts from './doctorposts';

function PostPublications({ userData, practitionerrole, selectedbranch }) {
    const [loading, setLoading] = useState(false);
    const [posts, setPosts] = useState([]);
    const practitionerValue = userData?.identifier?.[0]?.value;
    console.log(userData, "userData1")

    useEffect(() => {
        const getpostData = async () => {
            setLoading(true);
            try {
                // const res = await axios.get(`${URL.feed}feed/postsbyuseridwithouttoken/${practitionerValue}?limit=25&offset=0`);
                // setPosts(res?.data?.results || []);
                if (practitionerValue) {
                    const res = await axios.get(`${process.env.REACT_APP_C_M_API_URL}manager/getSharePostFromDocsteth/${practitionerValue}`);
                    setPosts(res.data || []);
                    console.log(res)
                } else {
                    setPosts([]);
                }




            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
        if (practitionerValue) {
            getpostData();
        }
    }, [practitionerValue]);

    return (
        <div>
            <Paper elevation={3} sx={{ p: 2 }}>
                <Grid container>
                    {loading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        posts.length > 0 ? posts?.map((post, index) => (
                            <Grid item xs={12} key={index}>
                                <DoctorsPosts feedData={post} userData={userData} />
                            </Grid>
                        )) : <div>No Posts Available</div>
                    )}
                </Grid>
            </Paper>
        </div>
    );
}

export default PostPublications;
