import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import paramithalightIcon from './assets/home-images/Paramitha logo-Light.svg'

const Loading = () => {
    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                // backgroundColor: 'rgba(0, 0, 0, 0.8)', // Black with 50% opacity
                background: 'rgba(255, 255, 255, 0.8)',

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 9999,
            }}
        >
            <Box
                sx={{
                    textAlign: 'center',
                    color: 'white',
                }}
            >
                <CircularProgress color="inherit" />
                {/* <Typography variant="h6" sx={{ color: 'white', mt: 2 }}>
                    Loading...
                </Typography> */}
                <img src={paramithalightIcon} alt="" />

            </Box>
        </Box>
    );
};

export default Loading;
