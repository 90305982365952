import React from "react";
import { Button, Typography } from "@mui/material";
import styles from "../../header/styles.module.css";
import videosrc from "../../../assets/images/1000_F_482137850_qXcZVnDPka8PjBvOdm4vmXIC0Pc6HDgN.jpg"
import childImage from '../../../assets/images/MicrosoftTeams-image (1) 1.png'

const Fifthslide = () => {
  return (
    <div style={{ width: "100%", height: "80vh", position: "relative", }}
      className={styles.carouselHead}
    >
      <img
        src={childImage}
        alt="background"
        width={1200} height={600}
        className={styles.fifthSlideImage}
      />
      <div className={styles.fifthslidecontainer}>
        <h1
          className={[styles.slidefifthheading].join(" ")}
        //   style={{ color: "black" }}
        >
          PROTECT YOUR CHILD WITH SAFE & EFFECTIVE VACCINES
        </h1>
        <p
          className={styles.slidefifthheading1}
          style={{
            fontSize: "25px",
            color: "white",
            marginTop: "5px",
          }}
        >
          Administered by Experts with 100% Vaccination Safety Checks.
        </p>
        <div className={styles.schedulevaccationbtn}>
          <Button
            variant="contained"
            sx={{
              background: "#ffffff !important",
              color: "#000",
              borderRadius: "7px",
              alignSelf: "left",
              padding: "10px 20px",
              height: "40px",
              textTransform: "capitalize",
              marginTop: "15px",

            }}
          >
            <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>   Schedule Vaccination</Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Fifthslide;
