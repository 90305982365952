import React from 'react'
import { Box, Grid, Paper } from '@mui/material'
import healthdeparticon from '../../assets/healthcareprograms/Health Department 2.svg';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function HealthServiceCare({ healthCareSerivces, selectBranchInfo }) {
    const branchName = useSelector(state => state.branch.branch)
    const navigate = useNavigate()
    const handleSelectService = (title, id) => {
        let name = title?.replace(/\//g, '')
        navigate(branchName ? `/${branchName}/healthcareservices/${name}/${id}` : `/healthcareservices/${name}/${id}`)
        window.location.reload();
    }
    return (
        <div>
            <div>
                <div className='font-semibold text-lg'>Healthcare Services</div>
                <Grid container spacing={2} mt={0}>
                    {healthCareSerivces?.map((item, index) => (
                        <Grid item xs={12} md={6} lg={6} key={index}>
                            <div className='text-sm font-medium'>
                                <Paper elevation={2} className='flex items-center cursor-pointer px-2 py-2' onClick={() => handleSelectService(item?.resource?.name, item?.resource?.id)}>
                                    <img
                                        src={item?.resource?.photo?.url || healthdeparticon}
                                        alt=""
                                        width={35}
                                        height={35}
                                        style={{ marginRight: '8px', height: '35px' }}
                                    />
                                    <Box className="truncate">
                                        <Grid className='text-base font-bold capitalize' sx={{ color: '#525C79' }}>
                                            {item?.resource?.name}
                                        </Grid>
                                        <Grid className="truncate text-sm" sx={{ color: '#525C79' }}>
                                            {item?.resource?.comment}
                                        </Grid>
                                    </Box>
                                </Paper>
                            </div>
                        </Grid>
                    ))}

                    {
                        healthCareSerivces?.length === 0 &&
                        <div className='pt-3 text[16px] pl-6'>No Healthcare Services</div>
                    }
                </Grid>
            </div>
        </div>
    )
}

export default HealthServiceCare