import React, { useEffect, useState } from "react";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import LikedIcon from "../../../assets/images/liked-icon.svg";
import ShareImg from "../../../assets/images/share-icon.svg";
import CommentIcon from "../../../assets/images/comment-icon.svg";
import likeIcon from "../../../assets/images/like-icon.svg";
import axios from "axios";
import URL from "../../../services/api_url";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { localstore } from "../../localstore/localstore";
import { getpostbyid } from "../../../redux/actions/loginActions";
import { getMetaData } from "../../atoms/getLocations";
import AskDoctor from "../../doctors/AskDoctor";
import NoAccess from "../../atoms/NoAccess";


function PostActions({ item, userData }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [commentOpen, setCommentOpen] = useState(false)
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const [like, setLike] = useState(false);
  // console.log(item, "1234567890")
  const usertoken = localstore.getToken()
  const patientName = localstore.getPatientName()
  const [post, setPost] = useState(item)
  const [firstName, lastName] = patientName?.split(" ") || '';
  const postdata = useSelector((state) => state.login.postdata)

  useEffect(() => {
    if (post?.id === postdata?.id) {
      setPost(postdata)
    }
  }, [post, postdata])
  useEffect(() => {
    if (usertoken) {
      dispatch(getpostbyid(post?.id, usertoken))
    }
  }, [post?.id, usertoken])




  const handleLike = async () => {
    if (usertoken) {
      setLike(true);
      const metadata = await getMetaData();

      const data = {
        "userData": {
          fullName: firstName || '',
          profilePic: null,
          caption: null,
        },
        "date": new Date().toISOString(),
        "likedBy": localstore?.getUserId(),
        "metadata": metadata,
        "postId": post?.id
      }
      await axios.post(`${URL.publish}addlikes`, data, {
        headers: {
          'Authorization': `Bearer ${usertoken}`
        }
      }).then(res => {
        dispatch(getpostbyid(post?.id, usertoken));
      })
    }
    else {
      setOpen(true)
      // alert('Your are unable to access the data! Please Login Or SignUp')
      // navigate(selectedbranch?.name ? `/${selectedbranch?.name}/login` : '/login')
    }

  }

  const handleUnlike = () => {
    setLike(false);
    axios
      .delete(`${URL.publish}deletelikesbyid/${post?.likedId}`, {
        headers: {
          Authorization: `Bearer ${usertoken}`,
        },
      })
      .then(res => {
        dispatch(getpostbyid(post?.id, usertoken));
      })
      .catch(err => {
        console.log(err.message, 'handleUnlike');
      });
  }



  const handleCommentClick = () => {
    setCommentOpen(prev => !prev)
    // navigate('/commentsmain', { state: { data: item } });
    // dispatch(getpostbyid(item.id, token));
    // setCommentOpen(!commentOpen)
  }
  const handlelinkcopy = () => {
    navigator.clipboard.writeText(`${'http:/localhost:3500/'}post/${item.id}`)
    // console.log(url, "yessss")
  }
  return (
    <Box >
      <Box
        sx={{
          display: "flex",
          pl: 1,
          pr: 1,
          mt: 1,
          justifyContent: "space-between",
        }}
      >
        {post.likeCount > 0 ? (
          <Typography
            sx={{
              fontSize: { xs: "7px", sm: "11px", md: "14px", lg: '14px' },
              // margin: "5px",
              height: '22px'
            }}
          >
            {post.likeCount} {post.likeCount === 1 ? "person" : "people"}{" "}
            {"liked"}
          </Typography>
        ) : null}
        {post.shareCount > 0 ? (
          <Typography
            sx={{
              fontSize: { xs: "7px", sm: "11px", md: "14px", lg: '14px' },

            }}
          >
            {post.shareCount} {post.shareCount === 1 ? "person" : "people"}{" "}
            Answered
          </Typography>
        ) : null}
      </Box>

      <Divider />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', p: "2px 20px" }}>

        <Box>
          {post.likedId || like === true ? (
            <IconButton aria-label="unlike" onClick={handleUnlike}>
              <img src={LikedIcon} alt="unlike" />
            </IconButton>
          ) : (<IconButton aria-label="like" onClick={handleLike}>
            <img src={likeIcon} alt="like" />
          </IconButton>)}
          <Typography variant="caption" sx={{ cursor: 'pointer' }}
            onClick=
            {
              item.likedId || like === true ? handleUnlike : handleLike
            }
          > Like</Typography>
        </Box>
        <Box onClick={handleCommentClick}>
          <IconButton aria-label="comment" >
            <img src={CommentIcon} alt="comment" />
          </IconButton>
          <Typography variant="caption" sx={{ cursor: 'pointer' }}>Ask Doctor</Typography>
        </Box>
        <Box >
          <IconButton aria-label="share" >
            <img src={ShareImg} alt="share" />
          </IconButton>
          <Typography variant="caption" sx={{ cursor: 'pointer' }}>Share</Typography>
        </Box>
      </Box>
      {commentOpen && <div>
        <Divider /><AskDoctor item={item} userData={userData} /></div>}

      <NoAccess open={open} handleClose={() => setOpen(false)} />
    </Box>
  )
}

export default PostActions;
