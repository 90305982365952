import React, { useEffect, useState } from 'react'
import { Avatar, Box, Button, Grid, Switch, Typography } from '@mui/material';
import { CommentTextField } from '../atoms/commentTextField';
import ContainedButton from '../atoms/commonbutton';
import { localstore } from '../localstore/localstore';
import { getMetaData } from '../atoms/getLocations';
import URL from '../../services/api_url';
import axios from 'axios';
import { timeSince } from '../atoms/timeSince';
import { Public } from '@mui/icons-material';
import moment from 'moment';
import NoAccess from '../atoms/NoAccess';

function AskDoctor({ userData, item }) {
    const [anonymous, setAnonymous] = useState(false);
    const [commentVal, setCommentVal] = useState('')
    const usertoken = localstore.getToken()
    const [askComment, setAskComment] = useState({})
    const [openMessage, setOpenMessage] = useState(false)

    const ibmId = localstore.getIbmId()
    const [replyComments, setReplyComments] = useState([])


    const getComments = async () => {
        if (usertoken) {
            try {
                // const res = await axios.get(`${URL.publish}commentsbypost/${id}?status=true`, {
                const res = await axios.get(`${URL.publish}getCommentsbypostId/${item?.id}?status=true`, {
                    headers: {
                        "Authorization": `Bearer ${usertoken}`
                    }
                })
                setReplyComments(res.data || [])
            } catch (error) {
                console.log(error)
            }
        }
    }


    useEffect(() => {
        getComments()
    }, [item?.id])

    const addComment = async () => {
        const metadata = await getMetaData();

        if (ibmId && usertoken) {
            try {
                const data = {
                    "anonymous": anonymous,
                    comment: commentVal,
                    commentBy: localstore?.getUserId(),
                    date: new Date().toISOString(),
                    metadata: metadata, // Ensure metadata is called properly
                    userData: {
                        fullName: userData?.name?.[0]?.text || '',
                        profilePic: null,
                        caption: null,
                    },
                    postId: item?.id,
                };

                const res = await axios.post(`${URL.publish}addcomment`, data, {
                    headers: {
                        'Authorization': `Bearer ${usertoken}`,
                    },
                });

                if (res.status === 200 || res.status === 201) {
                    setAskComment(res.data || {})
                    getComments()
                    setCommentVal('')
                    // getComments(); // Call getComments after the post is successful
                }
            } catch (error) {
                console.error('Error adding comment:', error);
            }
        } else {
            setOpenMessage(true)
            // alert('Your are unable to access the data, please log in or sign up for an account')
            // navigate(selectedbranch?.name ? `/${selectedbranch?.name}/login` : '/login')
        }

    }

    return (
        <Box sx={{ mb: { xs: 10 }, px: 3 }}>
            <Grid container mt={2}>
                <Grid item md={1} sm={1} xs={2}>
                    {
                        anonymous ?
                            <Avatar variant="rounded" />
                            : (
                                <Avatar variant="rounded" />
                            )
                    }
                    {/* <Avatar /> */}
                    {/* {anonymous ? (
                        <img
                            style={{ marginTop: "4px" }}
                            variant="rounded"
                            // src={AnonymousIcon}
                        />
                    ) : ibm?.photo && ibm?.photo[0]?.url?.length > 0 ? (
                        <img src={{ uri: ibm.photo[0].url }} />
                    ) : (
                        <Avatar
                            sx={{ width: 34, height: 34, mt: "4px" }}
                            variant="rounded"
                        >
                            <img src={UserIcon} alt="usericon" />
                        </Avatar>
                    )} */}
                </Grid>
                <Grid item xs={5} sm={7} md={7}>
                    <Box mt={1}>
                        {anonymous ? (
                            <Typography sx={{ fontSize: '14px' }} fontWeight={600}>
                                Anonymous
                            </Typography>
                        ) : (
                            <Typography fontWeight={400} fontSize={14}>
                                {userData?.name?.[0]?.text}
                                {/* {patientName?.replace(/['"]+/g, "")} */}
                            </Typography>
                        )}
                    </Box>
                </Grid>
                <Grid md={3} xs={4} sm={3} my={1}>
                    <Typography variant="caption">
                        <Typography sx={{ fontSize: '14px' }}>Ask Anonymous</Typography>
                    </Typography>
                </Grid>
                <Grid md={1} xs={1} sm={1}>
                    <Switch
                        // size={Platform.OS === 'ios' ? 'small' : 'medium'}
                        checked={anonymous}
                        onChange={() => {
                            setAnonymous(!anonymous);
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container alignItems={'end'} spacing={3}>
                <Grid item xs={2} sm={1} md={1}></Grid>
                <Grid item xs={10} sm={11} md={9}>
                    <CommentTextField
                        multiline
                        maxRows="10"
                        className='input_width'
                        value={commentVal}
                        onChange={(e) => {
                            // if (e.target.value.length <= 255) {
                            setCommentVal(e.target.value)
                            // }
                        }}
                        placeholder="Ask your Qestion"
                    />
                </Grid>
                <Grid item md={2}>
                    <ContainedButton text="Ask" bgcolor='#E44190' sx={{ maxHeight: '28px', borderRadius: '15px', textTransform: 'capitalize', marignBottom: '10px' }} disabled={!commentVal} onClick={addComment} />
                </Grid>
            </Grid>

            <div>
                {
                    ibmId &&
                    <>
                        {replyComments?.map((message, index) => {
                            return (
                                < div className='flex mt-2'>
                                    <Grid container>
                                        <Grid item xs={1} sm={1} md={1.5} lg={1}>
                                            <Avatar variant='rounded' src={message?.userData?.profilePic} />
                                        </Grid>
                                        <Grid item xs={11} sm={11} md={10.5} lg={11} sx={{ backgroundColor: '#f3f3f3', borderRadius: '10px', p: 1 }}>
                                            {/* <Typography component={'div'} sx={{ backgroundColor: '#f3f3f3', p: 1, ml: '8px!important' }}> */}
                                            {/* <div > */}
                                            <div className='flex justify-between'>
                                                <div>
                                                    <div className='flex items-center gap-4'>

                                                        <h2 className="capitalize text-ellipsis font-medium">{message?.userData?.fullName || "Anonymous"}</h2>

                                                        {/* <Typography color="black" className="fabdetail e-align" sx={{ fontSize: '14px' }}>
                          {`${timeSince(moment.utc(item.createDate).local())}`}

                        </Typography> */}
                                                    </div>
                                                    <p className="italic text-ellipsis text-xs text-[#0009]">{message?.userData?.caption}</p>
                                                </div>
                                                <Typography
                                                    sx={{
                                                        fontSize: { xs: 12, sm: 12, md: 12 },
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "2px",
                                                    }}
                                                >
                                                    <Public sx={{ fontSize: "14px", color: "#336ECC" }} />
                                                    {`${timeSince(moment.utc(message?.date).local())}`}
                                                </Typography>
                                                {/* <Delete sx={{ fontSize: '18px!important', cursor: 'pointer' }} onClick={() => setOpen(true)} /> */}
                                            </div>


                                            <p style={{ color: '#414141', fontSize: '15px', marginTop: '10px', wordBreak: 'break-word' }}>{message?.comment}</p>
                                            {message?.doctorData?.fullName &&
                                                <div className='mt-1 gap-2 bg-[#ffffff] p-3' style={{ borderRadius: '10px' }}>
                                                    <div className='flex items-center gap-2'>

                                                        {/* <FaUserDoctor style={{ width: '20px', height: '20px' }} /> */}
                                                        <Avatar variant='rounded' src={message?.doctorData?.profilePic} />
                                                        <div>
                                                            <Typography>
                                                                {message?.doctorData?.fullName}
                                                            </Typography>
                                                            <Typography fontSize={"12px"} >
                                                                {message?.doctorData?.caption}
                                                            </Typography>
                                                        </div>
                                                    </div>

                                                    <p className='font-medium mt-1' style={{ fontSize: '15px', wordBreak: 'break-word' }}>{message?.replyBy_doctor}</p>
                                                </div>
                                            }
                                        </Grid>
                                    </Grid>
                                </div>
                            )
                        })}
                    </>
                }
            </div>
            <NoAccess open={openMessage} handleClose={() => setOpenMessage(false)} />
        </Box>
    )
}

export default AskDoctor