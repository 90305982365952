import React, { useState } from 'react'
import { Formik } from 'formik'
import URL from '../../services/api_url'
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { locationJson } from '../../shared/constants';
import { Box, Card, CircularProgress, DialogActions, DialogContent, Grid, Paper, Typography } from '@mui/material';
import CustomAutoComplete from '../atoms/CustomAutoComplete';
import { selectedBranchInfo } from '../../redux/slices/SelectedBranchSlice';
import MedxpertIcon from '../../assets/medxlove.svg';
import AbhaIcon from '../../assets/abhalogo.svg';
import { CustomTextField } from '../atoms/TextField';
import { East, Visibility, VisibilityOff } from '@mui/icons-material';
import ContainedButton, { OutlinedButton } from '../atoms/commonbutton';
import CommonDialog from '../atoms/commonModal';
import { branchInfo } from '../../redux/slices/branchSlice';
import { debounce } from 'lodash';

function SignUpPage() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [enterClicked, setEnterClicked] = useState(false);
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [alertmessage, setAlertmessage] = useState('')
  const [alertstatus, setAlertstatus] = useState('')
  const selectedbranch = useSelector((state) => state.SelectedBranch.selectedbranch)
  const branchName = useSelector((state) => state.branch.branch)
  const [open, setOpen] = useState(false)
  const [textBlur, setTextBlur] = useState(false)
  const [username, setUsername] = useState('')
  const [userError, setUserError] = useState('')
  const [emailError, setEmailError] = useState('')
  // console.log(selectedbranch, "selectedbranch")
  const dispatch = useDispatch()

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // const branchOptions = ["Kompally", "Kothapet", "Chintal", "Chandanagar", "Medipally"];
  const branchOptions = locationJson
  const handleClose = () => {
    setOpen(false)
  }

  const handleClickShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword)
  }

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword)
  }


  const signUpValidationSchema = yup.object().shape({
    fullName: yup.string().required('Displayname is required'),
    // username: yup.string()
    //     .matches(
    //         /^[A-Za-z0-9]+([._][A-Za-z0-9]+)?$/,
    //         'Username should not contain spaces and can have one dot or underscore in between alphanumeric characters. Special characters cannot be at the beginning or end.'
    //     )
    //     .min(5, 'Username should be at least 5 characters.')
    //     // .min(8, 'Username should be at least 8 characters.')
    //     // .max(18, 'Username should be at most 18 characters.')
    //     .required('Username is required'),
    // phonenum: yup.string()
    //     .matches(/^\d*$/, 'Phone number must contain only numbers')
    //     .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
    //     .required('Phone number is required'),
    createpassword: yup
      .string()
      .matches(/\w*[a-z]\w*/, 'Password must have a small letter')
      .matches(/\w*[A-Z]\w*/, 'Password must have a capital letter')
      .matches(/\d/, 'Password must have a number')
      .matches(/[!@#$%^&*()\-_"=+{}; :,<.>]/, 'Password must have a special character')
      .min(8, ({ min }) => `Password must be at least ${min} characters`)
      .required('Password is required'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('createpassword')], 'Passwords do not match')
      .required('Confirm password is required'),
    // EmailId: yup.string().required('Email is required')
    //     .test('valid-domain', 'Invalid Email', (value) => {
    //         const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regular expression for email validation
    //         return emailPattern.test(value);
    //     }),
    selectBranch: yup.object().nullable().required("This Select Branch Field is required"),
  })

  // left side

  const debouncedCheckUsername = debounce((value) => {
    const data = {
      "username": value,
      "realm": 'parimitha'
    };
    axios.post(`${URL.check}/findPatientsByUserNameInkeycloak`, data, { headers: { 'realm': 'parimitha' } })
      .then(res => {

        // setUserRes(res.data)
      })
      .catch(error => {
        setUserError(error.response.data)
      });
  }, 500); // 300ms de

  const handleChangeUsername = (e) => {
    const value = e.target.value;
    setUsername(value);
    setUserError('');
    if (value) {
      debouncedCheckUsername(value);
    } else {
      setUserError('');
    }

  }
  // console.log(phoneNumber, "number");
  const debouncedCheckEmail = debounce((value) => {
    const data = {
      "email": value,
      "realm": 'parimitha'
    };
    axios.post(`${URL.check}/findPatientsByEmail`, data, { headers: { 'realm': 'parimitha' } })
      .then(res => {

        // setUserRes(res.data)
      })
      .catch(error => {
        setEmailError(error.response.data)
      });
  }, 500);
  const handleChangeEmail = (e) => {
    const value = e.target.value;
    setEmail(value);
    setEmailError('');
    if (value) {
      debouncedCheckEmail(value);
    } else {
      setUserError('');
    }

  }

  const handleClickPhoneNumber = () => {
    if (phoneNumber.length === 10 && /^\d+$/.test(phoneNumber)) {
      // updateStep((prev) => prev + 1);
      setPhoneNumber("");
      setEnterClicked(false);
    } else {
      setEnterClicked(true);
    }
  }

  const handleSubmitDetails = async (values) => {
    if (!email) {
      setEmailError('re')
    }
    const data = {
      "userName": username,
      "password": values.createpassword,
      "emailId": email,
      "display_name": values.fullName
    }
    // console.log(data, "1234567890")
    // console.log(values.selectBranch.tenant)

    try {
      setLoading(true)
      const res = await axios.post(`${URL.paramitha}/keycloak/addPatient/${values?.selectBranch?.tenant}`, data, {
        headers: {
          'X-FHIR-TENANT-ID': values?.selectBranch?.tenant,
          'X-FHIR-DSID': `fhir${values?.selectBranch?.tenant}`,
          'realm': values?.selectBranch?.tenant,
        }
      })
      if (res.status === 200 || res.status === 201) {
        setAlertmessage('User Account is created')
        setAlertstatus('success')
        setOpen(true)
        // navigate('/login')
      }
      // console.log('success')
    } catch (error) {
      if (error.response.status === 409) {
        const parsedBody = JSON.parse(error.response.data.body)
        const errorMessage = parsedBody.errorMessage;
        // setAlertmessage(errorMessage)
        // setAlertstatus('error')
        alert(errorMessage)
      }
      else {
        setAlertmessage(error.message);
        setAlertstatus('error');
      }

    } finally {
      setLoading(false)
    }
  }
  const isValidEmail = (email) => {
    // Simple regex for basic email validation
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };
  return (
    <div>
      <Formik
        validationSchema={signUpValidationSchema}
        enableReinitialize={true}
        initialValues={{ fullName: "", createpassword: "", confirmPassword: "", selectBranch: selectedbranch ? selectedbranch : null }}
        onSubmit={(values) => {
          handleSubmitDetails(values)

          // console.log(values, "heloo");
          // // UserService.doLogin()
          // updateStep(0);
          // IncreaseStep((prevstep) => prevstep + 1);
          // navigate('/signup2')
        }
        }
      >
        {
          ({
            handleSubmit,
            isValid,
            values,
            errors,
            handleChange,
            handleBlur,
            touched,
            isSubmitting,
            setFieldValue
          }) => (
            <>
              <Grid container spacing={4}>
                <Grid item md={4} sm={12} xs={12} >
                  <div className='py-4 px-4'>
                    <Box >
                      <Typography sx={{ fontWeight: 600, fontSize: { xs: 17, sm: 17, md: 17 } }}>
                        Become Patient at Paramitha Hospital ?</Typography>
                    </Box>

                    <Box sx={{ mt: "5px" }}>
                      <Typography sx={{ fontSize: { xs: 14, sm: 14, md: 14 }, fontWeight: 400 }} >
                        New to Paramitha Hospital, Register Now</Typography>
                    </Box>
                    <Box sx={{ mb: 1, mt: 3 }}>
                      <label style={{ fontWeight: 500 }}>Select branch</label>
                    </Box>
                    <CustomAutoComplete
                      borderRadius='7px'
                      borderColor='#59D8FA'
                      id="selectBranch"
                      placeholder='Select Branch'
                      options={branchOptions}
                      value={values.selectBranch}
                      getOptionLabel={option => option.name}
                      onChange={(event, value) => {
                        // handleChange({ target: { name: "selectBranch", value } });
                        setFieldValue("selectBranch", value);
                        localStorage.setItem("brName", value?.name);
                        dispatch(branchInfo(value?.name))
                        localStorage.setItem("selectedbranch", JSON.stringify(value));
                        dispatch(selectedBranchInfo(value))
                        navigate(value?.name ? `/${value?.name}/signup` : '/signup')
                      }}
                      error={touched.selectBranch && Boolean(errors.selectBranch)}

                    // renderInputProps={{
                    //     error: touched.selectBranch && Boolean(errors.selectBranch),
                    //     helperText: touched.selectBranch && errors.selectBranch
                    // }}
                    />
                    {errors.selectBranch && touched.selectBranch && (
                      <Typography sx={{ color: '#D32F2F', fontSize: '14px' }}>
                        {touched.selectBranch && errors.selectBranch}
                      </Typography>
                    )}
                    <Box sx={{ mt: 1 }}>
                      <Typography sx={{ fontSize: { xs: 14, sm: 14, md: 14 }, fontWeight: 500 }} >
                        your account will be credited for only selected branch of paramitha hospital</Typography>
                    </Box>

                    <div className='mt-6 hidden md:block'>
                      <Typography sx={{ fontSize: { xs: 14, sm: 16, md: 16 }, fontWeight: 600 }} >
                        Quick Signup ?</Typography>
                      <Box sx={{ mt: 1 }}>
                        <Typography sx={{ fontSize: { xs: 14, sm: 14, md: 14 }, fontWeight: 500 }} >
                          Reduce your efforts to create & remember your User name,Password
                        </Typography>
                      </Box>


                      <Box sx={{ mt: 2 }}>
                        <Grid container item spacing={3}>
                          <Grid item xs={12} sm={12} md={12}>
                            <Grid container sx={{ border: "1px solid #CFCFCF" }}>
                              <Grid item xs={2} sm={1} md={2} sx={{ borderRight: "1px solid #CFCFCF" }}>
                                <img src={AbhaIcon} width={30} height={30} alt="hospital"
                                  style={{ margin: "5px", marginLeft: "12px" }}
                                />
                              </Grid>
                              <Grid item xs={10} sm={11} md={10} textAlign={'center'}>
                                <div style={{ padding: "8px", color: '#1B5775', fontWeight: 500 }}>Signup with Ayushman Bharat</div>
                              </Grid>
                            </Grid>

                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <Grid container sx={{ border: "1px solid #CFCFCF" }}>
                              <Grid item xs={2} sm={1} md={2} sx={{ borderRight: "1px solid #CFCFCF" }}>
                                <img src={MedxpertIcon} width={30} height={30} alt="hospital"
                                  style={{ margin: "5px", marginLeft: "12px" }}
                                />

                              </Grid>
                              <Grid item xs={10} sm={11} md={10} textAlign={'center'}>
                                <div style={{ padding: "8px", color: '#1B5775', fontWeight: 500 }}>Signup with MedXperts</div>

                              </Grid>
                            </Grid>
                          </Grid>

                        </Grid>

                      </Box>
                    </div>

                  </div>

                </Grid>
                <Grid item md={8}>
                  <div className='pt-4 px-2'>
                    <Card sx={{ p: 1 }}>
                      <div className='px-6 py-4'>
                        <div className='font-semibold'>Create Your Account</div>
                        <p className='text-sm font-medium'>This will be your Login Credentials for your Account at Paramitha Hospitals</p>

                        <Grid item container spacing={3}>
                          <Grid item xs={12} md={6}>
                            <Box sx={{ mt: 2 }}>
                              <Box sx={{ mb: 1 }}>
                                <label style={{ fontWeight: 500 }}>Display Name</label>
                              </Box>
                              <CustomTextField placeholder="Enter Your Full Name " type={'text'} borderColor="#59D8FA" borderRadius="7px"
                                value={values.fullName}
                                onChange={handleChange('fullName')}
                                handleBlur={handleBlur('fullName')}
                                error={touched.fullName && Boolean(errors.fullName)}


                              />
                              {errors.fullName && touched.fullName && (
                                <Typography sx={{ color: '#D32F2F', fontSize: '14px' }}>
                                  {touched.fullName && errors.fullName}
                                </Typography>
                              )}
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Box sx={{ mt: 2 }}>
                              <Box sx={{ mb: 1 }}>
                                <label style={{ fontWeight: 500 }}>Email ID</label>
                              </Box>
                              <CustomTextField
                                placeholder="Enter your Email"
                                type={'email'}
                                borderColor="#59D8FA"
                                borderRadius="7px"
                                value={email}
                                onChange={handleChangeEmail}
                                // handleBlur={handleBlur('EmailId')}
                                error={touched.EmailId && Boolean(errors.EmailId)}
                              />
                              {emailError && (
                                <Typography sx={{ color: '#D32F2F', fontSize: '14px' }}>
                                  {emailError}
                                </Typography>
                              )}
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Box>
                              <Box sx={{ mb: 1 }}>
                                <label style={{ fontWeight: 500 }}>User Name</label>
                              </Box>
                              <CustomTextField
                                // label="Display name"
                                placeholder="User Name (Will be used for Signup Process)"
                                type={'text'}
                                borderColor="#59D8FA"
                                borderRadius="7px"
                                value={username}
                                onChange={handleChangeUsername}
                                // handleBlur={handleBlur('username')}
                                error={touched.username && Boolean(errors.username)}
                                text="@ Paramitha"
                              />
                              {userError && (
                                <Typography sx={{ color: '#D32F2F', fontSize: '14px' }}>
                                  {userError}
                                </Typography>
                              )}
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Box sx={{ mt: "16px" }}>
                              <Box sx={{ mb: 1 }}>
                                <label style={{ fontWeight: 500 }}>Create Password</label>
                              </Box>
                              <CustomTextField
                                // label="Display name"
                                placeholder="New Password"
                                borderRadius="7px"
                                type={showPassword ? 'text' : 'password'}
                                borderColor="#59D8FA"
                                icon={showPassword ? <Visibility /> : <VisibilityOff />}

                                value={values.createpassword}

                                onChange={handleChange('createpassword')}
                                handleBlur={handleBlur('createpassword')}
                                onClick={handleClickShowPassword}
                                error={touched.createpassword && Boolean(errors.createpassword)}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    handleSubmit();
                                  }
                                }}
                              />
                              {errors.createpassword && touched.createpassword && (
                                <Typography sx={{ color: '#D32F2F', fontSize: '14px' }}>
                                  {touched.createpassword && errors.createpassword}
                                </Typography>
                              )}
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Box sx={{ mt: "16px" }}>
                              <Box sx={{ mb: 1 }}>
                                <label style={{ fontWeight: 500 }}>Confirm Password</label>
                              </Box>
                              <CustomTextField
                                // label="Display name"
                                placeholder="Re-enter your Password"

                                type={showConfirmPassword ? 'text' : 'password'}
                                borderColor="#59D8FA"
                                icon={showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                borderRadius="7px"
                                value={values.confirmPassword}

                                onChange={handleChange('confirmPassword')}
                                handleBlur={handleBlur('confirmPassword')}
                                onClick={handleClickShowConfirmPassword}
                                error={touched.confirmPassword && Boolean(errors.confirmPassword)}

                              />
                              {errors.confirmPassword && touched.confirmPassword && (
                                <Typography sx={{ color: '#D32F2F', fontSize: '14px' }}>
                                  {touched.confirmPassword && errors.confirmPassword}
                                </Typography>
                              )}

                            </Box>
                          </Grid>
                        </Grid>

                        <div className='text-center mt-3'>
                          <ContainedButton
                            width="150px"
                            height="32px"
                            bgcolor="#1B5775"
                            borderRadius="7px"
                            text="Signup"
                            onClick={handleSubmit} // onClick event handler
                            // disabled={!isValid || !values.fullName || !values.username || !values.phonenum || !values.createpassword || !values.confirmPassword || !values.EmailId || !values.selectBranch}
                            startIcon={null}
                            endIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
                          />
                        </div>

                        <div className="block md:hidden mt-4">
                          <Typography sx={{ fontSize: { xs: 14, sm: 16, md: 16 }, textAlign: 'center', mt: 1, fontWeight: 600 }} >
                            Quick Signup ?</Typography>
                          <Box sx={{ mt: 1 }}>
                            <Typography sx={{ fontSize: { xs: 13, sm: 14, md: 14 }, fontWeight: 500 }} >
                              Reduce your efforts to create & remember your User name,Password
                            </Typography>
                          </Box>


                          <Box sx={{ mt: 2 }}>
                            <Grid container item spacing={3}>
                              <Grid item xs={12} sm={12} md={12}>
                                <Grid container sx={{ border: "1px solid #CFCFCF" }}>
                                  <Grid item xs={2} sm={1} md={2} sx={{ borderRight: "1px solid #CFCFCF" }}>
                                    <img src={AbhaIcon} width={30} height={30} alt="hospital"
                                      style={{ margin: "5px", marginLeft: "12px" }}
                                    />
                                  </Grid>
                                  <Grid item xs={10} sm={11} md={10} textAlign={'center'}>
                                    <div style={{ padding: "8px", fontSize: '14px', color: '#1B5775', fontWeight: 500 }}>Signup with Ayushman Bharat</div>
                                  </Grid>
                                </Grid>

                              </Grid>
                              <Grid item xs={12} sm={12} md={12}>
                                <Grid container sx={{ border: "1px solid #CFCFCF" }}>
                                  <Grid item xs={2} sm={1} md={2} sx={{ borderRight: "1px solid #CFCFCF" }}>
                                    <img src={MedxpertIcon} width={30} height={30} alt="hospital"
                                      style={{ margin: "5px", marginLeft: "12px" }}
                                    />

                                  </Grid>
                                  <Grid item xs={10} sm={11} md={10} textAlign={'center'}>
                                    <div style={{ padding: "8px", fontSize: '14px', color: '#1B5775', fontWeight: 500 }}>Signup with MedXperts</div>

                                  </Grid>
                                </Grid>
                              </Grid>

                            </Grid>

                          </Box>
                        </div>

                      </div>
                    </Card>
                  </div>
                </Grid>
              </Grid>
              <CommonDialog open={open} sx={{ '.MuiDialog-paper': { bottom: { md: "0px" }, top: { xs: "0px", sm: "0px", md: '-100px' } } }}>
                <DialogContent>
                  <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
                    Your User Account has been created successfully please login with  below username or email
                  </Typography>
                  <Typography >
                    User Name: <span className='font-medium mt-1'>{values.username}</span>
                  </Typography>
                  <Typography >
                    Email Address:<span className='font-medium mt-1'> {values.EmailId}</span>
                  </Typography>
                </DialogContent>
                <DialogActions sx={{ m: 0, justifyContent: 'end' }}>
                  <OutlinedButton text="Login" height="30px" borderColor="#1B5775" color="#1B5775" width="130px" endIcon={<East />} onClick={() => navigate(branchName ? `/${branchName}/login` : '/login')} />
                </DialogActions>
              </CommonDialog>
            </>
          )}


      </Formik>
    </div >
  )
}

export default SignUpPage